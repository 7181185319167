<ion-content style="height: 100%; width: 100%; overflow: hidden;">
  <div class="w-full h-full relative bg-dark-default pt-6 pb-8 overflow-scroll scroll-hidden px-4">
    <div class="w-full flex justify-end items-center pb-4">
      <svg (click)="closeModal()" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-danger-400 cursor-pointer">
        <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
      </svg>      
    </div>

    <!-- guest details -->
    <app-guest-list-reservation-info-legacy></app-guest-list-reservation-info-legacy>
  
    <!-- guest tags -->
    <app-guest-list-reservation-tags-legacy></app-guest-list-reservation-tags-legacy>
  
    <!-- additional guests -->
    <app-guest-list-reservation-additional-guests-legacy></app-guest-list-reservation-additional-guests-legacy>
  
    <!-- guest notes -->
    <app-guest-list-reservation-notes-legacy></app-guest-list-reservation-notes-legacy>
  
  </div>
</ion-content>