/* eslint-disable jsdoc/no-types */
// External imports
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

@Injectable()
export class DashboardProvider {
    //default floor plan modal variables
    selectedDashboardSegmentObservable = new Subject();
    selectedDashboardSegment: string;

    constructor(
        public modalCtrl: ModalController
    ) { }

    setSelectedDashboardSegment(segment: string) {
        this.selectedDashboardSegment = segment;
        this.selectedDashboardSegmentObservable.next(segment);
    }

    getSelectedDashboardSegment() {
        return this.selectedDashboardSegment;
    }
}
