<div class="w-full h-screen bg-dark-default pb-8 overflow-scroll scroll-hidden">

  <!-- stepper -->
  <app-campaign-stepper></app-campaign-stepper>

  <!-- select campaign type -->
  <app-campaign-type *ngIf="currentPage === 1"></app-campaign-type>

  <!-- set location map -->
  <app-location-map *ngIf="currentPage === 3"></app-location-map>

  <!-- set poster map -->
  <app-poster-map *ngIf="currentPage === 4"></app-poster-map>

  <!-- set campaign image -->
  <app-campaign-image *ngIf="currentPage === 2"></app-campaign-image>

  <!-- set campaign date/time -->
  <app-campaign-date-time *ngIf="currentPage === 5"></app-campaign-date-time>

  <!-- set campaign rate -->
  <app-campaign-rate *ngIf="currentPage === 6"></app-campaign-rate>

  <!-- campaign review -->
  <app-campaign-review *ngIf="currentPage === 7"></app-campaign-review>
</div>