//Angular variables
import { Injectable } from '@angular/core';
import { Subject, from } from 'rxjs';
import { map } from 'rxjs/operators';

//Ionic imports
import { ToastController } from '@ionic/angular';

//Capacitor variables
import { CapacitorHttp, HttpResponse, HttpOptions } from '@capacitor/core';

//Internal variables
import { environment } from '../../../environments/environment';

//Internal enums
import { EmployeeRole } from 'src/app/enums/settings/employee-role';

export interface LoginBody {
  email: string;
  password: string;
  rememberMe: boolean;
}

export interface NewEmployeeBody {
  employeeName: string;
  employeeEmail: string;
  employeeType: string;
  venueUserId: string;
}

export class UserTokens {
  accessToken: string;
  refreshToken: string;
  userId: string;
  promoterIdentifier: string;
  name: string;

  constructor(json: string) {
      if (json == null) {return;}

      const obj = JSON.parse(json);

      this.accessToken = obj.accessToken;
      this.refreshToken = obj.refreshToken;
      this.userId = obj.userId;
      this.promoterIdentifier = obj.promoterIdentifier;
      this.name = obj.name;
  }
}

export interface PotentialPreferredPromotersBody {
  searchString: string;
  page: number;
  pageSize: number;
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  public corporationIsGuestListOnlyObservable = new Subject<boolean>();
  public profilePictureUrlObservable = new Subject<string>();
  public userIdObservable = new Subject<string>();
  private corporationIsGuestListOnly: boolean;
  private userTokens = new UserTokens(null);
  private email: string;
  private corporationUserId: string;
  private corporationIconImageId: string;
  private corporationCoverImageId: string;
  private isParentCorporationEmployee: boolean;
  private isTemporaryEmployee: boolean;
  private corporationIsParentCorporation: boolean;
  private corporationIsAcceptingPayments: boolean;
  private showEventDropdownUponAddGuest: boolean;
  private parentCorporationId: string;
  private userIsSuperAdmin: boolean;
  private profilePictureUrl: string;
  private employeeRole: EmployeeRole;

  constructor(
    private toastController: ToastController
  ) {

  }

  async getNewAccessToken() {
    const userId = localStorage.getItem('phynxUserId');
    if (userId !== null && userId !== undefined && userId !== '' && userId !== 'null') {
      await this.refreshLoginMobile(userId).then(async (user) => {
        await this.setUserTokens(user.tokens).then(async () => true).catch((error) => {
          //TODO throw error here
          throw new Error('Unable to set user authentication tokens');
        });
      }).catch((error) => {
        //TODO throw error here
        throw new Error('Unable to refresh login session');
      });
    }
  }

  hasValidIdToken(): boolean {
    return (this.userTokens != null && this.userTokens.accessToken != null);
  }

  authorizationHeader(): string {
      return 'BEARER ' + this.userTokens.accessToken;
  }

  accessToken(): string {
      return this.userTokens.accessToken;
  }

  async userId(): Promise<string> {
      return this.userTokens.userId;
  }

  async name(): Promise<string> {
    return this.userTokens.name;
  }

  promoterIdentifier(): string {
      return this.userTokens.promoterIdentifier;
  }

  async userEmail(): Promise<string> {
    return this.email;
  }

  async userProfilePictureUrl(): Promise<string> {
    return this.profilePictureUrl;
  }

  async setProfilePictureUrl(url: string) {
    this.profilePictureUrl = url;
    this.profilePictureUrlObservable.next(url);
  }

  // venueUserId(): string {
  //   return this.corporationUserId;
  // }


  // isCorporationEmployee(): boolean {
  //   return this.isParentCorporationEmployee;
  // }

  async setUserTokens(token) {
    this.userTokens = token;

    if (!!token && token.userId !== null && token.userId !== undefined && token.userId !== '' && token.userId !== 'null') {
      this.userIdObservable.next(token.userId);
    } else {
      this.userIdObservable.next(null);
    }
  }

  setUserEmail(passedEmail: string) {
    this.email = passedEmail;
  }

  async setUserRole(role: EmployeeRole) {
    this.employeeRole = role;
  }
  async getUserRole() {
    return this.employeeRole;
  }


  async setCorporationUserId(passedCorporationUserId: string) {
    this.corporationUserId = passedCorporationUserId;
  }

  async getCorporationUserId() {
    return this.corporationUserId;
  }


  async setCorporationIconImageId(passedImageId: string) {
    this.corporationIconImageId = passedImageId;
  }

  async getCorporationIconImageId() {
    return this.corporationIconImageId;
  }


  async setCorporationCoverImageId(passedImageId: string) {
    this.corporationCoverImageId = passedImageId;
  }

  async getCorporationCoverImageId() {
    return this.corporationCoverImageId;
  }


  async setIsParentCorporationEmployee(isCorporationEmployee: boolean) {
    this.isParentCorporationEmployee = isCorporationEmployee;
  }

  async getIsParentCorporationEmployee() {
    return this.isParentCorporationEmployee;
  }

  async setCorporationIsAcceptingPayments(isAcceptingPayments: boolean) {
    this.corporationIsAcceptingPayments = isAcceptingPayments;
  }

  async getCorporationIsAcceptingPayments() {
    return this.corporationIsAcceptingPayments;
  }


  async setShowEventDropdownUponAddGuest(showEventDropdownUponAddGuest: boolean) {
    this.showEventDropdownUponAddGuest = showEventDropdownUponAddGuest;
  }

  async getShowEventDropdownUponAddGuest() {
    return this.showEventDropdownUponAddGuest;
  }


  async setIsTemporaryEmployee(isTemporaryEmployee: boolean) {
    this.isTemporaryEmployee = isTemporaryEmployee;
  }

  async getIsTemporaryEmployee() {
    return this.isTemporaryEmployee;
  }


  async setCorporationIsParentCorporation(isParentCorporationStatus: boolean) {
    this.corporationIsParentCorporation = isParentCorporationStatus;
  }

  async getCorporationIsParentCorporation() {
    return this.corporationIsParentCorporation;
  }

  async setCorporationIsGuestListOnly(isGuestListOnly: boolean) {
    this.corporationIsGuestListOnly = isGuestListOnly;
    this.corporationIsGuestListOnlyObservable.next(isGuestListOnly);
  }

  async getCorporationIsGuestListOnly() {
    return this.corporationIsGuestListOnly;
  }


  async setParentCorporationId(parentCorporationId: string) {
    this.parentCorporationId = parentCorporationId;
  }

  async getParentCorporationId() {
    return this.parentCorporationId;
  }


  async setUserIsSuperAdmin(userIsSuperAdmin: boolean) {
    this.userIsSuperAdmin = userIsSuperAdmin;
  }

  async getUserIsSuperAdmin() {
    return this.userIsSuperAdmin;
  }


  async loginMobile(body: LoginBody, retry = false) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/Account/LoginMobile`,
      headers:{'content-type':'application/json'},
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else {
      const error = new Error(response.data);
      (error as any).status = response.status; // Add status code to the error object
      (error as any).message = response.data; // Add message to the error object
      throw error;
    }
  }

  async refreshLoginMobile(uid: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/Account/RefreshLoginMobile?uid=${uid}`,
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.refreshLoginMobile(uid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async switchViewingVenue(body, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/Account/SwitchViewingVenue`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.switchViewingVenue(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async checkLastLoginStats(retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Venues/CheckLastLoginEventStats/${this.corporationUserId}`,
      headers:{
        authorization:this.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.checkLastLoginStats(false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async logoutMobile(retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/Account/LogoutMobile`,
      headers: {
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      },
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.logoutMobile(false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEmployeeProfilePicture(retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/EmployeeProfilePicture?eid=${this.userTokens.userId}&cuid=${this.corporationUserId}`,
      headers:{
        authorization:this.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEmployeeProfilePicture(false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateEmployeeProfilePicture(body: object, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Venues/EmployeeProfilePicture`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateEmployeeProfilePicture(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  storeCredentialsLocally(){

  }

  getLocalCredentials(){

  }

  async getBaseUrl(retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/Account/GetBaseUrl`,
      headers: {authorization:this.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getBaseUrl(false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async createParentCorporationSubscription(requestBody: object, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Stripe/CreateSubscriptionCustomer`,
      headers:{
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.createParentCorporationSubscription(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async createNewParentCorporation(requestBody: object, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Venues/RegisterNewParentCorporation`,
      headers:{
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.createNewParentCorporation(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async createNewChildCorporation(requestBody: object, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Venues/RegisterNewChildCorporation`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.createNewChildCorporation(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async checkIfEmployeeIsTemporary(retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/CheckIfEmployeeIsTemporary`,
      headers: {authorization:this.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.checkIfEmployeeIsTemporary(false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getTemporaryEmployeeCorporationUserId(retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/GetTemporaryEmployeeCorporationUserId`,
      headers: {authorization:this.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTemporaryEmployeeCorporationUserId(false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getSelectableChildVenues(corporationUserId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/GetCorporationSelectableChildVenues?corporationUserId=${corporationUserId}`,
      headers: {authorization:this.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getSelectableChildVenues(corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getUsersCorporationDetails(retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/GetCorporationDetails?corporationUserId=${this.getCorporationUserId()}`,
      headers: {authorization:this.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getUsersCorporationDetails(false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addCorporationSuperAdmin(requestBody: object, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Venues/AddCorporationSuperAdmin`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'},
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addCorporationSuperAdmin(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEmployeePermissions(corporationuserId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/GetEmployeePermissions?corporationUserId=${corporationuserId}`,
      headers: {authorization:this.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEmployeePermissions(corporationuserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getVenueEmployees(retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/GetVenueEmployees/${this.corporationUserId}`,
      headers: {authorization:this.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenueEmployees(false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async searchVenueEmployees(searchString: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      // eslint-disable-next-line max-len
      url: `${environment.baseUrl}/api/Venues/SearchVenueEmployees?searchString=${searchString}&corporationUserId=${this.corporationUserId}`,
      headers: {authorization:this.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.searchVenueEmployees(searchString, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getCorporationVenues(retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/GetCorporationChildVenues/${this.corporationUserId}`,
      headers: {authorization:this.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getCorporationVenues(false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addCorporationEmployee(requestBody: object, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Venues/AddCorporationEmployee`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addCorporationEmployee(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async removeCorporationEmployee(eid: string, cuid: string, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Venues/RemoveCorporationEmployee?eid=${eid}&cuid=${cuid}`,
      headers:{
        authorization:this.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.removeCorporationEmployee(eid, cuid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async editCorporationEmployee(requestBody: object, retry = true) {
    const options: HttpOptions = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Venues/EditCorporationEmployee`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.editCorporationEmployee(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addCorporationPromoter(requestBody: object, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Venues/AddCorporationPromoter`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addCorporationPromoter(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async bulkAddCorporationPromoter(requestBody: object, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Venues/BulkAddCorporationPromoter`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.bulkAddCorporationPromoter(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async searchPreferredPromoters(searchString: string, page: number, pageSize: number, corporationUserId: string, retry = true){
    //task 1
    const options: HttpOptions = {
      method: 'GET',
      // eslint-disable-next-line max-len
      url: `${environment.baseUrl}/api/Venues/SearchPreferredPromoters?searchString=${searchString}&corporationUserId=${corporationUserId}&passedPage=${page}&pageSize=${pageSize}`,
      headers: {authorization:this.authorizationHeader()}
    };

    //task 3
    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.searchPreferredPromoters(searchString, page, pageSize, corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getCorporationPreferredPromoters(retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/GetCorporationPreferredPromoters?corporationUserId=${this.corporationUserId}`,
      headers: {authorization:this.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getCorporationPreferredPromoters(false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async removeCorporationPreferredPromoters(promoterUserId: string, retry = true) {
    const options: HttpOptions = {
      method: 'PUT',
      // eslint-disable-next-line max-len
      url: `${environment.baseUrl}/api/Venues/RemoveCorporationPreferredPromoters?corporationUserId=${this.corporationUserId}&promoterUserId=${promoterUserId}`,
      headers: {authorization:this.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.removeCorporationPreferredPromoters(promoterUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getStripePaymentsSecretKey(retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Stripe/GetStripePaymentsSecretKey`,
      headers: {authorization:this.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getStripePaymentsSecretKey(false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getCorporationStripeSecret(requestBody, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Stripe/GetCorporationStripeSecret`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getCorporationStripeSecret(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getStripeCustomerPaymentMethod(requestBody, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Stripe/GetStripeCustomerPaymentMethod`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getStripeCustomerPaymentMethod(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async createConnectedStripeAccount(corporationUserId: string, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Stripe/CreateConnectedAccount?cuid=${corporationUserId}`,
      headers:{
        authorization:this.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.createConnectedStripeAccount(corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async createBugReport(requestBody, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Venues/CreateBugReport`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.createBugReport(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async createFeatureRequest(requestBody, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Venues/CreateFeatureRequest`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.createFeatureRequest(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getProfilePicture(userId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/GetProfilePicture?userId=${userId}`,
      headers: {authorization:this.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getProfilePicture(userId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addProfilePicture(requestBody: object, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Venues/AddProfilePicture`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addProfilePicture(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getPromoterTicketingPayouts(passedPage: number, pageSize: number, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Accounting/GetPromoterPayouts?passedPage=${passedPage}&pageSize=${pageSize}`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPromoterTicketingPayouts(passedPage, pageSize, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getPromoterTablePayouts(passedPage: number, pageSize: number, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Accounting/GetPromoterTablePayouts?passedPage=${passedPage}&pageSize=${pageSize}`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPromoterTablePayouts(passedPage, pageSize, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getPromoterGuestListPayouts(passedPage: number, pageSize: number, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Accounting/GetPromoterGuestListPayouts?passedPage=${passedPage}&pageSize=${pageSize}`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPromoterGuestListPayouts(passedPage, pageSize, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getPromoterEventGuestListReservations(eventDetailId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Accounting/GetPromoterEventGuestListReservations?eid=${eventDetailId}`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPromoterEventGuestListReservations(eventDetailId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async changeUserPassword(requestBody: object, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/Account/ChangeUserPassword`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.changeUserPassword(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateProfilePicture(requestBody: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Venues/UpdateProfilePicture`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateProfilePicture(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getUserBasicInfo(retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/Account/GetUserBasicInfo`,
      headers:{
        authorization:this.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getUserBasicInfo(false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateUserBasicInfo(requestBody: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/Account/UpdateUserBasicInfo`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateUserBasicInfo(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async submitForgotPassword(email: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Email/ForgotPasswordMobile?email=${email}`,
      headers:{
        authorization:this.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.submitForgotPassword(email, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async submitResetPassword(requestBody: object, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Venues/ResetPasswordMobile`,
      headers:{
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.submitResetPassword(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      const error = new Error(response.data);
      (error as any).status = response.status; // Add status code to the error object
      (error as any).message = response.data; // Add message to the error object
      await this.presentToast(error.message, null, 'bottom', 'information-toast', 'information-circle');
      throw error;
    }
  }

  async checkVenueProcessingRequirements(corporationUserId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/Account/VenueHasProcessingRequirements?corporationUserId=${corporationUserId}`,
      headers:{
        authorization:this.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.checkVenueProcessingRequirements(corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async createStripeAccountLink(requestBody: object, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Stripe/CreateStripeAccountLinkUrl`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.createStripeAccountLink(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async searchParentVenues(searchString: string, page: number, pageSize: number, retry = true) {
    if (searchString === null || searchString === '') {
      return [];
    } else {
      const requestBody = {
        searchString,
        page,
        pageSize
      };

      const options: HttpOptions = {
        method: 'POST',
        url: `${environment.baseUrl}/api/Venues/SearchParentVenues`,
        headers:{
          authorization:this.authorizationHeader(),
          'content-type':'application/json'
        },
        data: requestBody
      };

      const response: HttpResponse = await CapacitorHttp.post(options);

      if (response.status === 200) {
        return response.data;
      } else if (response.status === 204) {
        return null;
      } else if (response.status === 401 && retry) { // Unauthorized
        try {
          await this.getNewAccessToken();

          // Retry the request with the new access token
          return this.searchParentVenues(searchString, page, pageSize, false);
        } catch (error) {
          throw new Error('Unable to refresh access token: ' + error.message);
        }
      } else {
        throw new Error(response.data.message);
      }
    }
  }

  async sendNewChildVenueInquiry(corporationUserId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/SendNewChildVenueInquiry?corporationUserId=${corporationUserId}`,
      headers:{
        authorization:this.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.sendNewChildVenueInquiry(corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async setVenueAsDefault(newCorporationUserId: string, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Venues/MarkCorporationAsEmployeeDefault?newCorporationUserId=${newCorporationUserId}`,
      headers:{
        authorization:this.authorizationHeader(),
        'content-type':'application/json'
      }
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.setVenueAsDefault(newCorporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getVenueFeatures(corporationUserId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/VenueFeatures?corporationUserId=${corporationUserId}`,
      headers:{
        authorization:this.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenueFeatures(corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addChatGPTSubscription(body: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Venues/AddChatGPTSubscription`,
      headers: {
        authorization:this.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addChatGPTSubscription(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getName(retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/Account/GetName`,
      headers: {authorization:this.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getName(false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateName(body: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/Account/UpdateName`,
      headers: {
        authorization:this.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateName(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateDefaultPage(body: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/Account/UpdateDefaultPage`,
      headers: {
        authorization:this.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateDefaultPage(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getVenueTerms(corporationUserId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/GetVenueTerms?cuid=${corporationUserId}`,
      headers: {authorization:this.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenueTerms(corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async upsertVenueTerms(body: object, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Venues/UpsertVenueTerms`,
      headers: {
        authorization:this.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.upsertVenueTerms(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getVenueTableAccess(retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/GetVenueTableAccess?cuid=${this.corporationUserId}`,
      headers: {
        authorization:this.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenueTableAccess(false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getTimeZoneAdjustedTime(dateTime: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/GetTimeZoneAdjustedTime?dateTime=${dateTime}&cuid=${this.corporationUserId}`,
      headers: {
        authorization:this.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTimeZoneAdjustedTime(dateTime, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getVenueServerTimeBuffer(retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/GetVenueServerTimeBuffer?cuid=${this.corporationUserId}`,
      headers: {authorization:this.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenueServerTimeBuffer(false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateServerTimeBuffer(body: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Venues/UpdateServerTimeBuffer`,
      headers: {
        authorization:this.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateServerTimeBuffer(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getPromoterEventReservations(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GetPromoterEventReservations?eid=${eventId}`,
      headers: {
        authorization:this.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPromoterEventReservations(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getVenueCorporationDetails(cuid: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/VenueCorporationDetails?cuid=${cuid}`,
      headers: {
        authorization:this.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenueCorporationDetails(cuid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async updateVenueCorporationDetails(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Venues/VenueCorporationDetails`,
      headers: {
        authorization:this.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateVenueCorporationDetails(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getVenueWidgetEmail(cuid: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/VenueWidgetEmail?cuid=${cuid}`,
      headers: {
        authorization:this.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenueWidgetEmail(cuid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateVenueWidgetEmail(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Venues/VenueWidgetEmail`,
      headers: {
        authorization:this.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateVenueWidgetEmail(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getCurrentAppVersion(retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/GetCurrentAppVersion`,
      headers: {
        authorization:this.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getCurrentAppVersion(false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getVenueIconImageId(retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/GetVenueIconImageId?cuid=${this.corporationUserId}`,
      headers: {
        authorization:this.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenueIconImageId(false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async updateVenueIconImageId(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Venues/UpdateVenueIconImageId`,
      headers: {
        authorization:this.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateVenueIconImageId(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async presentToast(
    message: string,
    duration: number,
    position: 'top' | 'middle' | 'bottom',
    cssClass: 'error-toast' | 'warning-toast' | 'success-toast' | 'information-toast' | '',
    icon: 'bug' | 'warning' | 'checkmark-circle' | 'information-circle' | ''
  ){
    const toast = await this.toastController.create({
      message,
      duration,
      position,
      cssClass,
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel'
        }
      ],
      icon
    });

    await toast.present();

    return null;
  }
}
