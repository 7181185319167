// External imports
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { format, parseISO, getDate, getMonth, getYear } from 'date-fns';

//Internal components
import { AddEventComponent } from '../components/events/add-event/add-event.component';

//Internal services
import { AccountService } from '../services/account/account-service.service';

//Internal models
// eslint-disable-next-line max-len
import { StrydEventObject, NewEventTicketTier, EventPromoterDiscountTierObject, EventPromoterPaymentTierObject, NewStrydEventObject } from '../models/event.model';

//Internal enums
import { TicketTierStatus } from '../enums/events/ticket-tier-status';

export interface NewEventInfoBody {
    eventName: string;
    eventType: string;
    organizer: string;
    startDateTime: Date;
    endDateTime: Date;
    eventSummary: string;
    eventDescription: string;
}

export interface NewEventLocationBody {
    venueName: string;
    venueAddress: string;
    latitude: number;
    longitude: number;
    imageBase64: string;
}

export interface NewEventLineSkipBody {
    lineSkipEnabled: boolean;
    lineSkipStartDateTimeString: string;
    lineSkipStartDateTime: string;
    lineSkipEndDateTimeString: string;
    lineSkipEndDateTime: string;
    lineSkipQuantity: number;
    lineSkipPrice: number;
    lineSkipDescription: string;
    lineSkipMinimumQuantity: number;
    lineSkipMaximumQuantity: number;
}

export interface EventOrderFormOptions
{
    showAge: boolean;
    requireAge: boolean;
    showGender: boolean;
    requireGender: boolean;
    showBirthdate: boolean;
    requireBirthdate: boolean;
    showHomePhone: boolean;
    requireHomePhone: boolean;
    showCellPhone: boolean;
    requireCellPhone: boolean;
    showHomeAddress: boolean;
    requireHomeAddress: boolean;
    showShippingAddress: boolean;
    requireShippingAddress: boolean;
    showWebsite: boolean;
    requireWebsite: boolean;
    showJobTitle: boolean;
    requireJobTitle: boolean;
    showCompany: boolean;
    requireCompany: boolean;
}

export interface NewStrydEventBody {
    corporationUserId: string;
    strydEventDetails: NewStrydEventObject;
    eventTicketTiers: NewEventTicketTier[];
    lineSkipTier: NewEventTicketTier[];
    promoterDiscounts: EventPromoterDiscountTierObject[];
    promoterPayments: EventPromoterPaymentTierObject[];
    eventImage: string;
    orderFormOptions: EventOrderFormOptions;
}

@Injectable()
export class EventProvider {
    //add new event modal
    addEventModal: any;
    addEventModalIsOpenObservable = new Subject<boolean>();
    addEventModalIsOpen: boolean;
    addEventModalStepObservable = new Subject<number>();
    addEventModalStep: number;

    //new event body
    newEventBody = {
        id: null,
        name: null,
        type: null,
        organizer: null,
        venueUserId: null,
        venueName: null,
        venueAddress: null,
        latitude: null,
        longitude: null,
        isOnlineEvent: false,
        onlineEventUrl: null,
        isToBeAnnounced: false,
        startDateTime: null,
        startDateTimeUtc: null,
        endDateTime: null,
        endDateTimeUtc: null,
        timeZone: null,
        timeZoneOffset: null,
        imageFileId: null,
        shortDescription: null,
        fullDescription: null,
        refundAllowance: null,
        eventStatus: null,
        isPublished: false,
        isDraft: false,
        displayRemaining: false,
        isTaxed: false,
        taxRate: null,
        isNationwideEvent: false,
        isPreferredPromoterOnly: true,
        isGuestListOnly: false,
        activityIsDisabled: false,
        isCanceled: false,
        eventDetailId: null,
        venueEventTerms: null,
        createDate: null,
        lastChanged: null
    };

    //new event toggles
    guestListOnly: boolean;
    nationwideEvent: boolean;
    disableActivityFeed: boolean;

    //new event image
    imageBase64: string;
    imageChangedEvent: any = '';

    //new event ticket tiers
    newEventTicketTiers: NewEventTicketTier[] = [];
    newEventTicketTierSubject = new Subject<NewEventTicketTier>();
    newEventLineSkipTier: NewEventTicketTier[] = [];

    //new event line skip
    newEventLineSkipEnabled: boolean;
    lineSkipStartDateTimeString: string = null;
    lineSkipStartDateTime: string = null;
    lineSkipEndDateTimeString: string = null;
    lineSkipEndDateTime: string = null;
    lineSkipQuantity: number;
    lineSkipPrice: number;
    lineSkipDescription: string;
    lineSkipMinimumQuantity: number;
    lineSkipMaximumQuantity: number;

    //new event promoter discount & payments
    promoterDiscounts: EventPromoterDiscountTierObject[];
    promoterPayments: EventPromoterPaymentTierObject[];

    //new event settings
    newEventRefund = 'none';
    newEventRefundObservable = new Subject<string>();
    newEventTax = false;
    newEventTaxObservable = new Subject<boolean>();
    newEventTerms = null;
    newEventTermsObservable = new Subject<string>();

    //edit event variables
    editEventModalIsOpenObservable = new Subject<boolean>();

    //event links variables
    showViewEventLinksObservable = new Subject();
    showViewEventLinks: boolean;

    constructor(
        public modalCtrl: ModalController,
        private accountService: AccountService
    ) {

    }

    /**
     * @summary this configures and presents the add event modal
     * @task1 present the add event modal
     * @task2 set the addEventModalIsOpen variable to true and update it's observable respectively
     * @task3 subscribe to the addEventModal onDidDismiss and then reset all variables upon dismiss
     * @task4 set the addEventModalStep to 1 by calling {@link setAddEventModalStep}
     */
     async presentAddEventModal(isEditEvent: boolean){
        //task1
        // this.addEventModal = await this.modalCtrl.create({
        // breakpoints: [0.1, 0.5, 0.75, 1],
        // initialBreakpoint: 1,
        // component: AddEventComponent,
        // cssClass: 'addEventModal'
        // });

        // await this.addEventModal.present();

        //task2
        this.addEventModalIsOpen = true;
        this.addEventModalIsOpenObservable.next(true);

        //task3
        // this.addEventModal.onDidDismiss().then((res) => {
             //TODO need to add in reset all variables method
             //this.resetAllAddEventVariables();
        // });

        //task4
        this.setAddEventModalStep(1);

        //task5
        // this.isEditEvent = isEditEvent;
        // this.isEditEventObservable.next(isEditEvent);
    }

    async dismissAddEventModal(){
        //await this.addEventModal.dismiss();

        this.addEventModalIsOpen = false;
        this.addEventModalIsOpenObservable.next(false);
    }

    // eslint-disable-next-line
    /**
     * @param  {number} modalStep
     * @task1 set the {@var addEmployeeModalStep} to the passed modalStep
     * @task2 call the {@method next()} on {@var addEmployeeModalStepObservable} so that...
     * all subscriptions in other components fetch the new {@var addEmployeeModalStep}
     */
     setAddEventModalStep(modalStep: number) {
        //task 1
        this.addEventModalStep = modalStep;
        //task 2
        this.addEventModalStepObservable.next(modalStep);
    }


    /**
     * @returns addEmployeeModalStep {number}
     */
    async getAddEventModalStep() {
        return this.addEventModalStep;
    }

    async setNewEventInfo(eventInfo: NewEventInfoBody) {
        this.newEventBody.name = eventInfo.eventName;
        this.newEventBody.type = eventInfo.eventType;
        this.newEventBody.organizer = eventInfo.organizer;
        this.newEventBody.startDateTime = eventInfo.startDateTime;
        this.newEventBody.endDateTime = eventInfo.endDateTime;
        this.newEventBody.shortDescription = eventInfo.eventSummary;
        this.newEventBody.fullDescription = eventInfo.eventDescription;
        this.newEventBody.timeZoneOffset = (new Date().getTimezoneOffset()) / 60;
    }

    async getNewEventInfo() {
        const eventInfo: NewEventInfoBody = {
            eventName: this.newEventBody.name,
            eventType: this.newEventBody.type,
            organizer: this.newEventBody.organizer,
            startDateTime: this.newEventBody.startDateTime,
            endDateTime: this.newEventBody.endDateTime,
            eventSummary: this.newEventBody.shortDescription,
            eventDescription: this.newEventBody.fullDescription
        };

        return eventInfo;
    }

    async getNewEventStartDateTime() {
        return this.newEventBody.startDateTime;
    }

    async getNewEventLocationAndImage() {
        const eventLocationAndImage = {
            latitude: this.newEventBody.latitude,
            longitude: this.newEventBody.longitude,
            venueAddress: this.newEventBody.venueAddress,
            venueName: this.newEventBody.venueName,
            imageBase64: this.imageBase64,
            imageChangedEvent: this.imageChangedEvent
        };

        return eventLocationAndImage;
    }

    async getNewEventLineSkipDetails() {
        const newEventTierDetails = {
            lineSkipEnabled: this.newEventLineSkipEnabled,
            lineSkipStartDateTimeString: this.lineSkipStartDateTimeString,
            lineSkipStartDateTime: this.lineSkipStartDateTime,
            lineSkipEndDateTimeString: this.lineSkipEndDateTimeString,
            lineSkipEndDateTime: this.lineSkipEndDateTime,
            lineSkipQuantity: this.lineSkipQuantity,
            lineSkipPrice: this.lineSkipPrice,
            lineSkipMinimumQuantity: this.lineSkipMinimumQuantity,
            lineSkipMaximumQuantity: this.lineSkipMaximumQuantity,
            guestListOnly: this.guestListOnly,
            nationwideEvent: this.nationwideEvent,
            disableActivityFeed: this.disableActivityFeed,
        };

        return newEventTierDetails;
    }

    async getNewEventPromoterDetails() {
        const newEventPromoterDetails = {
            promoterDiscounts: this.promoterDiscounts,
            promoterPayments: this.promoterPayments
        };

        return newEventPromoterDetails;
    }

    async getNewEventSettings() {
        const newEventSettings = {
            eventRefund: this.newEventRefund,
            eventTax: this.newEventTax,
            eventTerms: this.newEventTerms
        };

        return newEventSettings;
    }


    async setNewEventLocationInfo(eventLocation: NewEventLocationBody) {
        this.newEventBody.latitude = eventLocation.latitude;
        this.newEventBody.longitude = eventLocation.longitude;
        this.newEventBody.venueName = eventLocation.venueName;
        this.newEventBody.venueAddress = eventLocation.venueAddress;
        this.imageBase64 = eventLocation.imageBase64;
    }

    async setNewEventImageChangedEvent(imageChangedEvent) {
        this.imageChangedEvent = imageChangedEvent;
    }

    async setNewEventToggles(guestListOnly: boolean, nationwideEvent: boolean, disableActivityFeed: boolean) {
        this.guestListOnly = guestListOnly;
        this.nationwideEvent = nationwideEvent;
        this.disableActivityFeed = disableActivityFeed;

        this.newEventBody.isGuestListOnly = guestListOnly;
        this.newEventBody.isNationwideEvent = nationwideEvent;
        this.newEventBody.activityIsDisabled = disableActivityFeed;
    }

    async getNewEventTicketToggles() {
        const newEventTicketToggles = {
            guestListOnly: this.guestListOnly,
            nationwideEvent: this.nationwideEvent,
            disableActivityFeed: this.disableActivityFeed
        };

        return newEventTicketToggles;
    }


    async setNewEventTicketTiers(eventTicketTiers: NewEventTicketTier[], lineSkipBody: NewEventLineSkipBody) {
        this.newEventTicketTiers = eventTicketTiers;

        this.newEventLineSkipEnabled = lineSkipBody.lineSkipEnabled;
        this.lineSkipStartDateTimeString = lineSkipBody.lineSkipStartDateTimeString;
        this.lineSkipStartDateTime = lineSkipBody.lineSkipStartDateTime;
        this.lineSkipEndDateTimeString = lineSkipBody.lineSkipEndDateTimeString;
        this.lineSkipEndDateTime = lineSkipBody.lineSkipEndDateTime;
        this.lineSkipQuantity = lineSkipBody.lineSkipQuantity;
        this.lineSkipPrice = lineSkipBody.lineSkipPrice;
        this.lineSkipDescription = lineSkipBody.lineSkipDescription;
        this.lineSkipMinimumQuantity = lineSkipBody.lineSkipMinimumQuantity;
        this.lineSkipMaximumQuantity = lineSkipBody.lineSkipMaximumQuantity;
    }

    async addNewEventTicketTier(eventTicketTier: NewEventTicketTier) {
        this.newEventTicketTiers.push(eventTicketTier);
        this.newEventTicketTierSubject.next(eventTicketTier);
    }

    async getNewEventTicketTiers() {
        return this.newEventTicketTiers;
    }

    async getNewEventLineSkipEnabled() {
        return this.newEventLineSkipEnabled;
    }


    // eslint-disable-next-line max-len
    async setNewEventPromoterDiscountAndPayments(promoterDiscounts: EventPromoterDiscountTierObject[], promoterPayments: EventPromoterPaymentTierObject[]) {
        this.promoterDiscounts = promoterDiscounts;
        this.promoterPayments = promoterPayments;
    }


    async setNewEventRefundValue(refundValue: string) {
        this.newEventRefund = refundValue;
        this.newEventBody.refundAllowance = refundValue;
        this.newEventRefundObservable.next(refundValue);
    }

    async setNewEventTaxValue(taxValue: boolean) {
        this.newEventTax = taxValue;
        this.newEventBody.isTaxed = taxValue;
        this.newEventTaxObservable.next(taxValue);
    }

    async setNewEventTermsValue(terms: string) {
        this.newEventTerms = terms;
        this.newEventBody.venueEventTerms = terms;
        this.newEventTermsObservable.next(terms);
        return;
    }

    async getNewEventPromoterDiscounts() {
        return this.promoterDiscounts;
    }

    async getNewEventPromoterPayments() {
        return this.promoterPayments;
    }


    async getGuestListOnlyEventBody(guestListOnly: boolean, isNationwideEvent: boolean, disableActivityFeed: boolean) {
        this.guestListOnly = guestListOnly !== undefined ? guestListOnly : false;
        this.nationwideEvent = isNationwideEvent !== undefined ? isNationwideEvent : false;
        this.disableActivityFeed = disableActivityFeed !== undefined ? disableActivityFeed : false;

        const body = {
            name: this.newEventBody.name,
            type: this.newEventBody.type,
            organizer: this.newEventBody.organizer,
            venueUserId: await this.accountService.getCorporationUserId(),
            venueName: this.newEventBody.venueName,
            venueAddress: this.newEventBody.venueAddress,
            latitude: this.newEventBody.latitude,
            longitude: this.newEventBody.longitude,
            startDateTime: this.newEventBody.startDateTime,
            endDateTime: this.newEventBody.endDateTime,
            timeZoneOffset: this.newEventBody.timeZoneOffset,
            shortDescription: this.newEventBody.shortDescription,
            fullDescription: this.newEventBody.fullDescription,
            isNationwideEvent: this.nationwideEvent,
            isGuestListOnly: this.guestListOnly,
            activityIsDisabled: this.disableActivityFeed,
            eventImage: this.imageBase64
        };

        return body;
    }

    async getNewStrydEventBody() {
        for (const r in this.newEventTicketTiers) {
            if (Object.prototype.hasOwnProperty.call(this.newEventTicketTiers, r)) {
                if (this.newEventTicketTiers[r].quantity === null || this.newEventTicketTiers[r].quantity === 0) {
                    const index = this.newEventTicketTiers.indexOf(this.newEventTicketTiers[r]);
                    this.newEventTicketTiers.splice(index, 0);
                }
            }

        }

        this.newEventLineSkipTier = [];

        if (this.newEventLineSkipEnabled) {
            const lineSkipTier: NewEventTicketTier = {
                id: '0',
                index: 0,
                name: 'Line Skip',
                quantity: this.lineSkipQuantity,
                quantityRemaining: this.lineSkipQuantity,
                quantitySold: 0,
                price: this.lineSkipPrice,
                buyerTotal: 0,
                absorbFees: false, //TODO for line skips
                salesStartDateTime: this.lineSkipStartDateTime,
                salesStartDateTimeString: this.lineSkipStartDateTimeString,
                salesEndDateTime: this.lineSkipEndDateTime,
                salesEndDateTimeString: this.lineSkipEndDateTimeString,
                minimumQuantity: this.lineSkipMinimumQuantity,
                maximumQuantity: this.lineSkipMaximumQuantity,
                discountIsPercentage: false,
                amountOff: 0,
                percentOff: 0,
                isLineSkip: true,
                isTableTicket: false,
                description: this.lineSkipDescription,
                createDate: new Date(),
                lastChanged: new Date(),
                enableDiscount: false,
                status: TicketTierStatus.visible,
                privacyCode: null,
                promoters: []
            };
            this.newEventLineSkipTier.push(new NewEventTicketTier(lineSkipTier));
        }

        const strydEventDetailBody: NewStrydEventObject = {
            activityIsDisabled: this.newEventBody.activityIsDisabled,
            displayRemaining: this.newEventBody.displayRemaining,
            endDateTime: this.newEventBody.endDateTime,
            fullDescription: this.newEventBody.fullDescription,
            isCanceled: this.newEventBody.isCanceled,
            isDraft: this.newEventBody.isDraft,
            isGuestListOnly: this.newEventBody.isGuestListOnly,
            isNationwideEvent: this.newEventBody.isNationwideEvent,
            isOnlineEvent: this.newEventBody.isOnlineEvent,
            isPreferredPromoterOnly: this.newEventBody.isPreferredPromoterOnly,
            isPublished: this.newEventBody.isPublished,
            isTaxed: this.newEventBody.isTaxed,
            isToBeAnnounced: this.newEventBody.isToBeAnnounced,
            latitude: this.newEventBody.latitude,
            longitude: this.newEventBody.longitude,
            name: this.newEventBody.name,
            organizer: this.newEventBody.organizer,
            refundAllowance: this.newEventBody.refundAllowance,
            shortDescription: this.newEventBody.shortDescription,
            startDateTime: this.newEventBody.startDateTime,
            timeZoneOffset: this.newEventBody.timeZoneOffset,
            type: this.newEventBody.type,
            venueAddress: this.newEventBody.venueAddress,
            venueEventTerms: this.newEventBody.venueEventTerms,
            venueName: this.newEventBody.venueName
        };

        const body: NewStrydEventBody = {
            corporationUserId: await this.accountService.getCorporationUserId(),
            strydEventDetails: strydEventDetailBody,
            eventTicketTiers: this.newEventTicketTiers,
            lineSkipTier: this.newEventLineSkipTier,
            promoterDiscounts: this.promoterDiscounts,
            promoterPayments: this.promoterPayments,
            eventImage: this.imageBase64,
            orderFormOptions: null
        };

        return body;
    }

    // async setSelectedEvent(event: StrydEventObject) {
    //     this.selectedEvent = event;
    //     this.selectedEventObservable.next(event);
    // }

    // async getSelectedEvent() {
    //     return this.selectedEvent;
    // }

    async resetNewEventVariables() {
        this.addEventModalStep = 1;
        this.addEventModalStepObservable.next(this.addEventModalStep);

        this.newEventBody = {
            id: null,
            name: null,
            type: null,
            organizer: null,
            venueUserId: null,
            venueName: null,
            venueAddress: null,
            latitude: null,
            longitude: null,
            isOnlineEvent: false,
            onlineEventUrl: null,
            isToBeAnnounced: false,
            startDateTime: null,
            startDateTimeUtc: null,
            endDateTime: null,
            endDateTimeUtc: null,
            timeZone: null,
            timeZoneOffset: null,
            imageFileId: null,
            shortDescription: null,
            fullDescription: null,
            refundAllowance: null,
            eventStatus: null,
            isPublished: false,
            isDraft: false,
            displayRemaining: false,
            isTaxed: false,
            taxRate: null,
            isNationwideEvent: false,
            isPreferredPromoterOnly: true,
            isGuestListOnly: false,
            activityIsDisabled: false,
            isCanceled: false,
            eventDetailId: null,
            venueEventTerms: null,
            createDate: null,
            lastChanged: null
        };

        //new event toggles
        this.guestListOnly = false;
        this.nationwideEvent = false;
        this.disableActivityFeed = false;

        //new event image
        this.imageBase64 = null;
        this.imageChangedEvent = '';

        //new event ticket tiers
        const currentDateTime = new Date().toISOString();
        const currentDateTimeString = format(new Date(), 'MMM dd, yyyy hh:mm aa');
        this.newEventTicketTiers = [
            {
                id: '1',
                index: 1,
                name: null,
                quantity: null,
                quantityRemaining: null,
                quantitySold: null,
                price: null,
                buyerTotal: null,
                absorbFees: false,
                salesStartDateTime: currentDateTime,
                salesStartDateTimeString: currentDateTimeString,
                salesEndDateTime: currentDateTime,
                salesEndDateTimeString: currentDateTimeString,
                minimumQuantity: 1,
                maximumQuantity: 10,
                enableDiscount: false,
                discountIsPercentage: false,
                amountOff: null,
                percentOff: null,
                isLineSkip: false,
                isTableTicket: false,
                description: null,
                status: TicketTierStatus.visible,
                privacyCode: null,
                createDate: null,
                lastChanged: null,
                promoters: []
              }
        ];
        this.newEventLineSkipTier = [];

        //new event line skip
        this.newEventLineSkipEnabled = false;
        this.lineSkipStartDateTimeString = null;
        this.lineSkipStartDateTime = null;
        this.lineSkipEndDateTimeString = null;
        this.lineSkipEndDateTime = null;
        this.lineSkipQuantity = null;
        this.lineSkipPrice = null;
        this.lineSkipDescription = null;
        this.lineSkipMinimumQuantity = null;
        this.lineSkipMaximumQuantity = null;

        //new event promoter discount & payments
        this.promoterDiscounts = [];
        this.promoterPayments = [];

        //new event settings
        this.newEventRefund = 'none';
        this.newEventRefundObservable = new Subject<string>();
        this.newEventTax = false;
        this.newEventTaxObservable = new Subject<boolean>();
        this.newEventTerms = null;
        this.newEventTermsObservable = new Subject<string>();

        //selected event variables
        // this.selectedEventObservable = new Subject<StrydEventObject>();
        // this.selectedEvent = null;

        return;
    }


    setEditEventModalIsOpen(isOpen: boolean) {
        this.editEventModalIsOpenObservable.next(isOpen);
    }


    async setViewEventLinks(showViewEventLinks: boolean) {
        this.showViewEventLinks = showViewEventLinks;
        this.showViewEventLinksObservable.next(showViewEventLinks);
    }
    async getViewEventLinks() {
        return this.showViewEventLinks;
    }
}
