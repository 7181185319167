/* eslint-disable max-len */
//Angular variables
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

//Capacitor variables
import { CapacitorHttp, HttpResponse, HttpOptions } from '@capacitor/core';

//Internal services
import { AccountService } from '../account/account-service.service';

//Internal variables
import { environment } from '../../../environments/environment';

//Internal models
import { CampaignObject } from 'src/app/models/campaign.model';
import { CampaignType } from 'src/app/enums/campaigns/campaign-type';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  constructor(
    private accountService: AccountService
  ) { }

  async getNewAccessToken() {
    const userId = localStorage.getItem('phynxUserId');
    if (userId !== null && userId !== undefined && userId !== '' && userId !== 'null') {
      await this.accountService.refreshLoginMobile(userId).then(async (user) => {
        await this.accountService.setUserTokens(user.tokens).then(async () => true).catch((error) => {
          //TODO throw error here
          throw new Error('Unable to set user authentication tokens');
        });
      }).catch((error) => {
        //TODO throw error here
        throw new Error('Unable to refresh login session');
      });
    }
  }

  // eslint-disable-next-line
  /**
   * @param  {string} strydEventId @param  {number} passedPage @param  {number} pageSize
   * @summary this GET method fetches the event's facebook campaigns
   * @returns JSON array of {@link CampaignObject}
   */
  async getEventFacebookCampaigns(strydEventId: string, passedPage: number, pageSize: number, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Campaigns/GetAllEventFacebookCampaigns?strydEventId=${strydEventId}&passedPage=${passedPage}&pageSize=${pageSize}`,
      headers:{authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventFacebookCampaigns(strydEventId, passedPage, pageSize, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  // eslint-disable-next-line
  /**
   * @param  {string} strydEventId @param  {number} passedPage @param  {number} pageSize
   * @summary this GET method fetches the event's instagram campaigns
   * @returns JSON array of {@link CampaignObject}
   */
  async getEventInstagramCampaigns(strydEventId: string, passedPage: number, pageSize: number, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Campaigns/GetAllEventInstagramCampaigns?strydEventId=${strydEventId}&passedPage=${passedPage}&pageSize=${pageSize}`,
      headers:{authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventInstagramCampaigns(strydEventId, passedPage, pageSize, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  // eslint-disable-next-line
  /**
   * @param  {string} strydEventId @param  {number} passedPage @param  {number} pageSize
   * @summary this GET method fetches the event's location campaigns
   * @returns JSON array of {@link CampaignObject}
   */
   async getEventLocationCampaigns(strydEventId: string, passedPage: number, pageSize: number, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Campaigns/GetAllEventLocationCampaigns?strydEventId=${strydEventId}&passedPage=${passedPage}&pageSize=${pageSize}`,
      headers:{authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventLocationCampaigns(strydEventId, passedPage, pageSize, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  // eslint-disable-next-line
  /**
   * @param  {string} strydEventId @param  {number} passedPage @param  {number} pageSize
   * @summary this GET method fetches the event's poster campaigns
   * @returns JSON array of {@link CampaignObject}
   */
  async getEventPosterCampaigns(strydEventId: string, passedPage: number, pageSize: number, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Campaigns/GetAllEventPosterCampaigns?strydEventId=${strydEventId}&passedPage=${passedPage}&pageSize=${pageSize}`,
      headers:{authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventPosterCampaigns(strydEventId, passedPage, pageSize, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  // eslint-disable-next-line
  /**
   * @param  {string} strydEventId
   * @summary this GET method fetches the event's poster campaigns
   * @returns JSON array of {@link CampaignObject}
   */
   async addNewCampaign(body: object, retry = true){
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Campaigns/AddNewCampaign`,
      headers:{
        'content-type':'application/json',
        authorization:this.accountService.authorizationHeader()
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addNewCampaign(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  // eslint-disable-next-line
  /**
   * @param  {string} strydEventId
   * @summary this GET method fetches the event's poster campaigns
   * @returns JSON array of {@link CampaignObject}
   */
   async editCampaign(body: object, retry = true){
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Campaigns/EditCampaign`,
      headers:{
        'content-type':'application/json',
        authorization:this.accountService.authorizationHeader()
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.editCampaign(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  // eslint-disable-next-line
  /**
   * @param  {string} campaignId
   * @summary this GET method fetches the passed campaign's posts
   * @returns JSON array of {@link SocialCampaignObjects}
   */
   async getAllSocialCampaignPosts(campaignId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Campaigns/GetAllSocialCampaignPosts?campaignId=${campaignId}`,
      headers:{authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getAllSocialCampaignPosts(campaignId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  // eslint-disable-next-line
  /**
   * @param  {string} campaignId
   * @summary this GET method fetches the passed campaign's post insights
   * @returns JSON array of {@link FacebookCampaignInsightsObject} or {@link InstagramCampaignInsightsObject}
   */
   async getSocialCampaignPostInsights(campaignId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Campaigns/GetAllSocialCampaignPostInsights?campaignId=${campaignId}`,
      headers:{authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getSocialCampaignPostInsights(campaignId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  // eslint-disable-next-line
  /**
   * @param  {string} campaignId
   * @summary this GET method fetches the passed location campaign's insights
   * @returns JSON array of {@link LocationInsightsObject}
   */
   async getLocationCampaignInsights(campaignId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Campaigns/GetLocationRouteInsights?campaignId=${campaignId}`,
      headers:{authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getLocationCampaignInsights(campaignId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  // eslint-disable-next-line
  /**
   * @param  {string} campaignId
   * @summary this PUT method updates the passed location campaign as complete
   * @returns IActionResult
   */
   async markLocationCampaignComplete(campaignId: string, retry = true){
    const options: HttpOptions = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Campaigns/MarkLocationCampaignComplete?campaignId=${campaignId}`,
      headers:{authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.markLocationCampaignComplete(campaignId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  // eslint-disable-next-line
  /**
   * @param  {string} campaignId
   * @summary this GET method fetches the passed poster campaign's insights
   * @returns JSON array of {@link PosterInsightsObject}
   */
   async getPosterCampaignInsights(campaignId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Campaigns/GetPosterRouteInsights?campaignId=${campaignId}`,
      headers:{authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPosterCampaignInsights(campaignId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  // eslint-disable-next-line
  /**
   * @param  {string} campaignId
   * @summary this PUT method updates the passed poster campaign as complete
   * @returns IActionResult
   */
   async markPosterCampaignComplete(campaignId: string, retry = true){
    const options: HttpOptions = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Campaigns/MarkPosterCampaignComplete?campaignId=${campaignId}`,
      headers:{authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.markPosterCampaignComplete(campaignId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  // eslint-disable-next-line
  /**
   * @param  {string} campaignId
   * @summary this GET method fetches the passed events total sales by campaign type
   * @returns JSON array of {@link SalesByCampaignTypeObject}
   */
   async getSalesByCampaignType(eventId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Campaigns/GetSalesByCampaignType?eventId=${eventId}`,
      headers:{authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getSalesByCampaignType(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  // eslint-disable-next-line
  /**
   * @param  {string} campaignId
   * @summary this GET method fetches the passed events total routes completed by campaign type
   * @returns JSON array of {@link CompletionsByCampaignTypeObject}
   */
   async getCompletionsByCampaignType(eventId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Campaigns/GetCompletionsByCampaignType?eventId=${eventId}`,
      headers:{authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getCompletionsByCampaignType(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  // eslint-disable-next-line
  /**
   * @param  {string} campaignId
   * @summary this GET method fetches the passed events total profit by campaign type
   * @returns JSON array of {@link ProfitByCampaignTypeObject}
   */
   async getProfitByCampaignType(eventId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Campaigns/GetProfitByCampaignType?eventId=${eventId}`,
      headers:{authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getProfitByCampaignType(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  // eslint-disable-next-line
  /**
   * @param  {string} eventId
   * @summary this GET method fetches the passed events link campaign id
   * @returns link campaign ID as a string
   */
  async getEventLinkCampaignId(eid: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Campaigns/EventLinkCampaignId?eid=${eid}`,
      headers:{authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventLinkCampaignId(eid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventCampaignByType(eventId: string, type: CampaignType, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Campaigns/GetEventCampaignByType?eid=${eventId}&type=${type}`,
      headers:{authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventCampaignByType(eventId, type, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }
}
