<div class="w-full h-2/6 bg-dark-default text-white">

  <div (click)="selectAddType('individual')" class="h-2/6 w-full px-2 flex flex-col justify-center items-start cursor-pointer hover:bg-dark-tint">
    <p class="text-lg font-semibold">Individual</p>
    <p class="text-xs sm:text-base">Add a singular guest reservation</p>
  </div>

  <div (click)="selectAddType('bulk')" class="h-2/6 w-full px-2 flex flex-col justify-center items-start cursor-pointer hover:bg-dark-tint">
    <p class="text-lg font-semibold">Bulk Add</p>
    <p class="text-xs sm:text-base">Add multiple guests separated by a line break</p>
  </div>

  <div (click)="selectAddType('excel')" class="h-2/6 w-full px-2 flex flex-col justify-center items-start cursor-pointer hover:bg-dark-tint">
    <p class="text-lg font-semibold">Excel Upload</p>
    <p class="text-xs sm:text-base">Upload an excel sheet of guests (template provided)</p>
  </div>

</div>
