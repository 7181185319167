// External
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

//Ionic imports
import { ModalController } from '@ionic/angular';

//Other External imports
import { format, parseISO } from 'date-fns';

//Internal components
import { AddCampaignComponent } from '../components/campaigns/add-campaign/add-campaign.component';

//Internal models
import { CampaignObject } from '../models/campaign.model';

//Internal enums
import { CampaignType } from '../enums/campaigns/campaign-type';

export interface WeekdayObject {
    internalValue: string;
    commonName: string;
    selected: boolean;
    index: number;
}

export interface Marker {
    id: string;
    latitude: number;
    longitude: number;
    label: string;
    note: string;
    index: number;
}

@Injectable()
export class AddCampaignProvider {
    //edit campaign
    isEditCampaign: boolean;
    existingCampaignId: string;

    //error
    newCampaignErrorObservable = new Subject();
    newCampaignError: string;

    //add new campaign modal
    addCampaignModal: any;
    modalIsOpenObservable = new Subject();
    modalIsOpen: boolean;

    //campaign step
    selectedCampaignStepObservable = new Subject();
    selectedCampaignStep: number;

    //campaign image
    selectedCampaignImageObservable = new Subject();
    selectedCampaignImage: string;
    selectedCampaignExistingImageId: string;
    selectedCampaignNewImageExists: boolean;

    //campaign type
    selectedCampaignTypeObservable = new Subject();
    selectedCampaignType: string;
    selectedCampaignTypeId: CampaignType;

    //campaign time range
    selectedCampaignTimeRangeObservable = new Subject();
    selectedCampaignTimeRange: string;

    //campaign custom start/end times
    selectedCampaignStartTimeObservable = new Subject();
    selectedCampaignStartTime: string;
    selectedCampaignStartTimeEdited: boolean;
    selectedCampaignEndTimeObservable = new Subject();
    selectedCampaignEndTime: string;
    selectedCampaignEndTimeEdited: boolean;

    //campaign date range
    selectedCampaignDateRangeObservable = new Subject();
    selectedCampaignDateRange: string;

    //campaign custom start/end dates
    selectedCampaignStartDateObservable = new Subject();
    selectedCampaignStartDate: string;
    selectedCampaignEndDateObservable = new Subject();
    selectedCampaignEndDate: string;

    //campaign weekdays
    selectedCampaignWeekdaysObservable = new Subject();
    selectedCampaignWeekdays: string[] = ['0', '0', '0', '0', '0', '0', '0'];

    //campaign image
    selectedCampaignRateObservable = new Subject();
    selectedCampaignRate: number;

    //campaign public comment
    selectedCampaignPublicComment: string;
    selectedCampaignPublicCommentObservable = new Subject();

    //location campaign coordinates
    locationCampaignCoordinatesObservable = new Subject();
    locationCampaignLatitude: number;
    locationCampaignLongitude: number;
    locationCampaignRadius: number;
    locationCampaignZoom: number;

    //location campaign duration
    locationCampaignDurationObservable = new Subject();
    locationCampaignDuration: number;

    //poster campaign markers
    posterCampaignAddedMarkersObservable = new Subject();
    posterCampaignRemovedMarkersObservable = new Subject();
    posterCampaignUpdatedMarkersObservable = new Subject();
    posterCampaignMarkers: Marker[] = [];

    //poster campaign selected marker
    posterCampaignSelectedMarkerObservable = new Subject();
    posterCampaignSelectedMarker: Marker;

    //location campaign coordinates
    posterCampaignCoordinatesObservable = new Subject();
    posterCampaignLatitude: number;
    posterCampaignLongitude: number;
    posterCampaignZoom: number;

    //internal variables used for returns
    timeRangeOptions = [
        {internalValue: 'any', commonName: 'Any Time', description: 'Promoters can post any time of the day.'},
        {internalValue: 'morning', commonName: 'Morning', description: 'Promoters can post between 6AM and Noon.'},
        {internalValue: 'afternoon', commonName: 'Afternoon', description: 'Promoters can post between Noon and 5PM.'},
        {internalValue: 'evening', commonName: 'Evening', description: 'Promoters can post between 5PM and 9PM.'},
        {internalValue: 'night', commonName: 'Night', description: 'Promoters can post between 9PM and 6AM.'},
        {internalValue: 'custom', commonName: 'Custom', description: 'Set a custom time period for when promoters can post.'},
    ];

    customTimeOptions = [
        {internalValue: '00:00:00', commonValue: '12:00 AM', index: 0},
        {internalValue: '00:30:00', commonValue: '12:30 AM', index: 1},
        {internalValue: '01:00:00', commonValue: '1:00 AM', index: 2},
        {internalValue: '01:30:00', commonValue: '1:30 AM', index: 3},
        {internalValue: '02:00:00', commonValue: '2:00 AM', index: 4},
        {internalValue: '02:30:00', commonValue: '2:30 AM', index: 5},
        {internalValue: '03:00:00', commonValue: '3:00 AM', index: 6},
        {internalValue: '03:30:00', commonValue: '3:30 AM', index: 7},
        {internalValue: '04:00:00', commonValue: '4:00 AM', index: 8},
        {internalValue: '04:30:00', commonValue: '4:30 AM', index: 9},
        {internalValue: '05:00:00', commonValue: '5:00 AM', index: 10},
        {internalValue: '05:30:00', commonValue: '5:30 AM', index: 11},
        {internalValue: '06:00:00', commonValue: '6:00 AM', index: 12},
        {internalValue: '06:30:00', commonValue: '6:30 AM', index: 13},
        {internalValue: '07:00:00', commonValue: '7:00 AM', index: 14},
        {internalValue: '07:30:00', commonValue: '7:30 AM', index: 15},
        {internalValue: '08:00:00', commonValue: '8:00 AM', index: 16},
        {internalValue: '08:30:00', commonValue: '8:30 AM', index: 17},
        {internalValue: '09:00:00', commonValue: '9:00 AM', index: 18},
        {internalValue: '09:30:00', commonValue: '9:30 AM', index: 19},
        {internalValue: '10:00:00', commonValue: '10:00 AM', index: 20},
        {internalValue: '10:30:00', commonValue: '10:30 AM', index: 21},
        {internalValue: '11:00:00', commonValue: '11:00 AM', index: 22},
        {internalValue: '11:30:00', commonValue: '11:30 AM', index: 23},
        {internalValue: '12:00:00', commonValue: '12:00 PM', index: 24},
        {internalValue: '12:30:00', commonValue: '12:30 PM', index: 25},
        {internalValue: '13:00:00', commonValue: '1:00 PM', index: 26},
        {internalValue: '13:30:00', commonValue: '1:30 PM', index: 27},
        {internalValue: '14:00:00', commonValue: '2:00 PM', index: 28},
        {internalValue: '14:30:00', commonValue: '2:30 PM', index: 29},
        {internalValue: '15:00:00', commonValue: '3:00 PM', index: 30},
        {internalValue: '15:30:00', commonValue: '3:30 PM', index: 31},
        {internalValue: '16:00:00', commonValue: '4:00 PM', index: 32},
        {internalValue: '16:30:00', commonValue: '4:30 PM', index: 33},
        {internalValue: '17:00:00', commonValue: '5:00 PM', index: 34},
        {internalValue: '17:30:00', commonValue: '5:30 PM', index: 35},
        {internalValue: '18:00:00', commonValue: '6:00 PM', index: 36},
        {internalValue: '18:30:00', commonValue: '6:30 PM', index: 37},
        {internalValue: '19:00:00', commonValue: '7:00 PM', index: 38},
        {internalValue: '19:30:00', commonValue: '7:30 PM', index: 39},
        {internalValue: '20:00:00', commonValue: '8:00 PM', index: 40},
        {internalValue: '20:30:00', commonValue: '8:30 PM', index: 41},
        {internalValue: '21:00:00', commonValue: '9:00 PM', index: 42},
        {internalValue: '21:30:00', commonValue: '9:30 PM', index: 43},
        {internalValue: '22:00:00', commonValue: '10:00 PM', index: 44},
        {internalValue: '22:30:00', commonValue: '10:30 PM', index: 45},
        {internalValue: '23:00:00', commonValue: '11:00 PM', index: 46},
        {internalValue: '23:30:00', commonValue: '11:30 PM', index: 47}
    ];

    weekdayOptions: WeekdayObject[] = [
        {internalValue: 'sunday', commonName: 'Sunday', selected: false, index: 0},
        {internalValue: 'monday', commonName: 'Monday', selected: false, index: 1},
        {internalValue: 'tuesday', commonName: 'Tuesday', selected: false, index: 2},
        {internalValue: 'wednesday', commonName: 'Wednesday', selected: false, index: 3},
        {internalValue: 'thursday', commonName: 'Thursday', selected: false, index: 4},
        {internalValue: 'friday', commonName: 'Friday', selected: false, index: 5},
        {internalValue: 'saturday', commonName: 'Saturday', selected: false, index: 6}
    ];

    labelOptions = [
        {label: 'A', index: 0},
        {label: 'B', index: 1},
        {label: 'C', index: 2},
        {label: 'D', index: 3},
        {label: 'E', index: 4},
        {label: 'F', index: 5},
        {label: 'G', index: 6},
        {label: 'H', index: 7},
        {label: 'I', index: 8},
        {label: 'J', index: 9},
        {label: 'K', index: 10},
        {label: 'L', index: 11},
        {label: 'M', index: 12},
        {label: 'N', index: 13},
        {label: 'O', index: 14},
        {label: 'P', index: 15},
        {label: 'Q', index: 16},
        {label: 'R', index: 17},
        {label: 'S', index: 18},
        {label: 'T', index: 19},
        {label: 'U', index: 20},
        {label: 'V', index: 21},
        {label: 'W', index: 22},
        {label: 'X', index: 23},
        {label: 'Y', index: 24},
        {label: 'Z', index: 25},
        {label: 'AA', index: 26},
        {label: 'BB', index: 27},
        {label: 'CC', index: 28},
        {label: 'DD', index: 29},
        {label: 'EE', index: 30},
        {label: 'FF', index: 31},
        {label: 'GG', index: 32},
        {label: 'HH', index: 33},
        {label: 'II', index: 34},
        {label: 'JJ', index: 35},
        {label: 'KK', index: 36},
        {label: 'LL', index: 37},
        {label: 'MM', index: 38},
        {label: 'NN', index: 39},
        {label: 'OO', index: 40},
        {label: 'PP', index: 41},
        {label: 'QQ', index: 42},
        {label: 'RR', index: 43},
        {label: 'SS', index: 44},
        {label: 'TT', index: 45},
        {label: 'UU', index: 46},
        {label: 'VV', index: 47},
        {label: 'WW', index: 48},
        {label: 'XX', index: 49},
        {label: 'YY', index: 50},
        {label: 'ZZ', index: 51}
      ];

    constructor(
        public modalCtrl: ModalController,
    ) {

    }

    /**
     * @summary this configures and presents the add campaign modal
     */
    async presentAddCampaignModal(){
        this.addCampaignModal = await this.modalCtrl.create({
            breakpoints: [0.1, 0.5, 0.75, 1],
            initialBreakpoint: 1,
            component: AddCampaignComponent
        });

        await this.addCampaignModal.present();

        this.modalIsOpen = true;
        this.modalIsOpenObservable.next(true);

        this.addCampaignModal.onDidDismiss().then((res) => {
            this.resetAllVariables();
        });
    }

    async dismissAddCampaignModal(){
        await this.addCampaignModal.dismiss();

        this.modalIsOpen = false;
        this.modalIsOpenObservable.next(false);
    }

    /**
     * @returns isEditCampaign {bool}
     */
     getSelectedCampaignIsEdit() {
        return this.isEditCampaign;
    }

    // eslint-disable-next-line
    /**
     * @param  {number} campaignStep
     * @task1 set the {@var selectedCampaignStep} to the passed campaignStep
     * @task2 call the {@method next()} on {@var selectedCampaignStepObservable} so that...
     * all subscriptions in other components fetch the new {@var selectedCampaignStep}
     */
    setSelectedCampaignStep(campaignStep: number) {
        //task 1
        this.selectedCampaignStep = campaignStep;
        //task 2
        this.selectedCampaignStepObservable.next(campaignStep);
    }


    /**
     * @returns selectedCampaignStep {number}
     */
    getSelectedCampaignStep() {
        return this.selectedCampaignStep;
    }


    // eslint-disable-next-line
    /**
     * @param  {number} campaignImage
     * @task1 set the {@var selectedCampaignImage} to the passed campaignImage
     * @task2 if this campaign is being edited then set the {@var selectedCampaignNewImageExists} to true
     * @task3 call the {@method next()} on {@var selectedCampaignImageObservable} so that...
     * all subscriptions in other components fetch the new {@var selectedCampaignImage}
     */
     setSelectedCampaignImage(campaignImage: string) {
        //task 1
        this.selectedCampaignImage = campaignImage;

        //task 2
        if (this.isEditCampaign) {
            this.selectedCampaignNewImageExists = true;
        }

        //task 3
        this.selectedCampaignImageObservable.next(campaignImage);
    }


    /**
     * @returns selectedCampaignImage {string}
     */
    getSelectedCampaignImage() {
        return this.selectedCampaignImage;
    }


    /**
     * @returns selectedCampaignExistingImageId {string}
     */
     getSelectedCampaignExistingImageId() {
        return this.selectedCampaignExistingImageId;
    }


    // eslint-disable-next-line
    /**
     * @param  {number} campaignType
     * @task1 call {@link getPassedCampaignType} to get the associated CampaignType
     * @task2 set the {@var selectedCampaignType} to the passed campaignType
     * @task3 set the {@var selectedCampaignTypeId} to the returned CampaignType from task 1
     * @task4 call the {@method next()} on {@var selectedCampaignTypeObservable} so that...
     * all subscriptions in other components fetch the new {@var selectedCampaignType}
     */
    async setSelectedCampaignType(campaignType: string) {
        //task 1
        let type: CampaignType;
        const result = await this.getPassedCampaignType(campaignType);

        //task 2
        this.selectedCampaignType = campaignType;
        //task 3
        this.selectedCampaignTypeId = result;
        //task 4
        this.selectedCampaignTypeObservable.next(campaignType);
    }

    // eslint-disable-next-line
    /**
     * @param  {string} campaignType @returns {CampaignType} CampaignType Enum
     * @summary take the campaign type string and return it's corresponding type ID
     */
    async getPassedCampaignType(campaignType: string) {
        switch(true) {
            case campaignType === 'facebook': {
                return CampaignType.facebook;
            }
            case campaignType === 'instagram': {
                return CampaignType.instagram;
            }
            case campaignType === 'location': {
                return CampaignType.location;
            }
            case campaignType === 'posters': {
                return CampaignType.posterCode;
            }
        };
    }


    /**
     * @returns selectedCampaignType {string}
     */
    getSelectedCampaignType() {
        return this.selectedCampaignType;
    }


    /**
     * @returns selectedCampaignTypeId {CampaignType}
     */
     getSelectedCampaignTypeId() {
        return this.selectedCampaignTypeId;
    }


    // eslint-disable-next-line
    /**
     * @param  {number} campaignTimeRange
     * @task1 set the {@var selectedCampaignTimeRange} to the passed campaignTimeRange
     * @task2 call the {@method next()} on {@var selectedCampaignTimeRangeObservable} so that...
     * all subscriptions in other components fetch the new {@var selectedCampaignTimeRange}
     * @task3 if the campaignTimeRange is not 'custom' then call {@link processPresetTimeRange}
     */
    setSelectedCampaignTimeRange(campaignTimeRange: string) {
        //task 1
        this.selectedCampaignTimeRange = campaignTimeRange;
        //task 2
        this.selectedCampaignTimeRangeObservable.next(campaignTimeRange);
    }


    /**
     * @returns selectedCampaignTimeRange {string}
     */
    getSelectedCampaignTimeRange() {
        return this.selectedCampaignTimeRange;
    }


    // eslint-disable-next-line
    /**
     * @param  {number} startTime
     * @task1 set the {@var selectedCampaignStartTime} to the passed startTime
     * @task2 call the {@method next()} on {@var selectedCampaignStartTimeObservable} so that...
     * all subscriptions in other components fetch the new {@var selectedCampaignStartTime}
     * @task3 if we are editing a campaign then we need to set {@var selectedCampaignStartTimeEdited} to true
     */
    setSelectedCampaignStartTime(startTime: string) {
        //task 1
        this.selectedCampaignStartTime = startTime;
        //task 2
        this.selectedCampaignStartTimeObservable.next(startTime);
        //task 3
        this.selectedCampaignStartTimeEdited = this.isEditCampaign ? true : false;
    }


    /**
     * @returns selectedCampaignStartTime {string}
     */
    getSelectedCampaignStartTime() {
        return this.selectedCampaignStartTime;
    }


    /**
     * @returns commonValue of selectedCampaignStartTime {string}
     */
    getFormattedSelectedCampaignStartTime() {
        // eslint-disable-next-line max-len
        const startTime = new Date(this.selectedCampaignStartTime).toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true});

        if (this.isEditCampaign && !this.selectedCampaignStartTimeEdited) {
            return this.selectedCampaignStartTime;
        } else {
            return startTime;
        }
    }


    // eslint-disable-next-line
    /**
     * @param  {number} endTime
     * @task1 set the {@var selectedCampaignEndTime} to the passed endTime
     * @task2 call the {@method next()} on {@var selectedCampaignEndTimeObservable} so that...
     * all subscriptions in other components fetch the new {@var selectedCampaignEndTime}
     * @task3 if we are editing a campaign then we need to set {@var selectedCampaignEndTimeEdited} to true
     */
    setSelectedCampaignEndTime(endTime: string) {
        //task 1
        this.selectedCampaignEndTime = endTime;
        //task 2
        this.selectedCampaignEndTimeObservable.next(endTime);
        //task 3
        this.selectedCampaignEndTimeEdited = this.isEditCampaign ? true : false;
    }


    /**
     * @returns selectedCampaignEndTime {string}
     */
    getSelectedCampaignEndTime() {
        return this.selectedCampaignEndTime;
    }


    /**
     * @returns commonValue of selectedCampaignEndTime {string}
     */
     getFormattedSelectedCampaignEndTime() {
        const endTime = new Date(this.selectedCampaignEndTime).toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true});

        if (this.isEditCampaign && !this.selectedCampaignEndTimeEdited) {
            return this.selectedCampaignEndTime;
        } else {
            return endTime;
        }
    }


    // eslint-disable-next-line
    /**
     * @param  {number} startDate
     * @task1 set the {@var selectedCampaignStartDate} to the passed startDate
     * @task2 call the {@method next()} on {@var selectedCampaignStartDateObservable} so that...
     * all subscriptions in other components fetch the new {@var selectedCampaignStartDate}
     */
    setSelectedCampaignStartDate(startDate: string) {
        //task 1
        this.selectedCampaignStartDate = startDate;
        //task 2
        this.selectedCampaignStartDateObservable.next(startDate);
    }


    /**
     * @returns selectedCampaignStartDate {string}
     */
    getSelectedCampaignStartDate() {
        return this.selectedCampaignStartDate;
    }

    selectedCampaignStartDateIsSet() {
        return this.selectedCampaignStartDate != null;
    }


    // eslint-disable-next-line
    /**
     * @param  {number} endDate
     * @task1 set the {@var selectedCampaignEndDate} to the passed endDate
     * @task2 call the {@method next()} on {@var selectedCampaignEndDateObservable} so that...
     * all subscriptions in other components fetch the new {@var selectedCampaignEndDate}
     */
    setSelectedCampaignEndDate(endDate: string) {
        //task 1
        this.selectedCampaignEndDate = endDate;
        //task 2
        this.selectedCampaignEndDateObservable.next(endDate);
    }


    /**
     * @returns selectedCampaignEndDate {string}
     */
    getSelectedCampaignEndDate() {
        return this.selectedCampaignEndDate;
    }


    // eslint-disable-next-line
    /**
     * @param  {number} campaignDateRange
     * @task1 set the {@var selectedCampaignDateRange} to the passed campaignDateRange
     * @task2 call the {@method next()} on {@var selectedCampaignDateRangeObservable} so that...
     * all subscriptions in other components fetch the new {@var selectedCampaignDateRange}
     */
    setSelectedCampaignDateRange(campaignDateRange: string) {
        //task 1
        this.selectedCampaignDateRange = campaignDateRange;

        //task 2
        this.selectedCampaignDateRangeObservable.next(campaignDateRange);
    }


    /**
     * @returns the selectedCampaignDateRange {string}
     */
    getSelectedCampaignDateRange() {
        return this.selectedCampaignDateRange;
    }


    // eslint-disable-next-line
    /**
     * @param  {number} index @param  {boolean} value
     * @task1 set the {@var selectedCampaignWeekdays} to the passed value in the passed index position
     * @task2 set the corresponding {@var weekdayOptions} to the value being passed
     * @task3 call the {@method next()} on {@var selectedCampaignWeekdaysObservable} so that...
     * all subscriptions in other components fetch the new {@var selectedCampaignWeekdays}
     */
    setSelectedCampaignWeekdays(index: number, value: boolean) {
        //task 1
        this.selectedCampaignWeekdays[index] = value ? '1' : '0';

        //task 2
        const weekday = this.weekdayOptions.find(wo => wo.index === index);
        weekday.selected = value;

        //task 3
        this.selectedCampaignWeekdaysObservable.next(value);
    }


    /**
     * @returns selectedCampaignWeekdays {string[]}
     */
    getSelectedCampaignWeekdays() {
        return this.selectedCampaignWeekdays;
    }

    /**
     * @returns weekdayOptions {WeekdayObject[]}
     */
     getSelectedCampaignWeekdayOptions() {
        return this.weekdayOptions;
    }


    /**
     * @returns formatted selectedCampaignWeekdays {string[]}
     */
     getFormattedSelectedCampaignWeekdays() {
        const selectedDays = this.weekdayOptions.filter((item) => item.selected === true);
        const formattedDays: string[] = [];

        for (const r in selectedDays) {
            if (Object.prototype.hasOwnProperty.call(selectedDays, r)) {
                formattedDays.push(selectedDays[r].commonName);
            }
        }

        return formattedDays;
    }


    // eslint-disable-next-line
    /**
     * @param  {number} rate
     * @task1 set the {@var selectedCampaignRate} to the passed rate
     * @task2 call the {@method next()} on {@var selectedCampaignRateObservable} so that...
     * all subscriptions in other components fetch the new {@var selectedCampaignRate}
     */
     setSelectedCampaignRate(rate: number) {
        //task 1
        this.selectedCampaignRate = rate;
        //task 2
        this.selectedCampaignRateObservable.next(rate);
    }


    /**
     * @returns selectedCampaignRate {number}
     */
    getSelectedCampaignRate() {
        return this.selectedCampaignRate;
    }


    // eslint-disable-next-line
    /**
     * @param  {number} latitude @param {number} longitude @param {number} radius @param {number} zoom
     * @task1 set the {@var locationCampaignLatitude} to the passed latitude
     * @task2 set the {@var locationCampaignLongitude} to the passed longitude
     * @task3 set the {@var locationCampaignRadius} to the passed radius
     * @task4 set the {@var locationCampaignZoom} to the passed zoom
     * @task5 call the {@method next()} on {@var locationCampaignCoordinatesObservable} so that...
     * all subscriptions in other components fetch the new coordinates
     */
     setLocationCampaignCoordinates(latitude: number, longitude: number, radius: number, zoom: number) {
        //task 1
        this.locationCampaignLatitude = latitude;
        //task 2
        this.locationCampaignLongitude = longitude;
        //task 3
        this.locationCampaignRadius = radius;
        //task 4
        this.locationCampaignZoom = zoom;
        //task 5
        this.locationCampaignCoordinatesObservable.next(true);
    }


    /**
     * @returns the location campaign coordinates {array}
     */
    getLocationCampaignCoordinates() {
        const coordinates = {
            latitude: this.locationCampaignLatitude,
            longitude: this.locationCampaignLongitude,
            radius: this.locationCampaignRadius,
            zoom: this.locationCampaignZoom
        };

        return coordinates;
    }


    // eslint-disable-next-line
    /**
     * @param  {string} publicComment
     * @task1 set the {@var selectedCampaignPublicComment} to the passed publicComment
     * @task2 call the {@method next()} on {@var selectedCampaignPublicCommentObservable} so that...
     * all subscriptions in other components fetch the new {@var selectedCampaignPublicComment}
     */
     setCampaignPublicComment(publicComment: string) {
        //task 1
        this.selectedCampaignPublicComment = publicComment;
        //task 2
        this.selectedCampaignPublicCommentObservable.next(publicComment);
    }


    /**
     * @returns selectedCampaignEndDate {string}
     */
    getSelectedCampaignPublicComment() {
        return this.selectedCampaignPublicComment;
    }


    // eslint-disable-next-line
    /**
     * @param  {string} duration
     * @task1 set the {@var locationCampaignDuration} to the passed duration
     * @task2 call the {@method next()} on {@var locationCampaignDurationObservable} so that...
     * all subscriptions in other components fetch the new {@var locationCampaignDuration}
     */
     setCampaignDuration(duration: number) {
        //task 1
        this.locationCampaignDuration = duration;
        //task 2
        this.locationCampaignDurationObservable.next(duration);
    }


    /**
     * @returns selectedCampaignEndDate {string}
     */
    getSelectedCampaignDuration() {
        return this.locationCampaignDuration;
    }


    // eslint-disable-next-line
    /**
     * @param  {string} latitude @param {number} longitude
     * @task1 get the marker's associated index
     * @task2 configure the marker's corresponding label (A through ZZ)
     * @task3 push the new marker object into the markers array
     * @task4 call the {@method next()} on {@var posterCampaignAddedMarkersObservable} so that...
     * all subscriptions in other components fetch the new {@var markers} object
     */
     addPosterCampaignMarker(latitude: number, longitude: number) {
        try {
            //task 1
            const markersLength = this.posterCampaignMarkers.length;

            if (markersLength === 52) {
                throw new Error('Cannot add more than 52 posters to a poster campaign');
            }

            const index = markersLength > 0 ? markersLength : 0;

            //task 2
            const latestLabel = this.labelOptions.find(lo => lo.index === index);
            const label = latestLabel.label;

            //task 3
            this.posterCampaignMarkers.push({
                id: null,
                latitude,
                longitude,
                label,
                note: '',
                index
            });

            //task 4
            this.posterCampaignAddedMarkersObservable.next(this.posterCampaignMarkers[index]);
        }
        catch (err) {
            this.newCampaignError = 'Cannot add more than 52 posters to a poster campaign';
            this.newCampaignErrorObservable.next('Cannot add more than 52 posters to a poster campaign');

            return new Error(err);
        }
    }


    // eslint-disable-next-line
    /**
     * @param  {string} index
     * @task1 check to see if there is only one poster in the {@var posterCampaignMarkers} array and, if so, then return
     * @task2 remove the passed marker object from the markers array
     * @task3 reassign indexes and labels after removing the passed marker
     * @task4 call the {@method next()} on {@var posterCampaignRemovedMarkersObservable} so that...
     * all subscriptions in other components fetch the removed {@var markers} object
     */
     async removePosterCampaignMarker(index: number) {
        //task 1
        if (this.posterCampaignMarkers.length === 1) {
            return 'Cannot remove this marker as it is the last marker in the campaign';
        }

        //task 2
        this.posterCampaignMarkers.splice(index, 1);

        //task 3
        for (let i = 0; i < this.posterCampaignMarkers.length; i++) {
            this.posterCampaignMarkers[i].index = i;

            const labelOption = this.labelOptions.find(lo => lo.index === i);
            const label = labelOption.label;

            this.posterCampaignMarkers[i].label = label;
        }

        //task 4
        this.posterCampaignRemovedMarkersObservable.next(index);
    }


    /**
     * @returns selectedCampaignEndDate {string}
     */
    getSelectedPosterCampaignMarkers() {
        return this.posterCampaignMarkers;
    }


    // eslint-disable-next-line
    /**
     * @param  {Marker} selectedMarker
     * @task1 set the {@var posterCampaignSelectedMarker} to the passed selectedMarker
     * @task2 call the {@method next()} on {@var posterCampaignSelectedMarkerObservable} so that...
     * all subscriptions in other components fetch the new {@var posterCampaignSelectedMarker}
     */
     setPosterCampaignSelectedMarker(selectedMarker: Marker) {
        //task 1
        this.posterCampaignSelectedMarker = selectedMarker;
        //task 2
        this.posterCampaignSelectedMarkerObservable.next(selectedMarker);
    }


    /**
     * @returns selectedCampaignEndDate {string}
     */
    getPosterCampaignSelectedMarker() {
        return this.posterCampaignSelectedMarker;
    }


    // eslint-disable-next-line
    /**
     * @param  {Marker} selectedMarker
     * @task1 update the respective {@var posterCampaignMarkers} object to the passed selectedMarker
     * @task2 call the {@method next()} on {@var posterCampaignSelectedMarkerObservable} so that...
     * all subscriptions in other components fetch the new {@var posterCampaignSelectedMarker}
     */
     updatePosterCampaignSelectedMarker(selectedMarker: Marker, ) {
        //task 1
        this.posterCampaignMarkers[selectedMarker.index] = selectedMarker;
        //task 2
        this.posterCampaignUpdatedMarkersObservable.next(selectedMarker);
    }


    // eslint-disable-next-line
    /**
     * @param  {number} latitude @param {number} longitude @param {number} zoom
     * @task1 set the {@var posterCampaignLatitude} to the passed latitude
     * @task2 set the {@var posterCampaignLongitude} to the passed longitude
     * @task3 set the {@var posterCampaignZoom} to the passed zoom
     * @task4 call the {@method next()} on {@var posterCampaignCoordinatesObservable} so that...
     * all subscriptions in other components fetch the new coordinates
     */
     setPosterCampaignCoordinates(latitude: number, longitude: number, zoom: number) {
        //task 1
        this.posterCampaignLatitude = latitude;
        //task 2
        this.posterCampaignLongitude = longitude;
        //task 4
        this.posterCampaignZoom = zoom;
        //task 5
        this.posterCampaignCoordinatesObservable.next(true);
    }


    /**
     * @returns the location campaign coordinates {array}
     */
    getPosterCampaignCoordinates() {
        const coordinates = {
            latitude: this.posterCampaignLatitude,
            longitude: this.posterCampaignLongitude,
            zoom: this.posterCampaignZoom
        };

        return coordinates;
    }

    //eslint-disable-next-line
    /**
     * @param  {CampaignObject} campaign
     * @summary this function takes the passed campaign object and assigns each value to its
     * associated/respective local variable and then, once all variables are assigned, opens the add-campaign modal
     */
    async processEditCampaign(campaign: CampaignObject) {
        //task 1: set isEditCampaign to true and assign the campaign ID
        this.isEditCampaign = true;
        this.existingCampaignId = campaign.id;

        //task 2: process the campaign type id by calling {@link processPassedCampaignTypeId}
        await this.processPassedCampaignTypeId(campaign.campaignTypeId);

        //task 3: set the campaign image id
        this.selectedCampaignExistingImageId = campaign.imageFileId;

        //task 4: set all respective campaign time variables
        this.selectedCampaignTimeRange = await this.returnTimeRange(campaign);

        let rawStartTime = format(new Date(campaign.startTime), 'kk:mm:SS');
        if (rawStartTime === '24:00:00'){
            rawStartTime = '00:00:00';
        }
        this.selectedCampaignStartTime = rawStartTime;

        let rawEndTime = format(new Date(campaign.endTime), 'kk:mm:SS');
        if (rawEndTime === '24:00:00'){
            rawEndTime = '00:00:00';
        }
        this.selectedCampaignEndTime = rawEndTime;

        //task 5: set all respective campaign date variables
        this.selectedCampaignDateRange = this.isAnyDate(campaign) ? 'any' : 'custom';
        this.selectedCampaignStartDate = campaign.startDate.toString();
        this.selectedCampaignEndDate = campaign.endDate.toString();

        //task 6: set the weekdays variables and call {@link processPassedWeekdays} to assign selections appropriately
        this.selectedCampaignWeekdays = campaign.weekdays.split('', 7);
        await this.processPassedWeekdays();

        //task 7: set the campaign rate
        this.selectedCampaignRate = campaign.rate;

        //task 8: set the campaign public comment
        this.selectedCampaignPublicComment = campaign.publicComment;

        //task 9: set the location campaign map specific variables
        for (const r in campaign.markers) {
            if (Object.prototype.hasOwnProperty.call(campaign.markers, r)) {
                this.locationCampaignLatitude = campaign.markers[r].latitude;
                this.locationCampaignLongitude = campaign.markers[r].longitude;
                this.locationCampaignRadius = campaign.markers[r].radius;
            }
        }
        this.locationCampaignZoom = campaign.zoom;

        //task 10: set the location campaign duration
        this.locationCampaignDuration = campaign.duration;

        //task 11: set the poster campaign markers array
        if (campaign.campaignTypeId === CampaignType.posterCode) {
            const sortedMarkers = campaign.markers.sort((a, b) => a.markerIndex - b.markerIndex);
            for (const r in sortedMarkers) {
                if (Object.prototype.hasOwnProperty.call(sortedMarkers, r)) {
                    const marker: Marker = {
                        id: sortedMarkers[r].id != null ? sortedMarkers[r].id : null,
                        latitude: sortedMarkers[r].latitude,
                        longitude: sortedMarkers[r].longitude,
                        label: this.labelOptions.find(lo => lo.index === sortedMarkers[r].markerIndex).label,
                        note: sortedMarkers[r].comment,
                        index: sortedMarkers[r].markerIndex
                    };

                    this.posterCampaignMarkers.push(marker);
                }
            }
        } else {
            this.posterCampaignMarkers = [];
        }

        //task 12: set the selected poster campaign marker to the first marker in the array
        if (campaign.campaignTypeId === CampaignType.posterCode) {
            this.posterCampaignSelectedMarker = this.posterCampaignMarkers.find(pcm => pcm.index === 0);
        } else {
            this.posterCampaignSelectedMarker = null;
        }

        //task 13: set the poster campaign map specific variables
        this.posterCampaignLatitude = campaign.latitude != null ? campaign.latitude : null;
        this.posterCampaignLongitude = campaign.longitude != null ? campaign.longitude : null;
        this.posterCampaignZoom = campaign.zoom != null ? campaign.zoom : null;

        //task 14: open the modal and set the selected campaign type and then set the step to 1 so that user can't change the campaign type
        await this.presentAddCampaignModal().then(() => {
            this.setSelectedCampaignType(this.selectedCampaignType);
            this.setSelectedCampaignStep(1);
        });
    }

    /**
     * @returns existingCampaignId {string}
     */
     getExistingCampaignId() {
        return this.existingCampaignId;
    }

    // eslint-disable-next-line
    /**
     * @param  {CampaignObject} campaign
     * @summary this function is used to tell if a campaign is set to any time
     */
    isAnyTime(campaign: CampaignObject) {
        const startTimeHours = new Date(campaign.startTime).getHours();
        const endTimeHours = new Date(campaign.endTime).getHours();

        if (startTimeHours === 0 && endTimeHours === 0) {
            return true;
        } else {
            return false;
        }
    }

    async returnTimeRange(campaign: CampaignObject) {
        const startTimeHours = new Date(campaign.startTime).getHours();
        const endTimeHours = new Date(campaign.endTime).getHours();

        switch(true) {
            case (startTimeHours === 0 && endTimeHours === 0): {
                return 'any';
            }
            case (startTimeHours === 6 && endTimeHours === 12): {
                return 'morning';
            }
            case (startTimeHours === 12 && endTimeHours === 17): {
                return 'afternoon';
            }
            case (startTimeHours === 17 && endTimeHours === 21): {
                return 'evening';
            }
            case (startTimeHours === 21 && endTimeHours === 6): {
                return 'night';
            }
            default: {
                return 'custom';
            }
        }
    }

    // eslint-disable-next-line
    /**
     * @param  {CampaignObject} campaign
     * @summary this function is used to tell if a campaign is set to any date
     */
     isAnyDate(campaign: CampaignObject) {
        if (campaign.startDate.toString() === '1970-01-01T00:00:00' && campaign.endDate.toString() === '1970-01-01T00:00:00') {
            return true;
        } else {
            return false;
        }
    }

    //eslint-disable-next-line
    /**
     * @param  {number} id
     * @summary this function is used by the {@link processEditCampaign} function to assign the {@var selectedCampaignType}
     * and the {@var selectedCampaignTypeId} respectively based on the passed id
     */
    async processPassedCampaignTypeId(id: number) {
        switch (true) {
            case id === 2: {
                this.selectedCampaignType = 'facebook';
                this.selectedCampaignTypeId = CampaignType.facebook;
                break;
            }
            case id === 4: {
                this.selectedCampaignType = 'instagram';
                this.selectedCampaignTypeId = CampaignType.instagram;
                break;
            }
            case id === 7: {
                this.selectedCampaignType = 'location';
                this.selectedCampaignTypeId = CampaignType.location;
                break;
            }
            case id === 8: {
                this.selectedCampaignType = 'posters';
                this.selectedCampaignTypeId = CampaignType.posterCode;
                break;
            }
            default: {
                break;
            }
        }
    }

    /**
     * @summary this function is called by {@link processEditCampaign} to update the appropriate weekdayOptions as selected = true
     */
    async processPassedWeekdays() {
        for (const r in this.selectedCampaignWeekdays) {
            if (this.selectedCampaignWeekdays[r] === '1'){
                this.weekdayOptions[r].selected = true;
            }
        }
    }

    /**
     * @summary reset all variables that may have been used during the campaign creation process
     */
    async resetAllVariables() {
        try {
            this.isEditCampaign = false;
            this.existingCampaignId = null;

            this.newCampaignError = null;

            this.selectedCampaignStep = 0;

            this.selectedCampaignImage = null;

            this.selectedCampaignType = null;
            this.selectedCampaignTypeId = null;

            this.selectedCampaignTimeRange = null;
            this.selectedCampaignStartTime = null;
            this.selectedCampaignEndTime = null;

            this.selectedCampaignDateRange = null;
            this.selectedCampaignStartDate = null;
            this.selectedCampaignEndDate = null;

            this.selectedCampaignWeekdays = ['0', '0', '0', '0', '0', '0', '0'];
            this.weekdayOptions.forEach((option) => {
                option.selected = false;
            });

            this.selectedCampaignRate = null;

            this.selectedCampaignPublicComment = null;

            this.locationCampaignLatitude = null;
            this.locationCampaignLongitude = null;
            this.locationCampaignRadius = null;
            this.locationCampaignZoom = null;

            this.locationCampaignDuration = null;

            this.posterCampaignMarkers = [];

            this.posterCampaignSelectedMarker = null;

            this.posterCampaignLatitude = null;
            this.posterCampaignLongitude = null;
            this.posterCampaignZoom = null;

            return true;
        }
        catch (err) {
            return false;
        }
    }
}
