//Angular imports
import { Injectable } from '@angular/core';
import { Observable, pipe, Subject } from 'rxjs';

//Ionic imports

//Internal models
import { VenueEmployeePermissionsObject } from './models/account.model';
import { StrydEventObject } from './models/event.model';

@Injectable()
export class GlobalAppProvider {
    //selected event variables
    selectedEventObservable = new Subject<StrydEventObject>();
    selectedEvent: StrydEventObject;

    selectedEventEnabledObservable = new Subject<boolean>();
    selectedEventEnabled: boolean;

    //employee permissions variables
    fetchedEmployeePermission = new Subject();
    employeePermissions: VenueEmployeePermissionsObject;

    //event list variables
    showEventListObservable = new Subject();
    showEventListDropdown: boolean;

    //mobile event list variables
    showMobileEventListObservable = new Subject();
    showMobileEventListDropdown: boolean;

    //notification popOut variables
    showNotificationsObservable = new Subject();
    showNotificationsPopOut: boolean;

    //mobile sidebar variables
    showMobileSidebarObservable = new Subject();
    showMobileSidebar: boolean;

    //active page variables
    activePageObservable = new Subject();
    activePage: string;

    //active page variables
    qrCodeScannerOpenObservable = new Subject();
    qrCodeScannerOpen: boolean;

    //employee profile picture variables
    employeeProfilePictureObservable = new Subject();
    employeeProfilePicture: string;

    //app state variables
    appIsInForegroundObservable = new Subject();

    //mobile nav clicked variables
    mobileNavClickedObservable = new Subject();
    topNavClickedObservable = new Subject();


    constructor() {

    }

    async setSelectedEvent(event: StrydEventObject) {
        this.selectedEvent = event;
        this.selectedEventObservable.next(event);
    }
    async getSelectedEvent() {
        return this.selectedEvent;
    }

    async setSelectedEventEnabled(enabled: boolean) {
        this.selectedEventEnabled = enabled;
        this.selectedEventEnabledObservable.next(enabled);
    }
    async getSelectedEventEnabled() {
        return this.selectedEventEnabled;
    }

    // eslint-disable-next-line
    /**
     * @param  {VenueEmployeePermissionsObject} permissions
     * This sets the employee permissions variable and triggers the employee permission Subject so that all observables fire off
     */
    setEmployeePermissions(permissions: VenueEmployeePermissionsObject) {
        this.fetchedEmployeePermission.next(permissions);
        this.employeePermissions = permissions;
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showDropdown
     * This sets the event list dropdown variable and triggers the event list dropdown Subject so that all observables fire off
     */
    setEventListDropdown(showDropdown: boolean) {
        this.showEventListObservable.next(showDropdown);
        this.showEventListDropdown = showDropdown;
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showDropdown
     * This sets the event list dropdown variable and triggers the event list dropdown Subject so that all observables fire off
     */
     setMobileEventListDropdown(showDropdown: boolean) {
        this.showMobileEventListObservable.next(showDropdown);
        this.showMobileEventListDropdown = showDropdown;
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showPopOut
     * This sets the notifications popOut variable and triggers the notifications popOut Subject so that all observables fire off
     */
    setNotificationsPopOut(showPopOut: boolean) {
        this.showNotificationsObservable.next(showPopOut);
        this.showNotificationsPopOut = showPopOut;
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showSidebar
     * This sets the mobile sidebar variable and triggers the mobile sidebar Subject so that all observables fire off
     */
    setMobileSidebar(showSidebar: boolean) {
        this.showMobileSidebarObservable.next(showSidebar);
        this.showMobileSidebar = showSidebar;
    }

    // eslint-disable-next-line
    /**
     * @param  {string} activePage
     * This sets the mobile sidebar variable and triggers the mobile sidebar Subject so that all observables fire off
     */
    async setActivePage(activePage: string) {
        this.activePageObservable.next(activePage);
        this.activePage = activePage;
    }

    async getActivePage() {
        return this.activePage;
    }

    // eslint-disable-next-line
    /**
     * @param  {string} activePage
     * This sets the mobile sidebar variable and triggers the mobile sidebar Subject so that all observables fire off
     */
     async setQRCodeScannerOpen(isOpen: boolean) {
        this.qrCodeScannerOpenObservable.next(isOpen);
        this.qrCodeScannerOpen = isOpen;
    }

    async getQRCodeScannerOpen() {
        return this.qrCodeScannerOpen;
    }


    // eslint-disable-next-line
    /**
     * @param  {string} profilePicture
     * This sets the employee profile picture variable and calls the respective Subject so that all observables fire off
     */
    async setEmployeeProfilePicture(profilePicture: string) {
        this.employeeProfilePictureObservable.next(profilePicture);
        this.employeeProfilePicture = profilePicture;
    }
    async getEmployeeProfilePicture() {
        return this.employeeProfilePicture;
    }


    // eslint-disable-next-line
    /**
     * @param  {boolean} isActive
     * This sets the appIsInForeground Subject so that all observables fire off
     */
    async setAppForegroundStatus(isActive: boolean) {
        this.appIsInForegroundObservable.next(isActive);
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} isActive
     * This sets the mobile nav clicked Subject so that all observables fire off
     */
    async setMobileNavClicked(clicked: boolean) {
        this.mobileNavClickedObservable.next(clicked);
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} isActive
     * This sets the top nav clicked Subject so that all observables fire off
     */
    async setTopNavClicked(clicked: boolean) {
        this.topNavClickedObservable.next(clicked);
    }
}
