//Angular imports
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

//Internal models
import { SearchedGuestsObject, SelectedGuestDetailsObject } from '../models/guest-list.model';


@Injectable()
export class GuestCheckInProvider {
  //search guests dropdown variables
  showSearchedGuestsDropdownObservable = new Subject();
  showSearchedGuestsDropdown: boolean;

  //selected guest variables
  selectedSearchedGuestObservable = new Subject();
  selectedSearchedGuest: SearchedGuestsObject;

  //selected guest variables
  selectedGuestDetailsObservable = new Subject();
  selectedGuestDetails: SelectedGuestDetailsObject;

  constructor(

  ) { }

  // eslint-disable-next-line
  /**
   * @param  {boolean} showDropdown
   * This sets the searched guests dropdown variable for the check in page
   * and triggers the guests list dropdown Subject so that all observables fire off
   */
  async setShowSearchedGuestDropdown(showDropdown: boolean) {
    this.showSearchedGuestsDropdownObservable.next(showDropdown);
    this.showSearchedGuestsDropdown = showDropdown;
  }

  // eslint-disable-next-line
  /**
   * @param  {boolean} showDropdown
   * This sets the selected searched guest variable for the check in page
   * and triggers the selected searched guest Subject so that all observables fire off
   */
   async setSelectedSearchedGuest(guest: SearchedGuestsObject) {
    this.selectedSearchedGuestObservable.next(guest);
    this.selectedSearchedGuest = guest;
  }

  async getSelectedSearchedGuest() {
    return this.selectedSearchedGuest;
  }

  // eslint-disable-next-line
  /**
   * @param  {boolean} showDropdown
   * This sets the selected searched guest variable for the check in page
   * and triggers the selected searched guest Subject so that all observables fire off
   */
   async setSelectedGuestDetails(guest: SelectedGuestDetailsObject) {
    this.selectedGuestDetailsObservable.next(guest);
    this.selectedGuestDetails = guest;
  }

  async getSelectedGuestDetails() {
    return this.selectedGuestDetails;
  }

}
