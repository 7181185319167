//Angular imports
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription, debounceTime, filter, tap } from 'rxjs';

//Ionic imports
import { ToastController, AlertController, LoadingController } from '@ionic/angular';

//Internal providers
import { GlobalAppProvider } from 'src/app/app.provider';
import { AddGuestListGuestProvider } from 'src/app/providers/add-guest-list-guest.provider';
import { GuestListProvider } from 'src/app/providers/guest-list.provider';

//Internal models
import { StrydEventObject } from 'src/app/models/event.model';
import { GuestListObject, PromoterAllowanceObject, VenueGuestListAccessLevelObject } from 'src/app/models/guest-list.model';

//Internal services
import { GuestListService } from 'src/app/services/guest-list/guest-list.service';
import { AccountService } from 'src/app/services/account/account-service.service';

interface BulkGuest {
  firstName: string;
  lastName: string;
  fullName: string;
  additionalGuests: number;
  notes: string | null;
  gender: string | null;
  phoneNumber: string | null;
  email: string | null;
  selectedAccessLevelName?: string | null;
  selectedAccessLevelIds?: string[] | null;
}

@Component({
  selector: 'app-bulk-guest',
  templateUrl: './bulk-guest.component.html',
  styleUrls: ['./bulk-guest.component.scss'],
})
export class BulkGuestComponent implements OnInit, AfterViewInit, OnDestroy {
  //internal display variables
  corporationUserId: string;
  showListDropdown: boolean;
  loading: any;
  showAccessLevels: boolean;
  showAccessLevelDropDown: boolean;
  showPromoterDropDown: boolean;
  bulkNameTextAreaRows = 8;

  //selected event variables
  selectedEvent: StrydEventObject = null;

  //guest list variables
  eventGuestLists: GuestListObject[];
  promoters: PromoterAllowanceObject[];
  originalPromoters: PromoterAllowanceObject[];

  //add guest variables
  selectedListId: string[] = [];
  selectedListIsPromoterList: boolean;
  selectedListNames: string[] = [];
  joinedSelectedListNames = '';
  formGuests: BulkGuest[] = [];
  bulkGuestForm: UntypedFormGroup;

  //access level variables
  existingAccessLevels: VenueGuestListAccessLevelObject[];

  private bulkNamesSubscription: Subscription;
  private promoterSearchSubscription: Subscription;

  constructor(
    private accountService: AccountService,
    private addGuestListGuestProvider: AddGuestListGuestProvider,
    private alertController: AlertController,
    private appProvider: GlobalAppProvider,
    private formBuilder: UntypedFormBuilder,
    private guestListProvider: GuestListProvider,
    private guestListService: GuestListService,
    private loadingController: LoadingController,
    private toastController: ToastController
  ) { }

  get names() { return this.bulkGuestForm.get('names'); }
  get selectedAccessLevels() { return this.bulkGuestForm.get('selectedAccessLevels'); }
  get selectedPromoter() { return this.bulkGuestForm.get('selectedPromoter'); }

  async ngOnInit() {
    //@task1 initialize the bulk guest form
    this.bulkGuestForm = this.formBuilder.group({
      names: [null, Validators.required],
      selectedAccessLevels: [null],
      selectedPromoter: [null]
    });

    //@task2 get the currently selected event
    this.selectedEvent = await this.appProvider.getSelectedEvent();
    this.corporationUserId = await this.accountService.getCorporationUserId();
    this.getExistingAccessLevels();

    //@task3 get all guest lists for the selected event
    await this.getVenueGuestLists();
    await this.getPromoters();
  }

  async ngAfterViewInit() {
    this.bulkNamesSubscription = this.names.valueChanges
      .pipe(
        debounceTime(500),
        filter((text) => text.length > 0),
        tap(async (searchText) => {
          const existingFormGuests = this.formGuests;
          const newFormGuests = await this.parseGuestData(searchText);

          newFormGuests.forEach((newGuest) => {
            const existingGuest = existingFormGuests.find((guest) => guest.fullName === newGuest.fullName);

            if (existingGuest) {
              newGuest.selectedAccessLevelIds = existingGuest.selectedAccessLevelIds;
              newGuest.selectedAccessLevelName = existingGuest.selectedAccessLevelName;
            }
          });

          this.formGuests = newFormGuests;
        })
      )
      .subscribe();

    this.promoterSearchSubscription = this.selectedPromoter.valueChanges
      .pipe(
        debounceTime(500),
        tap(async (searchText) => {
          if (searchText.length > 0) {
            this.promoters = this.originalPromoters.filter(promoter =>
              promoter.promoterName.toLowerCase().startsWith(searchText.toLowerCase())
            );
          } else {
            this.promoters = [...this.originalPromoters];
          }
        })
      )
      .subscribe();
  }

  async ngOnDestroy() {
    if (this.bulkNamesSubscription) {
      this.bulkNamesSubscription.unsubscribe();
    }
  }

  async getVenueGuestLists() {
    this.guestListService.getEventVenueOnlyGuestLists(this.selectedEvent.eventDetailId)
      .then((lists) => {
        this.eventGuestLists = [];

        for (const r in lists) {
          if (Object.prototype.hasOwnProperty.call(lists, r)) {
            this.eventGuestLists.push(new GuestListObject(lists[r]));
          }
        }
      }).catch(async (error) => {
        await this.presentToast(
          'An error occurred while fetching available guest lists',
          2000,
          'top',
          'error-toast',
          'bug'
        );
      });
  }

  async getSelectedGuestListPromoterAllowances(list: GuestListObject) {
    this.guestListService.getSelectedGuestListPromoterAllowances(list.id)
      .then((allowances) => {
        list.allowances = [];

        for (const r in allowances) {
          if (Object.prototype.hasOwnProperty.call(allowances, r)) {
            list.allowances.push(new PromoterAllowanceObject(allowances[r]));
          }
        }
      }).catch(async (error) => {
        await this.presentToast(
          'An error occurred while fetching list promoter allowances',
          2000,
          'top',
          'error-toast',
          'bug'
        );
      });
  }

  async getPromoters() {
    await this.guestListService.getPreferredPromoters(await this.accountService.getCorporationUserId())
      .then((promoters) => {
        this.originalPromoters = [];
        this.promoters = [];

        for (const r in promoters) {
          if (Object.prototype.hasOwnProperty.call(promoters, r)) {
            this.originalPromoters.push(new PromoterAllowanceObject(promoters[r]));
            this.promoters.push(new PromoterAllowanceObject(promoters[r]));
          }
        }

      })
      .catch(async (error) => {
        await this.presentToast('There was an error fetching available promoters', 2000, 'top', 'error-toast', 'bug');
      });
  }

  async getExistingAccessLevels() {
    await this.guestListService.getVenueGuestListAccessLevel(this.corporationUserId)
      .then((res: any) => {
        this.existingAccessLevels = [];

        for (const r in res) {
          if (Object.prototype.hasOwnProperty.call(res, r)) {
            this.existingAccessLevels.push(new VenueGuestListAccessLevelObject(res[r]));
          }
        }
      })
      .catch((error) => {

      });
  }

  onListClicked(list: GuestListObject) {
    const hasSelectedPromoterList = this.eventGuestLists.some((l) => l.selected && l.isPromoterList);
    const hasSelectedNonPromoterList = this.eventGuestLists.some((l) => l.selected && !l.isPromoterList);

    if ((hasSelectedPromoterList && !list.isPromoterList) || (hasSelectedNonPromoterList && list.isPromoterList)) {
      this.presentToast('You cannot select a promoter list and a non-promoter list together', 5000, 'bottom', 'warning-toast', 'warning');
      return;
    }

    //@task1 mark the passed list as the inverse of whatever it's current selected state is
    list.selected = !list.selected;

    //@task2 check to see if this list is in the selected list id array
    const previouslySelectedIndex = this.selectedListId.indexOf(list.id);

    //@task3 if the index is not -1 then the list is in the selected list id array and needs to be removed
    if (previouslySelectedIndex !== -1) {
      this.selectedListId.splice(previouslySelectedIndex, 1);
      this.selectedListNames.splice(previouslySelectedIndex, 1);
    } else {
      //@task4 if the index is -1 then the list is not in the selected list id array and needs to be added
      this.selectedListId.push(list.id);
      this.selectedListNames.push(list.name);
    }

    //@task5 add the selected list name to the selected list names array to be displayed in the input field
    //and check if the list is a promoter list
    this.joinedSelectedListNames = this.selectedListNames.join(', ');
    this.selectedListIsPromoterList = list.selected && list.isPromoterList;

    if (this.selectedListIsPromoterList) {
      this.getSelectedGuestListPromoterAllowances(list);
    }

    this.showListDropdown = false;
  }

  async addAccessLevels() {
    if (this.names.value === null || this.names.value === '' || this.names.value === undefined) {
      await this.presentToast('Please enter at least 1 guest name before adding access levels', 5000, 'bottom', 'warning-toast', 'warning');
      return;
    }

    //@task1 separate each line item from the text area into it's own array item
    const text = this.names.value;
    const nameArray: string[] = text.match(/[^\r\n]+/g);

    this.formGuests = [];
    this.formGuests = await this.parseGuestData(this.names.value);

    this.showAccessLevels = true;
    this.bulkNameTextAreaRows = 4;
  }

  async selectAccessLevel(accessLevel: VenueGuestListAccessLevelObject) {
    this.showAccessLevelDropDown = false;
    accessLevel.selected = !accessLevel.selected;

    // eslint-disable-next-line max-len
    //recalculate the selected access levels form control value so that it only contains the name of each selected access levels separated by a comma
    const selectedAccessLevels = this.existingAccessLevels.filter((level) => level.selected === true);
    const selectedAccessLevelNames = selectedAccessLevels.map((level) => level.name);
    this.selectedAccessLevels.setValue(selectedAccessLevelNames.join(', '));


    //if at least one access level is selected then show a toast message
    if (this.existingAccessLevels.some((level) => level.selected === true)) {
      // eslint-disable-next-line max-len
      await this.presentToast('You may now click on a guest to assign them to the selected access levels', 5000, 'bottom', 'information-toast', 'information-circle');
    }
  }

  assignAccessControl(guest) {
    this.showAccessLevelDropDown = false;

    if (this.existingAccessLevels.some((level) => level.selected === true)) {
      guest.selectedAccessLevelIds = this.existingAccessLevels.filter((level) => level.selected === true).map((level) => level.id);

      // eslint-disable-next-line max-len
      guest.selectedAccessLevelName = this.existingAccessLevels.filter((level) => level.selected === true).map((level) => level.name).join(', ');
    } else {
      guest.selectedAccessLevelIds = null;
      guest.selectedAccessLevelName = null;
    }
  }

  assignAccessLevelsToAll() {
    if (this.existingAccessLevels.some((level) => level.selected === true)) {
      this.formGuests.forEach((guest) => {
        guest.selectedAccessLevelIds = this.existingAccessLevels.filter((level) => level.selected === true).map((level) => level.id);
        // eslint-disable-next-line max-len
        guest.selectedAccessLevelName = this.existingAccessLevels.filter((level) => level.selected === true).map((level) => level.name).join(', ');
      });
    } else {
      this.formGuests.forEach((guest) => {
        guest.selectedAccessLevelIds = null;
        guest.selectedAccessLevelName = null;
      });
    }
  }

  selectPromoter(promoter: PromoterAllowanceObject) {
    this.showPromoterDropDown = false;

    const existingSelectedPromoter = this.promoters.find((p) => p.selected === true && p.promoterUserId !== promoter.promoterUserId);

    if (existingSelectedPromoter) {
      existingSelectedPromoter.selected = false;
    }

    promoter.selected = !promoter.selected;

    if (promoter.selected) {
      this.selectedPromoter.setValue(promoter.promoterName);
    } else {
      this.selectedPromoter.setValue(null);
    }
  }

  async onBulkGuestSubmit() {
    if (this.selectedListId.length === 0) {
      await this.presentToast('Please select at least one guest list', null, 'bottom', 'warning-toast', 'warning');
      return;
    }

    if (this.selectedListIsPromoterList) {
      const selectedPromoter = this.promoters.find((promoter) => promoter.selected === true);

      if (!selectedPromoter) {
        await this.presentToast('Please select a promoter in order to proceed', 5000, 'bottom', 'warning-toast', 'warning');
        return;
      }
    }

    await this.showLoading();

    //@task1 separate each line item from the text area into it's own array item
    const text = this.names.value;
    const nameArray: string[] = text.match(/[^\r\n]+/g);

    const totalGuests = await this.getTotalGuests(this.formGuests);

    for (const r in this.selectedListId) {
      if (Object.prototype.hasOwnProperty.call(this.selectedListId, r)) {
        const eventGuestList = this.eventGuestLists.find((list) => list.id === this.selectedListId[r]);
        //if the list quantity remaining is less than the total guests then show an ionic alert asking if they want to continue
        if ((eventGuestList.venueQuantityRemaining < totalGuests) && !this.selectedListIsPromoterList) {
          this.loading.dismiss();
          await this.presentIncreaseListQuantitiesAlert(this.formGuests);
          return;
        }
      }
    }

    const selectedPromoterUserId = this.promoters.find((promoter) => promoter.selected === true)?.promoterUserId;
    // eslint-disable-next-line max-len
    const venueOverrideAllowance = selectedPromoterUserId !== undefined && selectedPromoterUserId !== null && selectedPromoterUserId !== '' ? true : false;

    const requestBody = {
      corporationUserId: await this.accountService.getCorporationUserId(),
      guestListIds: this.selectedListId,
      guests: this.formGuests,
      increaseListQuantities: false,
      addedByUserId: selectedPromoterUserId,
      venueOverrideAllowance
    };

    this.guestListService.bulkAddGuests(requestBody)
      .then(async (res) => {
        this.loading.dismiss();
        // eslint-disable-next-line max-len
        await this.presentToast('All guests have been uploaded successfully', 3000, 'top', 'success-toast','checkmark-circle');

        this.resetBulkGuestForm();
        await this.guestListProvider.setShowAddGuestListGuest(false);
      })
      .catch((error) => {
        this.loading.dismiss();
        this.presentToast('An error occurred while uploading new guests', 2000, 'top', 'error-toast', 'bug');
      });
  }

  async parseGuestData(input: string): Promise<BulkGuest[]> {
    const guests: BulkGuest[] = [];

    // Split input by line breaks
    const lines = input.split('\n');

    lines.forEach((line) => {
      //if the line is empty then skip it
      if (line.trim() === '') {
        return;
      }

      const guest: BulkGuest = {
        firstName: '',
        lastName: '',
        fullName: '',
        additionalGuests: 0,
        notes: null,
        gender: null,
        phoneNumber: null,
        email: null,
      };

      // Find name
      const nameMatch = line.match(/^[\w\s\-\']+(?=[\s\+\#\*]|$)/);
      if (nameMatch) {
        const nameParts = nameMatch[0].trim().split(/\s+/);
        guest.firstName = nameParts[0];
        if (nameParts.length > 1) {
          guest.lastName = nameParts.slice(1).join(' ');
        }
        guest.fullName = `${guest.firstName} ${guest.lastName}`;
      }

      // Find additional guests
      const additionalGuestsMatch = line.match(/\+\s*(\d+)/); // Updated line
      if (additionalGuestsMatch) {
        guest.additionalGuests = parseInt(additionalGuestsMatch[1], 10);
      }

      // Find note
      const noteMatch = line.match(/[\?][\s]*(.+?)(?=[\#\@\*]|$)/); // Updated line
      if (noteMatch) {
        guest.notes = noteMatch[1].trim();
      }

      // Find gender
      const genderMatch = line.match(/\*[\s]*(\w+)/i);
      if (genderMatch) {
        const gender = genderMatch[1].trim().toLowerCase();

        switch (gender) {
          case 'male':
            guest.gender = 'Male';
            break;
          case 'female':
            guest.gender = 'Female';
            break;
          default:
            guest.gender = 'Other';
        }
      }

      // Find phone number
      const phoneNumberMatch = line.match(/#[\s]*(.+?)(?=[\$\@\*]|$)/);
      if (phoneNumberMatch) {
        guest.phoneNumber = phoneNumberMatch[1].trim();
      }

      // Find email
      const emailMatch = line.match(/@[\s]*(.+?)(?=[\#\$\*]|$)/);
      if (emailMatch) {
        guest.email = emailMatch[1].trim();
      }

      guests.push(guest);
    });

    return guests;
  }

  async getTotalGuests(guests: BulkGuest[]): Promise<number> {
    let totalGuests = 0;

    guests.forEach((guest) => {
      totalGuests += 1 + guest.additionalGuests;
    });

    return totalGuests;
  }

  async presentIncreaseListQuantitiesAlert(guests) {
    const alert = await this.alertController.create({
      header: 'Increase List Quantities',
      // eslint-disable-next-line max-len
      message: `You are attempting to add more guests than the quantity allowed for one or more of the selected guest lists. Would you like to increase the quantity allowed for the selected guest lists?`,
      mode: 'ios',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Increase Quantities',
          handler: async (data) => {
            await this.showLoading();
            const requestBody = {
              corporationUserId: await this.accountService.getCorporationUserId(),
              guestListIds: this.selectedListId,
              guests,
              increaseListQuantities: true
            };

            this.guestListService.bulkAddGuests(requestBody)
              .then(async (res) => {
                this.loading.dismiss();
                // eslint-disable-next-line max-len
                await this.presentToast('All guests have been uploaded successfully', 3000, 'top', 'success-toast','checkmark-circle');

                this.resetBulkGuestForm();
                await this.guestListProvider.setShowAddGuestListGuest(false);
              })
              .catch((error) => {
                this.loading.dismiss();
                this.presentToast('An error occurred while uploading new guests', 2000, 'top', 'error-toast', 'bug');
              });
          }
        }
      ]
    });

    await alert.present();
  }

  async closeAddGuestListGuest() {
    await this.guestListProvider.setShowAddGuestListGuest(false);
  }

  resetBulkGuestForm() {
    this.bulkGuestForm.reset({
      names: null,
      selectedAccessLevels: null
    });

    this.eventGuestLists.forEach((list) => {
      list.selected = false;
    });

    this.selectedListId = [];
    this.selectedListNames = [];
    this.joinedSelectedListNames = '';
    this.formGuests = [];
    this.showAccessLevels = false;
    this.showAccessLevelDropDown = false;
    this.existingAccessLevels.forEach((level) => {
      level.selected = false;
    });
  }

  async showLoading() {
    this.loading = await this.loadingController.create({
      message: 'Processing uploading...',
      cssClass: 'custom-loading',
    });

    this.loading.present();
  }

  async presentToast(
    message: string,
    duration: number,
    position: 'top' | 'middle' | 'bottom',
    cssClass: 'error-toast' | 'warning-toast' | 'success-toast' | 'information-toast' | '',
    icon: 'bug' | 'warning' | 'checkmark-circle' | 'information-circle' | ''
  ){
    const toast = await this.toastController.create({
      message,
      duration,
      position,
      cssClass,
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel'
        }
      ],
      icon
    });

    await toast.present();

    return null;
  }
}
