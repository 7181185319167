import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(rawNum): string {
    if (!rawNum) {
      return '';
    }

    // Remove all non-numeric characters
    let cleaned = ('' + rawNum).replace(/\D/g, '');

    // Assuming the default country code (+1 for US) if not present
    if (cleaned.length === 10) {
      cleaned = '1' + cleaned; // Prepend the country code if it's missing (10 digits)
    }

    if (cleaned.length !== 11 || cleaned[0] !== '1') {
      return rawNum; // Return the original input if it does not comply with expected format
    }

    // Extract parts of the phone number
    const countryCode = cleaned.slice(0,1);
    const areaCode = cleaned.slice(1,4);
    const midSection = cleaned.slice(4,7);
    const lastSection = cleaned.slice(7);

    // Format and return the phone number
    return `+${countryCode} (${areaCode}) ${midSection}-${lastSection}`;
  }

}
