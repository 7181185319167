import { TicketTierStatus } from '../enums/events/ticket-tier-status';
import { TicketTierPreferredPromotersObject } from './account.model';

export class StrydEventObject {
    activityIsDisabled: boolean;
    createDate: string;
    displayRemaining: boolean;
    endDateTime: Date;
    endDateTimeString: string;
    endDateTimeUtc: Date;
    eventDetailId: string;
    eventStatus: number;
    fullDescription: string;
    id: string;
    imageFileId: string;
    isCanceled: boolean;
    isDraft: boolean;
    isGuestListOnly: boolean;
    isNationwideEvent: boolean;
    isOnlineEvent: boolean;
    isPreferredPromoterOnly: boolean;
    isPublished: boolean;
    isTaxed: boolean;
    isToBeAnnounced: boolean;
    lastChanged: Date;
    latitude: number;
    longitude: number;
    name: string;
    onlineEventUrl: string;
    organizer: string;
    refundAllowance: string;
    shortDescription: string;
    startDateTime: Date;
    startDateTimeString: string;
    startDateTimeUtc: Date;
    taxRate: number;
    timeZone: string;
    timeZoneOffset: number;
    type: string;
    venueAddress: string;
    venueName: string;
    venueUserId: string;

    //not in Stryd.Domain
    selected: boolean;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class NewStrydEventObject {
    activityIsDisabled: boolean;
    displayRemaining: boolean;
    endDateTime: string;
    fullDescription: string;
    isCanceled: boolean;
    isDraft: boolean;
    isGuestListOnly: boolean;
    isNationwideEvent: boolean;
    isOnlineEvent: boolean;
    isPreferredPromoterOnly: boolean;
    isPublished: boolean;
    isTaxed: boolean;
    isToBeAnnounced: boolean;
    latitude: number;
    longitude: number;
    name: string;
    organizer: string;
    refundAllowance: string;
    shortDescription: string;
    startDateTime: string;
    timeZoneOffset: number;
    type: string;
    venueAddress: string;
    venueEventTerms: string;
    venueName: string;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class NewEventTicketTier {
    id: string;
    index: number;
    name: string;
    quantity: number;
    quantityRemaining: number;
    quantitySold: number;
    price: number;
    buyerTotal: number;
    absorbFees: boolean;
    salesStartDateTime: string;
    salesStartDateTimeString: string;
    salesEndDateTime: string;
    salesEndDateTimeString: string;
    minimumQuantity: number;
    maximumQuantity: number;
    discountIsPercentage: boolean;
    amountOff: number;
    percentOff: number;
    isLineSkip: boolean;
    isTableTicket: boolean;
    description: string;
    status: TicketTierStatus = TicketTierStatus.visible;
    privacyCode: string;
    createDate: Date;
    lastChanged: Date;

    //not part of Stryd.Domain
    promoters: TicketTierPreferredPromotersObject[] = [];
    enableDiscount: boolean;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class EventPreferredPromoterObject {
    promoterId: string;
    promoterName: string;
    promoterEmail: string;
    promoterProfilePicId: string;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class EventPromoterDiscountTierObject {
    ticketTierId: string;
    ticketTierName: string;
    ticketTierPrice: number;
    promoters: EventPromoterDiscountObject[];

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class EventPromoterDiscountObject {
    promoterUserId: string;
    promoterName: string;
    amountOff: number;
    percentOff: number;
    totalAllowed: number;
    startDateTime: string;
    startDateTimeString: string;
    endDateTime: string;
    endDateTimeString: string;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class EventPromoterPaymentTierObject {
    ticketTierId: string;
    ticketTierName: string;
    ticketTierPrice: number;
    promoters: EventPromoterPaymentObject[];

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class EventPromoterPaymentObject {
    promoterUserId: string;
    promoterName: string;
    paymentAmount: number;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class StrydEventTicketTierObject {
    id: string;
    eventId: string;
    ticketType: string;
    name: string;
    quantity: number;
    quantityRemaining: number;
    price: number;
    absorbFees: boolean;
    salesStartDateTime: Date;
    salesStartDateTimeUtc: Date;
    salesEndDateTime: Date;
    salesEndDateTimeUtc: Date;
    timeZoneOffset: number;
    minimumQuantity: number;
    maximumQuantity: number;
    discountIsPercentage: boolean;
    amountOff: number;
    percentOff: number;
    isLineSkip: boolean;
    isTableTicket: boolean;
    description: string;
    createDate: Date;
    lastChanged: Date;

    //not in Stryd.Domain
    backgroundIconColor: string;
    promoterPaymentAmount: number;

    constructor(values: object = {}, colorIndex: number) {
        Object.assign(this, values);

        if (colorIndex !== null) {
            const colorObject = [
                'bg-[#6149cd]',
                'bg-[#49cda3]',
                'bg-[#cd6149]',
                'bg-[#ffc409]',
                'bg-[#2E2E3F]',
            ];
            this.backgroundIconColor = colorObject[colorIndex];
        }
    }
}


export class PromoterEventTicketTierObject {
    eventTicketTiers: StrydEventTicketTierObject[];
    hasMoreTiers: boolean;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}
