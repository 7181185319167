//Angular variables
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

//Capacitor variables
import { CapacitorHttp, HttpResponse, HttpOptions} from '@capacitor/core';

//Internal services
import { AccountService } from '../account/account-service.service';

//Internal providers
import { GlobalAppProvider } from 'src/app/app.provider';

//Internal variables
import { environment } from '../../../environments/environment';

//Internal interfaces
export interface SearchEventBody {
  searchString: string;
  page: number;
  pageSize: number;
}
@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    private accountService: AccountService,
    private appProvider: GlobalAppProvider
  ) { }

  async getNewAccessToken() {
    const userId = localStorage.getItem('phynxUserId');
    if (userId !== null && userId !== undefined && userId !== '' && userId !== 'null') {
      await this.accountService.refreshLoginMobile(userId).then(async (user) => {
        await this.accountService.setUserTokens(user.tokens).then(async () => true).catch((error) => {
          //TODO throw error here
          throw new Error('Unable to set user authentication tokens');
        });
      }).catch((error) => {
        //TODO throw error here
        throw new Error('Unable to refresh login session');
      });
    }
  }

  // eslint-disable-next-line
  /**
   * @param  {SearchEventBody} body
   * @summary Call the GetSearchedEventDetails API to return searched events by name or date
   * @returns array of StrydEventDetail objects
   * @method GET
   * @url baseUrl/api/StrydEvents/GetSearchedEventDetails/searchString/venueUserId/page/pageSize
   * @task2 make sure that the search string is not null so that we don't get an error response
   * @task3 formulate the API options
   * @task4 make the API call and return the result data
   */
  async getSearchedEvents(searchString: string, passedPage: number, pageSize: number, corporationUserId: string, retry = true){
    //task 1
    if (searchString === null || searchString === ''){
      this.appProvider.setEventListDropdown(false);
      return [];
    } else {

      //task 2
      const options = {
        method: 'GET',
        // eslint-disable-next-line max-len
        url: `${environment.baseUrl}/api/StrydEvents/GetSearchedEventDetails/${searchString}/${corporationUserId}/${passedPage}/${pageSize}`,
        headers: {authorization:this.accountService.authorizationHeader()}
      };

      const response: HttpResponse = await CapacitorHttp.get(options);

      if (response.status === 200) {
        this.appProvider.setEventListDropdown(true);
        return response.data;
      } else if (response.status === 204) {
        return null;
      } else if (response.status === 401 && retry) { // Unauthorized
        try {
          await this.getNewAccessToken();

          // Retry the request with the new access token
          return this.getSearchedEvents(searchString, passedPage, pageSize, corporationUserId, false);
        } catch (error) {
          throw new Error('Unable to refresh access token: ' + error.message);
        }
      } else {
        throw new Error(response.data.message);
      }
    }
  }

  async getMobileSearchedEvents(searchString: string, passedPage: number, pageSize: number, corporationUserId: string, retry = true){
    //task 1
    if (searchString === null || searchString === ''){
      this.appProvider.setMobileEventListDropdown(false);
      return [];
    } else {

      //task 2
      const options = {
        method: 'GET',
        // eslint-disable-next-line max-len
        url: `${environment.baseUrl}/api/StrydEvents/GetSearchedEventDetails/${searchString}/${corporationUserId}/${passedPage}/${pageSize}`,
        headers: {authorization:this.accountService.authorizationHeader()}
      };

      const response: HttpResponse = await CapacitorHttp.get(options);

      if (response.status === 200) {
        this.appProvider.setMobileEventListDropdown(true);
        return response.data;
      } else if (response.status === 204) {
        return null;
      } else if (response.status === 401 && retry) { // Unauthorized
        try {
          await this.getNewAccessToken();

          // Retry the request with the new access token
          return this.getMobileSearchedEvents(searchString, passedPage, pageSize, corporationUserId, false);
        } catch (error) {
          throw new Error('Unable to refresh access token: ' + error.message);
        }
      } else {
        throw new Error(response.data.message);
      }
    }
  }



  async addGuestListOnlyEvent(requestBody: object, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/StrydEvents/AddNewGuestListOnlyStrydEvent`,
      headers:{
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addGuestListOnlyEvent(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async submitNewStrydEvent(requestBody: object, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/StrydEvents/CreateNewStrydEvent`,
      headers:{
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.submitNewStrydEvent(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getTierBuyerTotal(requestBody: object, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/StrydEvents/GetTicketTierBuyerTotal`,
      headers:{
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTierBuyerTotal(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async enableEvent(eventId: string, requestBody: object, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Events/Enable/${eventId}`,
      headers:{
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.enableEvent(eventId, requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getPromoterUpcomingEvents(searchText: string = null, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/StrydEvents/GetPromoterUpcomingEvents?searchText=${searchText}`,
      headers:{
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPromoterUpcomingEvents(searchText, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getPromoterEventTicketTiers(strydEventDetailId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/StrydEvents/GetPromoterEventTicketTiers?eid=${strydEventDetailId}&isTableTicket=false`,
      headers:{
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPromoterEventTicketTiers(strydEventDetailId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getAllPromoterEventTicketTiers(strydEventDetailId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/StrydEvents/GetAllPromoterEventTicketTiers?eid=${strydEventDetailId}&isTableTicket=false`,
      headers:{
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getAllPromoterEventTicketTiers(strydEventDetailId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getPromoterEventTableTiers(strydEventDetailId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/StrydEvents/GetPromoterEventTicketTiers?eid=${strydEventDetailId}&isTableTicket=true`,
      headers:{
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPromoterEventTableTiers(strydEventDetailId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async initializeTicketPaymentIntent(requestBody: object, sendCompletePurchaseLink: boolean, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/StrydEvents/InitializeTicketPaymentIntent?sendCompletePurchaseLink=${sendCompletePurchaseLink}`,
      headers:{
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.initializeTicketPaymentIntent(requestBody, sendCompletePurchaseLink, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventLinkCampaign(strydEventId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/StrydEvents/GetEventLinkCampaign?eventId=${strydEventId}`,
      headers:{
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventLinkCampaign(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventQRCodeCampaign(strydEventId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/StrydEvents/GetEventQRCodeCampaign?eventId=${strydEventId}`,
      headers:{
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventQRCodeCampaign(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateEventBasicInfo(requestBody: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/StrydEvents/UpdateEventBasicInfo`,
      headers:{
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateEventBasicInfo(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async cancelEvent(requestBody: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/StrydEvents/CancelStrydEvent`,
      headers:{
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.cancelEvent(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async duplicateEvent(requestBody: object, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/StrydEvents/DuplicateStrydEvent`,
      headers:{
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.duplicateEvent(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async deleteEvent(strydEventId: string, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/StrydEvents/DeleteStrydEvent?eid=${strydEventId}`,
      headers:{
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.deleteEvent(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getOngoingOrNextEvent(cuid: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/StrydEvents/OngoingOrNextEvent?cuid=${cuid}`,
      headers:{
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getOngoingOrNextEvent(cuid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }
}
