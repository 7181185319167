import { GuestTagCategory } from '../enums/guest-list/guest-tag-category';

export class GuestListGuestObject {
    id: string;
    guestListId: string;
    promoterUserId: string;
    createdByEmployeeUserId: string;
    firstName: string;
    lastName: string;
    fullName: string;
    phoneNumber: string;
    email: string;
    gender: string;
    additionalGuests: boolean;
    totalAdditionalGuests: number;
    notes: string;
    checkedIn: boolean;
    rejected: boolean;
    rejectReason: string;
    reinstated: boolean;
    banned: boolean;
    checkedInBy: string;
    temperatureChecked: boolean;
    customCheckCompleted: boolean;
    guestProfileId: string;
    guestProfileVenueId: string;
    specialEventType: string;
    checkedInTime: Date;
    createDate: Date;
    lastChanged: Date;

    //not in Domain
    guestListName: string;
    addedBy: string;
    lastReservationNote: string;
    totalGuestsModified = 0;
    accessLevelNames: string;
    accessLevelIds: string[];
    addedByName: string;
    promoterName: string;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class PromoterListGuestsResponseObject {
    totalGuests: number;
    totalCheckedInGuests: number;
    totalOwed: number;
    guestListGuests: PromoterListGuestsObject[];

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class PromoterListGuestsObject {
    id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    totalAdditionalGuests: number;
    email: string;
    phoneNumber: string;
    totalCheckedIn: number;
    checkInDateTime: Date;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class GuestListGuestExportObject {
    firstName: string;
    lastName: string;
    fullName: string;
    phoneNumber: string;
    email: string;
    gender: string;
    totalAdditionalGuests: number;
    notes: string;
    checkedIn: boolean;
    rejected: boolean;
    rejectReason: string;
    banned: boolean;
    temperatureChecked: boolean;
    customCheckCompleted: boolean;
    specialEventType: string;
    createDate: Date;
    lastChanged: Date;

    //not in Domain
    guestListName: string;
    addedBy: string;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class GuestListAdditionalGuestObject {
    id: string;
    guestListId: string;
    parentGuestId: string;
    promoterUserId: string;
    createdByEmployeeUserId: string;
    checkedIn: boolean;
    rejected: boolean;
    rejectReason: string;
    reinstated: boolean;
    checkedInBy: string;
    gender: string;
    temperatureChecked: boolean;
    customCheckCompleted: boolean;
    createDate: Date;
    lastChanged: Date;

    //not in Domain
    guestListName: string;
    addedBy: string;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class GuestListObject {
    id: string;
    eventId: string;
    name: string;
    startDate: Date | string;
    startDateUtc: Date;
    endDateTime: Date | string;
    endDateTimeUtc: Date;
    quantityAllowed: number;
    venueQuantityRemaining: number;
    venueUserId: string;
    checkinUserId: string;
    visible: boolean;
    isCheckinList: boolean;
    temperatureCheckRequired: boolean;
    customCheckRequired: boolean;
    customCheckDescription: string;
    isPublicList: boolean;
    isCanceled: boolean;
    guestDetailsRequired: boolean;
    showGender: boolean;
    genderRequired: boolean;
    publicAdditionalGuestAllowed: boolean;
    publicMaxAdditionalGuests: number;
    publicAllowRemoval: boolean;
    publicShowListMembers: boolean;
    allowSpecialRequests: boolean;
    allowSpecialEvents: boolean;
    anyAllergies: boolean;
    showRemainingSpots: boolean;
    isRecurring: boolean;
    recurringListId: string;
    sendTicketLink: boolean;
    alternateTicketVerbiage: string;
    showEmail: boolean;
    emailRequired: boolean;
    showPhoneNumber: boolean;
    phoneNumberRequired: boolean;
    createDate: Date;
    lastChanged: Date;
    timeZoneOffset: number;
    totalGuests: number;
    totalCheckedIn: number;
    isPromoterList: boolean;
    allowances: PromoterAllowanceObject[];
    checkInEmployeeName: string;

    selected: boolean;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class PromoterGuestListObject {
    guestListId: string;
    name: string;
    startDate: Date;
    endDateTime: Date;
    allowanceId: string;
    quantityRemaining: number;
    maxAdditionalGuests: number;
    rate: number;
    showEmail: boolean;
    emailRequired: boolean;
    showPhoneNumber: boolean;
    phoneNumberRequired: boolean;
    showGender: boolean;
    genderRequired: boolean;

    //not in Stryd.Domain
    backgroundIconColor: string;
    isActive: boolean;

    constructor(values: object = {}, colorIndex: number) {
        Object.assign(this, values);

        if (colorIndex !== null) {
            const colorObject = [
                // eslint-disable-next-line @typescript-eslint/quotes
                "bg-[#6149cd]",
                // eslint-disable-next-line @typescript-eslint/quotes
                "bg-[#49cda3]",
                // eslint-disable-next-line @typescript-eslint/quotes
                "bg-[#cd6149]",
                // eslint-disable-next-line @typescript-eslint/quotes
                "bg-[#ffc409]",
                // eslint-disable-next-line @typescript-eslint/quotes
                "bg-[#2E2E3F]",
            ];
            this.backgroundIconColor = colorObject[colorIndex];
        }

        this.isActive = new Date(this.endDateTime) >= new Date() ? true : false;
    }
}

export class CheckInEmployeeObject {
    employeeUserId: string;
    employeeName: string;
    employeeEmail: string;

    selected: boolean;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class PromoterAllowanceObject {
    promoterUserId: string;
    promoterName: string;
    promoterEmail: string;
    quantityAllowed: number;
    maxAdditionalGuests: number;
    rate: number;

    //not in Stryd.Domain
    selected? = false;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class GuestListGuestUploadObject {
    firstName: string;
    lastName: string;
    email: string = null;
    phoneNumber: string = null;
    totalAdditionalGuests = 0;
    notes = null;
    failedReason = null;
    selectedAccessLevelName?: string | null;
    selectedAccessLevelIds?: string[] | null;

    constructor(values: object = {}) {
      Object.assign(this, values);
    }
}

export class BannedGuestObject {
    id: string;
    venueUserId: string;
    bannedBy: string;
    firstName: string;
    lastName: string;
    fullName: string;
    phoneNumber: string;
    email: string;
    reason: string;
    imageFileId: string;
    createDate: Date;
    lastChanged: Date;

    //not in Stryd.Domain
    firstInitial: string;
    lastInitial: string;

    constructor(values: object = {}) {
      Object.assign(this, values);
    }
}

export class GuestTagsObject {
    id: string;
    guestProfileVenueId: string;
    tagCategory: GuestTagCategory;
    name: string;
    backgroundClass: string;
    textClass: string;
    createdByEmployeeUserId: string;
    isActive: boolean;
    createDate: Date;
    lastChanged: Date;

    constructor(values: object = {}) {
      Object.assign(this, values);
    }
}

export class GuestNoteObject {
    id: string;
    creatorUserId: string;
    creatorName: string;
    venueUserId: string;
    strydEventId: string;
    eventName: string;
    guestProfileId: string;
    guestProfileVenueId: string;
    reservationId: string;
    note: string;
    isReservationNote: boolean;
    isHistoricNote: boolean;
    createDate: Date;
    lastChanged: Date;

    constructor(values: object = {}) {
      Object.assign(this, values);
    }
}


export class BannedGuestDetailsObject {
    imageFileId: string;
    firstName: string;
    lastName: string;
    fullName: string;
    phoneNumber: string;
    email: string;
    gender: string;
    totalVenueVisits: number;
    totalOtherBans: number;
    bannedReason: string;
    bannedBy: string;
    isBolo: boolean;

    constructor(values: object = {}) {
      Object.assign(this, values);
    }
}

export class SearchedGuestsObject {
    id: string;
    fullName: string;
    email: string;
    phoneNumber: string;
    checkedIn: boolean;
    allCheckedIn: boolean;
    rejected: boolean;
    allRejected: boolean;
    banned: boolean;
    totalAdditionalGuests: number;
    guestListName: string;
    guestListIsCanceled: boolean;
    hasNotes: boolean;
    notes: string;
    eventName: string;
    isTicketed: boolean;
    isLineSkip: boolean;
    ticketTierName: string;
    isGuestListGuest: boolean;
    isTableGuest: boolean;
    tableName: string;
    addedBy: string;

    //not in Stryd.Domain
    totalGuestsModified = 0;
    accessLevelNames: string;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class SelectedGuestDetailsObject {
    primaryGuestId: string;
    fullName: string;
    email: string;
    phoneNumber: string;
    gender: string;
    checkedIn: boolean;
    checkedInBy: string;
    rejected: boolean;
    rejectReason: string;
    rejectedBy: string;
    banned: boolean;
    totalAdditionalGuests: number;
    additionalGuests: SearchedGuestAdditionalGuestObject[];
    tags: GuestTagsObject[];
    reservationNotes: GuestNoteObject[];
    historicNotes: GuestNoteObject[];
    allNotes: GuestNoteObject[];
    guestProfileId: string;
    guestProfileVenueId: string;
    strydEventId: string;
    addedBy: string;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class SearchedGuestAdditionalGuestObject {
    additionalGuestId: string;
    checkedIn: boolean;
    checkedInBy: string;
    rejected: boolean;
    rejectReason: string;
    rejectedBy: string;
    banned: boolean;
    bannedBy: string;
    bannedReason: string;
    gender: string;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class ListBreakdownDataResponseObject {
    lists: ListBreakdownDataObject[];
    totalInvited: number;
    totalAllotment: number;
    totalCheckedIn: number;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class ListBreakdownDataObject {
    list: string;
    invited: number;
    allotment: number;
    attended: number;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class VenueGuestListAccessLevelObject {
    id: string;
    corporationUserId: string;
    name: string;
    description: string;
    visible: boolean;
    createDate: Date;
    lastChanged: Date;

    //not in Stryd.Domain
    selected: boolean;
    totalGuests: number;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class PromoterBreakdownReportResponse {
    eventDate: Date;
    eventName: string;
    guestListId: string;
    guestListName: string;
    promoterUserId: string;
    promoterName: string;
    totalGuests: number;
    checkedInGuests: number;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}

export class PromoterBreakdownReportExportObject {
    eventDate: Date;
    eventName: string;
    guestListName: string;
    promoterName: string;
    totalGuests: number;
    checkedInGuests: number;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}
