import { Component, OnInit } from '@angular/core';

//Internal providers
import { AccountProvider } from 'src/app/providers/account.provider';

@Component({
  selector: 'app-add-child-venue',
  templateUrl: './add-child-venue.component.html',
  styleUrls: ['./add-child-venue.component.scss'],
})
export class AddChildVenueComponent implements OnInit {
  //internal display variables
  selectedModalStep: number;

  constructor(
    private accountProvider: AccountProvider
  ) { }

  /**
   * @task1 get the current add employee modal step and subscribe to the add employee modal step observable
   */
   ngOnInit() {
    //task 1
    this.selectedModalStep = this.accountProvider.getAddEmployeeModalStep();

    this.accountProvider.addChildVenueModalStepObservable.subscribe((step: number) => {
      this.selectedModalStep = step;
    });
  }

}
