//Angular imports
import { Component, OnInit } from '@angular/core';

//Internal providers
import { AddCampaignProvider } from 'src/app/providers/add-campaign.provider';

//Internal enums
import { CampaignType } from 'src/app/enums/campaigns/campaign-type';
import { CampaignPage } from 'src/app/enums/campaigns/campaign-page';

@Component({
  selector: 'app-add-campaign',
  templateUrl: './add-campaign.component.html',
  styleUrls: ['./add-campaign.component.scss'],
})

//TODO add the following functionality to campaigns: duplicateCampaign, mark as recurring

//TODO possibly reconsider the way we index poster pins
//TODO allow posters to be 'free drop' where promoters hang posters anywhere and we drop pins wherever they take pictures of the posters

//TODO consider how to push either an uploaded image or event image to facebook and have the promoter manually paste an event link...
//TODO instead of auto injecting

export class AddCampaignComponent implements OnInit {
  currentStep = 0;
  currentCampaignType: string;
  currentCampaignTypeId: CampaignType;

  currentPage: CampaignPage;

  constructor(
    private campaignProvider: AddCampaignProvider
  ) { }

  /**
   * @summary subscribe to the campaign step, and campaign type observables and assign to variables accordingly
   * @task1 call the {@link getCurrentPage} function to initialize the page as campaign type
   * @task2 subscribe to the campaign step changes and assign the new value to the currentStep variable
   * @task3 subscribe to the campaign type changes and assign the new value to the currentCampaignType variable
   * @task4 after getting the new campaign type then get the campaignTypeId by calling {@link getPassedCampaignType}
   */
  ngOnInit() {
    this.getCurrentPage();

    this.campaignProvider.selectedCampaignStepObservable.subscribe((res: number) => {
      this.currentStep = res;
      this.getCurrentPage();
    });

    this.campaignProvider.selectedCampaignTypeObservable.subscribe(async (res: string) => {
      this.currentCampaignType = res;

      this.currentCampaignTypeId = await this.campaignProvider.getPassedCampaignType(this.currentCampaignType);

      this.getCurrentPage();
    });
  }

  getCurrentPage() {
    switch (true) {
      case this.currentStep === 0: {
        this.currentPage = CampaignPage.campaignType;
        break;
      }
      case this.currentStep === 1 &&
      (this.currentCampaignTypeId === CampaignType.facebook || this.currentCampaignTypeId === CampaignType.instagram): {
        this.currentPage = CampaignPage.campaignImage;
        break;
      }
      case this.currentStep === 1 && (this.currentCampaignTypeId === CampaignType.location): {
        this.currentPage = CampaignPage.campaignLocationMap;
        break;
      }
      case this.currentStep === 1 && (this.currentCampaignTypeId === CampaignType.posterCode): {
        this.currentPage = CampaignPage.campaignPosterMap;
        break;
      }
      case this.currentStep === 2: {
        this.currentPage = CampaignPage.campaignDateTime;
        break;
      }
      case this.currentStep === 3 &&
      (this.currentCampaignTypeId === CampaignType.location || this.currentCampaignTypeId === CampaignType.posterCode): {
        this.currentPage = CampaignPage.campaignRate;
        break;
      }
      case
      // eslint-disable-next-line max-len
      (this.currentStep === 3 && (this.currentCampaignTypeId === CampaignType.facebook || this.currentCampaignTypeId === CampaignType.instagram)): {
        this.currentPage = CampaignPage.campaignReview;
        break;
      }
      case
      // eslint-disable-next-line max-len
      (this.currentStep === 4 && (this.currentCampaignTypeId === CampaignType.location || this.currentCampaignTypeId === CampaignType.posterCode)): {
        this.currentPage = CampaignPage.campaignReview;
        break;
      }
      default: {
        break;
      }
    }
  }
}
