/* eslint-disable max-len */
//Angular variables
import { Injectable } from '@angular/core';

//Capacitor variables
import { CapacitorHttp, HttpResponse } from '@capacitor/core';

//Other external imports
import * as XLSX from 'xlsx';

//Internal services
import { AccountService } from '../account/account-service.service';

//Internal variables
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChangeLogService {

  constructor(
    private accountService: AccountService
  ) { }

  async getNewAccessToken() {
    const userId = localStorage.getItem('phynxUserId');
    if (userId !== null && userId !== undefined && userId !== '' && userId !== 'null') {
      await this.accountService.refreshLoginMobile(userId).then(async (user) => {
        await this.accountService.setUserTokens(user.tokens).then(async () => true).catch((error) => {
          //TODO throw error here
          throw new Error('Unable to set user authentication tokens');
        });
      }).catch((error) => {
        //TODO throw error here
        throw new Error('Unable to refresh login session');
      });
    }
  }

  async getTableReservationChanges(tableReservationId: string, page: number, pageSize: number, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/ChangeLog/TableReservationChanges?rid=${tableReservationId}&page=${page}&pageSize=${pageSize}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTableReservationChanges(tableReservationId, page, pageSize, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }
}
