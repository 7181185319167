/* eslint-disable max-len */
export const environment = {
  production: true,
  name: 'prod',
  sentryName: 'phynx-prod',

  //PHYNX PROD VARIABLES
  baseUrl: 'https://web-phynx-prod-be.azurewebsites.net', // DEV
  publicUrl: 'https://phynx.live', // This is the url that the webapp is running on (this is used, for example, by stripe as a return url)
  ticketingUrl: 'https://tyx.live', // This is the url that the ticketing webapp is running on (this is used, for example, by the promoter app to copy links that can be sent to potential customers)
  stage: false,
  appVersion: '2.2.01',
  appDistVersion: null,

  sentryDsn: 'https://2db57ef0dfabc18018be76b9f14b047b@o4508138733240320.ingest.us.sentry.io/4508208287318016',
  sentryTracesSampleRate: 0.3,
  sentryReplaysSessionSampleRate: 0.1,
  sentryReplaysOnErrorSampleRate: 1.0,
  sentryProfilesSampleRate: 0.1,
  sentryDebug: false,
};
