//Angular imports
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

//Ionic imports
import { ModalController } from '@ionic/angular';

//Internal models
import { StrydEventObject } from '../models/event.model';



@Injectable()
export class PromoteProvider {
  //Selected promote event
  selectedPromoteEventObservable = new Subject();
  selectedPromoteEvent: StrydEventObject;

  //show event drop down
  showEventDropDownObservable = new Subject();
  showEventDropDown: boolean;

  constructor(
    public modalCtrl: ModalController
  ) { }

  /**
   * @param strydEvent
   * @task1 set the {@var selectedPromoteEvent} to the passed event
   * @task2 call the {@method next()} on {@var selectedPromoteEventObservable} so that...
   * all subscriptions in other components fetch the new {@var selectedPromoteEvent}
   */
  setSelectedPromoteEvent(strydEvent: StrydEventObject) {
    //task 1
    this.selectedPromoteEvent = strydEvent;
    //task 2
    this.selectedPromoteEventObservable.next(strydEvent);
  }

  /**
   * @returns selectedPromoteEvent {StrydEventObject}
   */
  getSelectedPromoteEvent() {
    return this.selectedPromoteEvent;
  }


  /**
   * @param show {boolean}
   * @task1 set the {@var showEventDropDown} to the passed value
   * @task2 call the {@method next()} on {@var showEventDropDownObservable} so that...
   * all subscriptions in other components fetch the new {@var showEventDropDown}
   */
  setShowEventDropDown(show: boolean) {
    //task 1
    this.showEventDropDown = show;
    //task 2
    this.showEventDropDownObservable.next(show);
  }

  /**
   * @returns showEventDropDown {boolean}
   */
  getShowEventDropDown() {
    return this.showEventDropDown;
  }
}
