//Angular imports
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

//Ionic imports
import { AlertController } from '@ionic/angular';

//External imports
import * as XLSX from 'xlsx';
import { NgxFileDropEntry } from 'ngx-file-drop';

//Internal models
import { GuestListGuestUploadObject, GuestListObject, VenueGuestListAccessLevelObject } from 'src/app/models/guest-list.model';
import { StrydEventObject } from 'src/app/models/event.model';

//Internal services
import { GuestListService } from 'src/app/services/guest-list/guest-list.service';
import { AccountService } from 'src/app/services/account/account-service.service';

//Internal providers
import { GlobalAppProvider } from 'src/app/app.provider';
import { AddGuestListGuestProvider } from 'src/app/providers/add-guest-list-guest.provider';
import { GuestListProvider } from 'src/app/providers/guest-list.provider';

//Ionic imports
import { LoadingController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-excel-upload',
  templateUrl: './excel-upload.component.html',
  styleUrls: ['./excel-upload.component.scss'],
})
export class ExcelUploadComponent implements OnInit {
  //internal display variables
  corporationUserId: string;
  showListDropdown: boolean;
  hasGuests: boolean;
  loading: any;
  submissionContainsErrors: boolean;
  showAccessLevels: boolean;
  showAccessLevelDropDown: boolean;
  selectedAccessLevels: FormControl;

  //selected event variables
  selectedEvent: StrydEventObject = null;

  //guest upload variables
  guests: GuestListGuestUploadObject[];
  previewGuests: GuestListGuestUploadObject[];
  totalGuests: number;
  public files: NgxFileDropEntry[] = [];

  //guest list variables
  eventGuestLists: GuestListObject[];

  //add guest variables
  selectedListId: string[] = [];
  selectedListNames: string[] = [];
  joinedSelectedListNames = '';

  //access level variables
  existingAccessLevels: VenueGuestListAccessLevelObject[];

  constructor(
    private accountService: AccountService,
    private addGuestListGuestProvider: AddGuestListGuestProvider,
    private alertController: AlertController,
    private appProvider: GlobalAppProvider,
    private guestListProvider: GuestListProvider,
    private guestListService: GuestListService,
    private loadingCtrl: LoadingController,
    private toastController: ToastController
  ) { }

  async ngOnInit() {
    this.selectedAccessLevels = new FormControl(null);

    //@task1 get the currently selected event
    this.selectedEvent = await this.appProvider.getSelectedEvent();
    this.corporationUserId = await this.accountService.getCorporationUserId();
    this.getExistingAccessLevels();

    //@task2 get all guest lists for the selected event
    this.guestListService.getEventVenueOnlyGuestLists(this.selectedEvent.eventDetailId).then((lists) => {
      this.eventGuestLists = [];

      for (const r in lists) {
        if (Object.prototype.hasOwnProperty.call(lists, r)) {
          if (!lists[r].isPromoterList) {
            this.eventGuestLists.push(new GuestListObject(lists[r]));
          }
        }
      }
    }).catch(async (error) => {
      await this.presentToast(
        'An error occurred while fetching available guest lists',
        2000,
        'top',
        'error-toast',
        'bug'
      );
    });
  }

  async getExistingAccessLevels() {
    await this.guestListService.getVenueGuestListAccessLevel(this.corporationUserId)
      .then((res: any) => {
        this.existingAccessLevels = [];

        for (const r in res) {
          if (Object.prototype.hasOwnProperty.call(res, r)) {
            this.existingAccessLevels.push(new VenueGuestListAccessLevelObject(res[r]));
          }
        }
      })
      .catch((error) => {

      });
  }

  onListClicked(list: GuestListObject) {
    //@task1 find the existing selected list and, if there is one, mark selected to false
    const existingSelection = this.eventGuestLists.find(egl => egl.selected === true);

    if (existingSelection !== null && existingSelection !== undefined) {
      existingSelection.selected = false;
    }

    //@task2 mark the passed list as the inverse of whatever it's current selected state is
    list.selected = !list.selected;

    //@task3 check to see if this list is in the selected list id array
    const previouslySelectedIndex = this.selectedListId.indexOf(list.id);

    //@task4 if the index is not -1 then the list is in the selected list id array and needs to be removed
    if (previouslySelectedIndex !== -1) {
      this.selectedListId.splice(previouslySelectedIndex, 1);
      this.selectedListNames.splice(previouslySelectedIndex, 1);
    } else {
      //@task5 if the index is -1 then the list is not in the selected list id array and needs to be added
      this.selectedListId.push(list.id);
      this.selectedListNames.push(list.name);
    }

    //@task6 add the selected list name to the selected list names array to be displayed in the input field
    //and check if the list is a promoter list
    this.joinedSelectedListNames = this.selectedListNames.join(', ');

    this.showListDropdown = false;
  }

  onFileDropped(files: NgxFileDropEntry[]) {
    this.files = files;

    //ensure all files are of type .xlsx
    if (this.files.some((file) => file.fileEntry.name.split('.').pop() !== 'xlsx')) {
      this.files = [];
      this.presentToast('Only .xlsx files are allowed', 2000, 'top', 'error-toast', 'bug');
      return;
    }

    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file
          console.log(droppedFile.relativePath, file);

          // Read the file as an ArrayBuffer
          const reader = new FileReader();

          reader.onload = (event) => {
            // Add a type assertion for ArrayBuffer
            const arrayBuffer = reader.result as ArrayBuffer;

            // Convert the ArrayBuffer to Uint8Array
            const data = new Uint8Array(arrayBuffer);

            // Read the Excel file with type 'array'
            const workBook = XLSX.read(data, { type: 'array' });

            // Process the workbook to JSON
            const jsonData = workBook.SheetNames.reduce((initial, name) => {
              const sheet = workBook.Sheets[name];
              initial = XLSX.utils.sheet_to_json(sheet);
              return initial;
            }, {});

            console.log(jsonData);

            this.guests = [];

            // Process the JSON data
            for (const r in jsonData) {
              if (Object.prototype.hasOwnProperty.call(jsonData, r)) {
                this.guests.push(new GuestListGuestUploadObject(jsonData[r]));
                this.guests[r].phoneNumber = this.guests[r].phoneNumber?.toString();
              }
            }

            this.previewGuests = [];

            // Create a preview of the guests
            for (let i = 0; i < 5 && i < this.guests.length; i++) {
              this.previewGuests.push(new GuestListGuestUploadObject(this.guests[i]));
            }

            this.hasGuests = this.guests.length > 0;

            this.totalGuests = 0;

            // Calculate the total number of guests
            if (this.hasGuests) {
              this.totalGuests = this.guests.reduce((accumulator, obj) => accumulator + obj.totalAdditionalGuests, 0) + this.guests.length;
            }

            console.log(this.guests);
          };

          // Read the file as an ArrayBuffer
          reader.readAsArrayBuffer(file);

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }


  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];

    reader.onload = (event) => {
      // Add a type assertion for ArrayBuffer
      const arrayBuffer = reader.result as ArrayBuffer;

      // Convert the ArrayBuffer to Uint8Array
      const data = new Uint8Array(arrayBuffer);

      // Read the Excel file with type 'array'
      workBook = XLSX.read(data, { type: 'array' });

      // Process the workbook to JSON
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});

      this.guests = [];

      // Process the JSON data
      for (const r in jsonData) {
        if (Object.prototype.hasOwnProperty.call(jsonData, r)) {
          this.guests.push(new GuestListGuestUploadObject(jsonData[r]));
          this.guests[r].phoneNumber = this.guests[r].phoneNumber?.toString();
        }
      }

      this.previewGuests = [];

      // Create a preview of the guests
      for (let i = 0; i < 5 && i < this.guests.length; i++) {
        this.previewGuests.push(new GuestListGuestUploadObject(this.guests[i]));
      }

      this.hasGuests = this.guests.length > 0;

      this.totalGuests = 0;

      // Calculate the total number of guests
      if (this.hasGuests) {
        this.totalGuests = this.guests.reduce((accumulator, obj) => accumulator + obj.totalAdditionalGuests, 0) + this.guests.length;
      }
    };

    // Read the file as an ArrayBuffer
    reader.readAsArrayBuffer(file);
  }

  async addAccessLevels() {
    this.showAccessLevels = true;
  }

  async selectAccessLevel(accessLevel: VenueGuestListAccessLevelObject) {
    this.showAccessLevelDropDown = false;
    accessLevel.selected = !accessLevel.selected;

    // eslint-disable-next-line max-len
    //recalculate the selected access levels form control value so that it only contains the name of each selected access levels separated by a comma
    const selectedAccessLevels = this.existingAccessLevels.filter((level) => level.selected === true);
    const selectedAccessLevelNames = selectedAccessLevels.map((level) => level.name);
    this.selectedAccessLevels.setValue(selectedAccessLevelNames.join(', '));


    //if at least one access level is selected then show a toast message
    if (this.existingAccessLevels.some((level) => level.selected === true)) {
      // eslint-disable-next-line max-len
      await this.presentToast('You may now click on a guest to assign them to the selected access levels', 5000, 'bottom', 'information-toast', 'information-circle');
    }
  }

  assignAccessControl(guest) {
    this.showAccessLevelDropDown = false;

    if (this.existingAccessLevels.some((level) => level.selected === true)) {
      guest.selectedAccessLevelIds = this.existingAccessLevels.filter((level) => level.selected === true).map((level) => level.id);

      // eslint-disable-next-line max-len
      guest.selectedAccessLevelName = this.existingAccessLevels.filter((level) => level.selected === true).map((level) => level.name).join(', ');
    } else {
      guest.selectedAccessLevelIds = null;
      guest.selectedAccessLevelName = null;
    }
  }

  assignAccessLevelsToAll() {
    if (this.existingAccessLevels.some((level) => level.selected === true)) {
      this.guests.forEach((guest) => {
        guest.selectedAccessLevelIds = this.existingAccessLevels.filter((level) => level.selected === true).map((level) => level.id);
        // eslint-disable-next-line max-len
        guest.selectedAccessLevelName = this.existingAccessLevels.filter((level) => level.selected === true).map((level) => level.name).join(', ');
      });
    } else {
      this.guests.forEach((guest) => {
        guest.selectedAccessLevelIds = null;
        guest.selectedAccessLevelName = null;
      });
    }
  }

  async submitExcelUpload() {
    const selectedList = this.eventGuestLists.find((egl) => egl.selected === true);

    if (this.totalGuests > selectedList.venueQuantityRemaining) {
      await this.presentIncreaseListQuantitiesAlert();
      return;
    } else {
      await this.showLoading();

      await this.assembleRequestBody(false)
        .then((requestBody) => {
          console.log(requestBody);
          this.guestListService.excelUploadGuests(requestBody)
            .then(async (res) => {

              this.submissionContainsErrors = res.isError;

              this.loading.dismiss();


              if (!this.submissionContainsErrors) {
                await this.presentToast('All guests have been uploaded successfully', 3000, 'top', 'success-toast', 'checkmark-circle');
                this.resetExcelUpload();
                await this.guestListProvider.setShowAddGuestListGuest(false);
              } else {
                await this.presentToast('An error occurred while uploading guests', 2000, 'top', 'error-toast', 'bug');
              }
            })
            .catch(async (error) => {
              this.loading.dismiss();
              await this.presentToast('An error occurred while uploading guests', 2000, 'top', 'error-toast', 'bug');
            });

        })
        .catch(async (error) => {
          this.loading.dismiss();
          await this.presentToast('An error occurred while uploading guests', 2000, 'top', 'error-toast', 'bug');
        });
    }
  }

  async presentIncreaseListQuantitiesAlert() {
    const alert = await this.alertController.create({
      header: 'Increase List Quantities',
      // eslint-disable-next-line max-len
      message: `You are attempting to add more guests than the quantity allowed for the selected guest lists. Would you like to increase the quantity allowed for the selected guest list?`,
      mode: 'ios',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Increase Quantities',
          handler: async (data) => {
            await this.showLoading().then(async () => {
              await this.assembleRequestBody(true).then((requestBody) => {
                this.guestListService.excelUploadGuests(requestBody)
                  .then(async (res) => {

                    this.submissionContainsErrors = res.isError;

                    this.loading.dismiss();

                    if (!this.submissionContainsErrors) {
                      // eslint-disable-next-line max-len
                      await this.presentToast('All guests have been uploaded successfully', 3000, 'top', 'success-toast', 'checkmark-circle');
                      this.resetExcelUpload();
                      await this.guestListProvider.setShowAddGuestListGuest(false);
                    } else {
                      // eslint-disable-next-line max-len
                      await this.presentToast('An error occurred while uploading guests', 2000, 'top', 'error-toast', 'bug');
                    }
                  })
                  .catch(async (error) => {
                    await this.presentToast('An error occurred while uploading guests', 2000, 'top', 'error-toast', 'bug');
                  });
              });
            });
          }
        }
      ]
    });

    await alert.present();
  }

  async assembleRequestBody(increaseListQuantities: boolean) {
    const requestBody = {
      guestListIds: this.selectedListId,
      strydEventId: this.selectedEvent.id,
      guests: this.guests,
      corporationUserId: await this.accountService.getCorporationUserId(),
      increaseListQuantities
    };

    return requestBody;
  }

  clearErrors() {
    this.previewGuests = [];
    this.guests = null;
    this.submissionContainsErrors = false;
  }

  resetExcelUpload() {
    this.showListDropdown = false;
    this.hasGuests = false;
    this.submissionContainsErrors = false;
    this.selectedEvent = null;
    this.guests = null;
    this.previewGuests = null;
    this.totalGuests = null;
    this.eventGuestLists = null;
    this.selectedListId = null;
    this.selectedListId = [];
    this.selectedListNames = [];
    this.joinedSelectedListNames = '';
  }

  async closeAddGuestListGuest() {
    await this.guestListProvider.setShowAddGuestListGuest(false);
  }

  async showLoading() {
    this.loading = await this.loadingCtrl.create({
      message: 'Uploading, this may take a moment...',
      cssClass: 'custom-loading',
    });

    this.loading.present();
  }

  async presentToast(
    message: string,
    duration: number,
    position: 'top' | 'middle' | 'bottom',
    cssClass: 'error-toast' | 'warning-toast' | 'success-toast' | 'information-toast' | '',
    icon: 'bug' | 'warning' | 'checkmark-circle' | 'information-circle' | ''
  ){
    const toast = await this.toastController.create({
      message,
      duration,
      position,
      cssClass,
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel'
        }
      ],
      icon
    });

    await toast.present();

    return null;
  }
}
