import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'authorizedSession',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'admin-dashboard',
    loadChildren: () => import('./admin-dashboard/admin-dashboard.module').then( m => m.AdminDashboardPageModule)
  },
  {
    path:'campaigns',
    loadChildren: () => import('./campaigns/campaigns.module').then(m => m.CampaignsPageModule)
  },
  {
    path: 'ticketing',
    loadChildren: () => import('./ticketing/ticketing.module').then( m => m.TicketingPageModule)
  },
  {
    path: 'messaging',
    loadChildren: () => import('./messaging/messaging.module').then( m => m.MessagingPageModule)
  },
  {
    path: 'crm',
    loadChildren: () => import('./crm/crm.module').then( m => m.CrmPageModule)
  },
  {
    path: 'guest-list',
    loadChildren: () => import('./guest-list/guest-list.module').then( m => m.GuestListPageModule)
  },
  {
    path: 'table-service',
    loadChildren: () => import('./table-service/table-service.module').then( m => m.TableServicePageModule)
  },
  {
    path: 'incentives',
    loadChildren: () => import('./incentives/incentives.module').then( m => m.IncentivesPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'add-initial-employee',
    loadChildren: () => import('./add-initial-employee/add-initial-employee.module').then( m => m.AddInitialEmployeePageModule)
  },
  {
    path: 'organization',
    loadChildren: () => import('./organization/organization.module').then( m => m.OrganizationPageModule)
  },
  {
    path: 'promote',
    loadChildren: () => import('./promote/promote.module').then( m => m.PromotePageModule)
  },
  {
    path: 'check-in',
    loadChildren: () => import('./check-in/check-in.module').then( m => m.CheckInPageModule)
  },
  {
    path: 'accounting',
    loadChildren: () => import('./accounting/accounting.module').then( m => m.AccountingPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'refresh-session',
    loadChildren: () => import('./refresh-session/refresh-session.module').then( m => m.RefreshSessionPageModule)
  },
  {
    path: 'venue-terms/:venueId',
    loadChildren: () => import('./venue-terms/venue-terms.module').then( m => m.VenueTermsPageModule)
  },  {
    path: 'widget-test',
    loadChildren: () => import('./widget-test/widget-test.module').then( m => m.WidgetTestPageModule)
  }




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
