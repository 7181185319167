//Angular imports
import { Component, OnInit, AfterViewInit } from '@angular/core';

//Ionic imports
import { AlertController, ToastController } from '@ionic/angular';

//Internal services
import { GuestListService } from 'src/app/services/guest-list/guest-list.service';
import { AccountService } from 'src/app/services/account/account-service.service';

//Internal models
import { BannedGuestDetailsObject, BannedGuestObject } from 'src/app/models/guest-list.model';
import { VenueEmployeePermissionsObject } from 'src/app/models/account.model';

//Environment
import { environment } from 'src/environments/environment';

//Internal providers
import { AccountProvider } from 'src/app/providers/account.provider';

@Component({
  selector: 'app-view-banned-guests',
  templateUrl: './view-banned-guests.component.html',
  styleUrls: ['./view-banned-guests.component.scss'],
})
export class ViewBannedGuestsComponent implements OnInit, AfterViewInit {
  //Internal display variables
  baseUrl: string;

  //Banned guest variables
  bannedGuests: BannedGuestObject[];
  hasBannedGuests: boolean;

  bannedGuestDetails: BannedGuestDetailsObject;
  bannedGuestImages: string[] = [];
  initials = 'N/A';

  //permissions variables
  employeePermissions: VenueEmployeePermissionsObject;
  hasTicketingWritePermission: boolean;
  hasGuestListWritePermission: boolean;
  hasTableServiceWritePermission: boolean;

  constructor(
    private accountProvider: AccountProvider,
    private accountService: AccountService,
    private alertController: AlertController,
    private guestListService: GuestListService,
    private toastController: ToastController
  ) { }

  async ngOnInit() {
    //@task1 set the baseUrl
    this.baseUrl = environment.baseUrl;

    //@task2 get and store the banned guests
    await this.getBannedGuests();
  }

  async ngAfterViewInit() {
    //task 1
    this.employeePermissions = await this.accountProvider.getEmployeePermissions();
    this.hasTicketingWritePermission = this.employeePermissions.ticketing_Write;
    this.hasGuestListWritePermission = this.employeePermissions.guestList_Write;
    this.hasTableServiceWritePermission = this.employeePermissions.tableService_Write;
  }

  async getBannedGuests() {
    await this.guestListService.getBannedGuests(await this.accountService.getCorporationUserId())
      .then((res: BannedGuestObject[]) => {
        this.bannedGuests = [];

        for (const r in res) {
          if (Object.prototype.hasOwnProperty.call(res, r)) {
            this.bannedGuests.push(new BannedGuestObject(res[r]));
            this.bannedGuests[r].firstInitial = res[r].firstName.charAt(0);
            this.bannedGuests[r].lastInitial = res[r].lastName.charAt(0);
          }
        }

        this.hasBannedGuests = this.bannedGuests.length > 0 ? true : false;
      }).catch(async (error) => {
        await this.presentToast('An error occurred fetching your banned guests', 2000, 'top', 'error-toast', 'bug');
      });
  }

  async getBanDetails(guest: BannedGuestObject) {
    await this.guestListService.getBannedGuestDetails(guest.id, await this.accountService.getCorporationUserId())
      .then((res) => {
        this.bannedGuestDetails = res;

        const firstInitial = this.bannedGuestDetails.firstName.charAt(0);
        const lastInitial = this.bannedGuestDetails.lastName.charAt(0);
        this.initials = `${firstInitial}${lastInitial}`;
      })
      .catch(async (error) => {
        await this.presentToast('An error occurred fetching the banned guest details', 2000, 'top', 'error-toast', 'bug');
      });

    await this.guestListService.getBannedGuestImages(guest.id)
      .then((res) => {
        this.bannedGuestImages = [];

        for (const r in res) {
          if (Object.prototype.hasOwnProperty.call(res, r)) {
            this.bannedGuestImages.push(res[r]);
          }
        }
      })
      .catch(async (error) => {
        await this.presentToast('An error occurred fetching the banned guest images', 2000, 'top', 'error-toast', 'bug');
      });
  }

  async presentAddImageAlert(guest: BannedGuestObject) {
    const alert = await this.alertController.create({
      header: 'Add Image',
      message: `Would you like to add an image for ${guest.fullName}?`,
      mode: 'ios',
      buttons: [
        {
          text: 'Never mind',
          role: 'cancel',
          handler: () => {

          },
        },
        {
          text: 'Choose Image',
          role: 'confirm',
          handler: () => {
            //logic to choose image from files
            const fileSelector = document.createElement('input');
            fileSelector.setAttribute('type', 'file');
            fileSelector.setAttribute('accept', '.png,.jpg,.jpeg');

            fileSelector.click();

            fileSelector.onchange = async (e: Event) => {
              const eventTarget = e.target as HTMLInputElement;
              const file = eventTarget.files[0];

              await this.fileToBase64(file)
                .then(async (base64) => {
                  //submit the image
                  const body = {
                    bannedGuestId: guest.id,
                    corporationUserId: await this.accountService.getCorporationUserId(),
                    base64,
                  };

                  await this.guestListService.addBannedGuestImage(body)
                    .then(async (res) => {
                      // eslint-disable-next-line max-len
                      await this.presentToast(`Successfully added an image for ${guest.fullName}`, 3000, 'top', 'success-toast', 'checkmark-circle')
                        .then(async () => await this.getBanDetails(guest));
                    })
                    .catch(async (error) => {
                      // eslint-disable-next-line max-len
                      await this.presentToast(`An error occurred while adding an image for ${guest.fullName}`, 2000, 'top', 'error-toast', 'bug');
                    });
                }).catch((error) => {
                  // eslint-disable-next-line max-len
                  this.presentToast('An error occurred while trying to upload the image. Please try again.', 2000, 'top', 'error-toast', 'bug');
                });

            };

          },
        },
      ],
    });

    await alert.present();
  }

  async fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  async presentRemoveBanAlert(guest: BannedGuestObject) {
    const alert = await this.alertController.create({
      header: 'Remove Ban',
      message: `Are you sure you want to remove the ban from ${guest.fullName}?`,
      mode: 'ios',
      buttons: [
        {
          text: 'Nevermind',
          role: 'cancel',
          handler: () => {

          },
        },
        {
          text: 'Remove Ban',
          role: 'confirm',
          handler: () => {
            this.removeBan(guest);
          },
        },
      ],
    });

    await alert.present();
  }

  async removeBan(guest: BannedGuestObject) {
    await this.guestListService.removeGuestBan(guest)
      .then(async (res) => {
        await this.presentToast(`Successfully removed the ban from ${guest.fullName}`, 3000, 'top', 'warning-toast', 'warning')
          .then(async () => await this.getBannedGuests());
      })
      .catch(async (error) => {
        await this.presentToast(`An error occurred while removing ${guest.fullName}'s ban`, 2000, 'top', 'error-toast', 'bug');
      });
  }


  async presentToast(
    message: string,
    duration: number,
    position: 'top' | 'middle' | 'bottom',
    cssClass: 'error-toast' | 'warning-toast' | 'success-toast' | 'information-toast' | '',
    icon: 'bug' | 'warning' | 'checkmark-circle' | 'information-circle' | ''
  ){
    const toast = await this.toastController.create({
      message,
      duration,
      position,
      cssClass,
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel'
        }
      ],
      icon
    });

    await toast.present();

    return null;
  }
}
