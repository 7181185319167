// External imports
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

//Internal services
import { AccountService } from '../services/account/account-service.service';

//Internal models
import { TicketedGuestObject, StrydEventTicketTierObject } from '../models/ticketing.model';
import { SelectedGuestDetailsObject } from '../models/guest-list.model';

//Internal enums
import { EditTierType } from '../enums/ticketing/edit-tier-type';

@Injectable()
export class TicketingProvider {
    //search guests dropdown variables
    showSearchedGuestsDropdownObservable = new Subject();
    showSearchedGuestsDropdown: boolean;

    //selected guest variables
    selectedGuestObservable = new Subject();
    selectedGuest: TicketedGuestObject;
    selectedGuestDetailsObservable = new Subject();
    selectedGuestDetails: SelectedGuestDetailsObject;

    //editing tier variables
    editingTicketTierObservable = new Subject();
    editingTicketTier: StrydEventTicketTierObject;

    editTicketTierModalIsOpenObservable = new Subject();
    editTicketTierModalIsOpen: boolean;
    editTicketTierType: EditTierType;
    ticketTiersLockedObservable = new Subject();
    newTicketTierAddedObservable = new Subject();

    constructor(
        public modalCtrl: ModalController,
        private accountService: AccountService
    ) { }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showDropdown
     * This sets the searched guests dropdown variable and triggers the ticketed guests dropdown Subject so that all observables fire off
     */
    async setShowSearchedGuestDropdown(showDropdown: boolean) {
        this.showSearchedGuestsDropdownObservable.next(showDropdown);
        this.showSearchedGuestsDropdown = showDropdown;
    }


    // eslint-disable-next-line
    /**
     * @param  {TicketedGuestObject} guest
     * This sets the selected guest variable and triggers the selected guest dropdown Subject so that all observables fire off
     */
     async setSelectedGuest(guest: TicketedGuestObject) {
        this.selectedGuestObservable.next(guest);
        this.selectedGuest = guest;
    }

    async getSelectedGuest() {
        return this.selectedGuest;
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showDropdown
     * This sets the selected searched guest variable for the check in page
     * and triggers the selected searched guest Subject so that all observables fire off
     */
    async setSelectedGuestDetails(guest: SelectedGuestDetailsObject) {
        this.selectedGuestDetailsObservable.next(guest);
        this.selectedGuestDetails = guest;
    }

    async getSelectedGuestDetails() {
        return this.selectedGuestDetails;
    }


    // eslint-disable-next-line
    /**
     * @param  {StrydEventTicketTierObject} tier
     * This sets the editing ticket tier variable for the edit tier modal
     * and triggers the editing ticket tier Subject so that all observables fire off
     */
     async setEditingTicketTier(tier: StrydEventTicketTierObject) {
        this.editingTicketTierObservable.next(tier);
        this.editingTicketTier = tier;
    }

    async getEditingTicketTier() {
        return this.editingTicketTier;
    }


    // eslint-disable-next-line
    /**
     * @param  {boolean} showDropdown
     * This sets the selected searched guest variable for the check in page
     * and triggers the selected searched guest Subject so that all observables fire off
     */
     async setEditTicketTierModal(isOpen: boolean, editType: EditTierType) {
        this.editTicketTierModalIsOpenObservable.next(isOpen);
        this.editTicketTierModalIsOpen = isOpen;
        this.editTicketTierType = editType;
    }

    async getEditTicketTierModalIsOpen() {
        return this.editTicketTierModalIsOpen;
    }

    async getEditTicketTierType() {
        return this.editTicketTierType;
    }


    async setTicketTiersLocked(locked: boolean) {
        this.ticketTiersLockedObservable.next(locked);
    }

    async setNewTicketTierAdded(added: boolean) {
        this.newTicketTierAddedObservable.next(added);
    }
}
