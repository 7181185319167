/* eslint-disable jsdoc/no-types */
// External imports
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

//Internal services
import { AccountService } from '../services/account/account-service.service';

//Internal models

//Internal enums

@Injectable()
export class OrganizationProvider {
    //click events
    organizationPageClickedObservable = new Subject();

    constructor(
        public modalCtrl: ModalController,
        private accountService: AccountService
    ) { }

    async setOrganizationPageClicked(clicked: boolean) {
        this.organizationPageClickedObservable.next(clicked);
    }
}
