/* eslint-disable @typescript-eslint/naming-convention */
// External imports
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

//Internal providers
import { GlobalAppProvider } from '../app.provider';

//Internal models
// eslint-disable-next-line max-len
import { VenueEmployeePermissionsObject, CorporationDetailsObject, VenueEmployeeListObject, VenueFeaturesObject } from '../models/account.model';

//Internal components
import { AddEmployeeComponent } from '../components/organization/add-employee/add-employee.component';
import { AddChildVenueComponent } from '../components/organization/add-child-venue/add-child-venue.component';

//Internal enums
import { DefaultPage } from '../enums/settings/default-page';
import { EmployeeRole } from '../enums/settings/employee-role';

//Internal services
import { AccountService } from '../services/account/account-service.service';

export interface NewEmployeeInfoBody {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    role: EmployeeRole;
}

export interface NewChildCorporationInfoBody {
    corporationName: string;
    corporationPhone: string;
    corporationEmail: string;
    streetAddress: string;
    city: string;
    state: string;
    postalCode: string;
}

export interface NewChildCorporationPrimaryContactBody {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    position: string;
    email: string;
    password: string;
    parentCorporation: boolean;
    childCorporation: boolean;
    subscriptionTier: string;
    totalTrialDays: number;
}

export interface SelectableCorporationsBody {
    venueName: string;
    isDefaultVenue: boolean;
    venueHasStripeConnected: boolean;
    corporationUserId: string;
    employeeIsSuperAdmin: boolean;
    venueIsGuestListOnly: boolean;
}

@Injectable()
export class AccountProvider {
    //authenticated employee permission variables
    employeePermissionsObservable = new Subject();
    employeePermissions: VenueEmployeePermissionsObject;

    //selectable venue variables
    selectedCorporationNameObservable = new Subject<string>();
    selectedCorporationName: string;
    selectedCorporationUserId: string;

    selectableCorporations: SelectableCorporationsBody[];

    //selected venue features
    selectedVenueFeaturesObservable = new Subject();
    selectedVenueFeatures: VenueFeaturesObject;

    //add new employee modal
    addEmployeeModal: any;
    addEmployeeModalIsOpenObservable = new Subject<boolean>();
    addEmployeeModalIsOpen: boolean;
    addEmployeeModalStepObservable = new Subject<number>();
    addEmployeeModalStep: number;
    isEditEmployeeObservable = new Subject<boolean>();
    isEditEmployee: boolean;
    employeeUserId: string;
    employeeUserIdObservable = new Subject<boolean>();

    //new employee info variables
    newEmployeeFirstName: string;
    newEmployeeLastName: string;
    newEmployeeEmail: string;
    // newEmployeePassword: string;
    newEmployeeRole: number;

    //new employee permission variables
    isSuperAdmin: boolean;
    adminDashboard_Read: boolean;
    adminDashboard_Write: boolean;
    promoter_Read: boolean;
    promoter_Write: boolean;
    campaigns_Read: boolean;
    campaigns_Write: boolean;
    ticketing_Read: boolean;
    ticketing_Write: boolean;
    ticketing_Checkin: boolean;
    messaging_Read: boolean;
    messaging_Write: boolean;
    crm_Read: boolean;
    crm_Write: boolean;
    guestList_Read: boolean;
    guestList_Write: boolean;
    guestList_Checkin: boolean;
    tableService_Read: boolean;
    tableService_Write: boolean;
    tableService_Checkin: boolean;
    incentives_Read: boolean;
    incentives_Write: boolean;
    settings_Read: boolean;
    settings_Write: boolean;
    organization_Read: boolean;
    organization_Write: boolean;
    employees_Read: boolean;
    employees_Write: boolean;
    corporationVenues_Read: boolean;
    corporationVenues_Write: boolean;
    accounting_Read: boolean;
    accounting_Write: boolean;
    event_Create: boolean;


    //add new child venue modal
    addChildVenueModal: any;
    addChildVenueModalIsOpenObservable = new Subject<boolean>();
    addChildVenueModalIsOpen: boolean;
    addChildVenueModalStepObservable = new Subject<number>();
    addChildVenueModalStep: number;

    //new child venue variables
    corporationName: string;
    corporationPhone: string;
    corporationEmail: string;
    corporationStreetAddress: string;
    corporationCity: string;
    corporationState: string;
    corporationPostalCode: string;
    primaryContactFirstName: string;
    primaryContactLastName: string;
    primaryContactPhoneNumber: string;
    primaryContactPosition: string;
    primaryContactEmail: string;
    primaryContactPassword: string;
    newVenueSubscriptionTier: string;
    newVenueTotalTrialDays: number;


    constructor(
        public modalCtrl: ModalController,
        private appProvider: GlobalAppProvider,
        private accountService: AccountService
    ) {

    }

    //eslint-disable-next-line
    /**
     * @param  {SelectableCorporationsBody} selectableVenuesResponse
     * @summary set the currently selected corporation name as well as the selectable child corporation variables
     */
    async setSelectedCorporationName(corporationName: string, corporationUserId: string) {
        this.selectedCorporationName = corporationName;
        this.selectedCorporationUserId = corporationUserId;

        this.selectedCorporationNameObservable.next(corporationName);
    }

    async getSelectedCorporationName() {
        return this.selectedCorporationName;
    }

    async setSelectableCorporations(corporations: SelectableCorporationsBody[]) {
        this.selectableCorporations = corporations;
    }

    async getSelectableCorporations() {
        return this.selectableCorporations;
    }

    // eslint-disable-next-line
    /**
     * @param  {VenueEmployeePermissionsObject} permissions
     * @task1 set the {@var employeePermissions} and {@var employeePermissionsObservable} to the passed permissions
     * @task2 get the default page value by calling {@link getDefaultPageValue} and then set the active page
     */
    async setEmployeePermissions(permissions: VenueEmployeePermissionsObject, performNavigation: boolean) {
        //task 1
        this.employeePermissionsObservable.next(permissions);
        this.employeePermissions = permissions;

        //task 2
        const defaultPage = await this.getDefaultPageValue(this.employeePermissions.defaultPage);

        if (performNavigation) {
            this.appProvider.setActivePage(defaultPage);
        }

        //task 3 set the modal step to 1
    }

    /**
     * @returns employeePermissions {@link VenueEmployeePermissionsObject}
     */
    async getEmployeePermissions() {
        return this.employeePermissions;
    }

    async setEmployeeNewDefaultPage(defaultPage: number) {
        this.employeePermissions.defaultPage = defaultPage;
        this.employeePermissionsObservable.next(this.employeePermissions);
    }

    // eslint-disable-next-line
    /**
     * @param  {VenueFeaturesObject[]} features
     * @task1 set the {@var selectedVenueFeatures} and {@var selectedVenueFeaturesObservable} to the passed features
     */
    async setSelectedVenueFeatures(features: VenueFeaturesObject) {
        this.selectedVenueFeaturesObservable.next(features);
        this.selectedVenueFeatures = features;
    }

    async getSelectedVenueFeatures() {
        return this.selectedVenueFeatures;
    }

    /**
     * @returns the associated active page based on {@link employeePermissions.defaultPage}
     */
    async getDefaultPageValue(defaultPage: DefaultPage) {
        switch (defaultPage) {
            case 0: {
                return 'admin-dashboard';
            }
            case 1: {
                return 'campaigns';
            }
            case 2: {
                return 'ticketing';
            }
            case 3: {
                return 'messaging';
            }
            case 4: {
                return 'crm';
            }
            case 5: {
                return 'guest-list';
            }
            case 6: {
                return 'table-service';
            }
            case 7: {
                return 'incentives';
            }
            case 8: {
                return 'accounting';
            }
            case 9: {
                return 'promote';
            }
            case 10: {
                return 'checkin';
            }
            default: {
                return null;
            }
        }
    }


    /**
     * @summary this configures and presents the add employee modal
     * @task1 present the add employee modal
     * @task2 set the addEmployeeModalIsOpen variable to true and update it's observable respectively
     * @task3 subscribe to the addEmployeeModal onDidDismiss and then reset all variables upon dismiss
     * @task4 set the addEmployeeModalStep to 1 by calling {@link setAddEmployeeModalStep}
     */
     async presentAddEmployeeModal(isEditEmployee: boolean){
        //task1
        this.addEmployeeModal = await this.modalCtrl.create({
        breakpoints: [0.1, 0.5, 0.75, 1],
        initialBreakpoint: 1,
        component: AddEmployeeComponent,
        cssClass: 'addEmployeeModal'
        });

        await this.addEmployeeModal.present();

        //task2
        this.addEmployeeModalIsOpen = true;
        this.addEmployeeModalIsOpenObservable.next(true);

        //task3
        this.addEmployeeModal.onDidDismiss().then((res) => {
            //TODO need to add in reset all variables method
            this.resetAllAddEmployeeVariables();
        });

        //task4
        this.setAddEmployeeModalStep(1);

        //task5
        this.isEditEmployee = isEditEmployee;
        this.isEditEmployeeObservable.next(isEditEmployee);
    }

    async dismissAddEmployeeModal(){
        await this.addEmployeeModal.dismiss();

        this.addEmployeeModalIsOpen = false;
        this.addEmployeeModalIsOpenObservable.next(false);
    }

    async getIsEditEmployee() {
        return this.isEditEmployee;
    }

    resetAllAddEmployeeVariables() {
        this.newEmployeeFirstName = null;
        this.newEmployeeLastName = null;
        this.newEmployeeEmail = null;
        // this.newEmployeePassword = null;
        this.newEmployeeRole = null;
        this.isSuperAdmin = undefined;
        this.adminDashboard_Read = undefined;
        this.adminDashboard_Write = undefined;
        this.campaigns_Read = undefined;
        this.campaigns_Write = undefined;
        this.ticketing_Read = undefined;
        this.ticketing_Write = undefined;
        this.ticketing_Checkin = undefined;
        this.messaging_Read = undefined;
        this.messaging_Write = undefined;
        this.crm_Read = undefined;
        this.crm_Write = undefined;
        this.guestList_Read = undefined;
        this.guestList_Write = undefined;
        this.guestList_Checkin = undefined;
        this.tableService_Read = undefined;
        this.tableService_Write = undefined;
        this.tableService_Checkin = undefined;
        this.incentives_Read = undefined;
        this.incentives_Write = undefined;
        this.settings_Read = undefined;
        this.settings_Write = undefined;
        this.organization_Read = undefined;
        this.organization_Write = undefined;
        this.employees_Read = undefined;
        this.employees_Write = undefined;
        this.corporationVenues_Read = undefined;
        this.corporationVenues_Write = undefined;
        this.accounting_Read = undefined;
        this.accounting_Write = undefined;
        this.isEditEmployee = false;
    }

    // eslint-disable-next-line
    /**
     * @param  {number} modalStep
     * @task1 set the {@var addEmployeeModalStep} to the passed modalStep
     * @task2 call the {@method next()} on {@var addEmployeeModalStepObservable} so that...
     * all subscriptions in other components fetch the new {@var addEmployeeModalStep}
     */
     setAddEmployeeModalStep(modalStep: number) {
        //task 1
        this.addEmployeeModalStep = modalStep;
        //task 2
        this.addEmployeeModalStepObservable.next(modalStep);
    }


    /**
     * @returns addEmployeeModalStep {number}
     */
    getAddEmployeeModalStep() {
        return this.addEmployeeModalStep;
    }

    //eslint-disable-next-line
    /**
     * @param  {NewEmployeeInfoBody} employeeInfo
     * @summary set all of the new employee information variables to the passed items respectively
     */
    async setNewEmployeeInfoVariables(employeeInfo: NewEmployeeInfoBody) {
        this.newEmployeeFirstName = employeeInfo.firstName;
        this.newEmployeeLastName = employeeInfo.lastName;
        this.newEmployeeEmail = employeeInfo.email;
        // this.newEmployeePassword = employeeInfo.password;
        this.newEmployeeRole = employeeInfo.role;
    }


    async getEmployeeInfoVariables() {
        const info: NewEmployeeInfoBody = {
            firstName: this.newEmployeeFirstName,
            lastName: this.newEmployeeLastName,
            email: this.newEmployeeEmail,
            password: null,
            role: this.newEmployeeRole
        };

        return info;
    }

    async getEmployeePermissionVariables() {
        const info: VenueEmployeePermissionsObject = {
            isSuperAdmin: this.isSuperAdmin,
            adminDashboard_Read: this.adminDashboard_Read,
            adminDashboard_Write: this.adminDashboard_Write,
            promoter_Read: this.promoter_Read,
            promoter_Write: this.promoter_Write,
            campaigns_Read: this.campaigns_Read,
            campaigns_Write: this.campaigns_Write,
            ticketing_Read: this.ticketing_Read,
            ticketing_Write: this.ticketing_Write,
            ticketing_Checkin: this.ticketing_Checkin,
            messaging_Read: this.messaging_Read,
            messaging_Write: this.messaging_Write,
            crm_Read: this.crm_Read,
            crm_Write: this.crm_Write,
            guestList_Read: this.guestList_Read,
            guestList_Write: this.guestList_Write,
            guestList_Checkin: this.guestList_Checkin,
            tableService_Read: this.tableService_Read,
            tableService_Write: this.tableService_Write,
            tableService_Checkin: this.tableService_Checkin,
            incentives_Read: this.incentives_Read,
            incentives_Write: this.incentives_Write,
            settings_Read: this.settings_Read,
            settings_Write: this.settings_Write,
            organization_Read: this.organization_Read,
            organization_Write: this.organization_Write,
            employees_Read: this.employees_Read,
            employees_Write: this.employees_Write,
            corporationVenues_Read: this.corporationVenues_Read,
            corporationVenues_Write: this.corporationVenues_Write,
            accounting_Read: this.accounting_Read,
            accounting_Write: this.accounting_Write,
            event_Create: this.event_Create,
            id: null,
            corporationUserId: null,
            employeeUserId: null,
            defaultPage: null,
            createDate: null,
            lastChanged: null
        };

        return info;
    }

    //eslint-disable-next-line
    /**
     * @param  {VenueEmployeePermissionsObject} employeePermissions
     * @summary set all of the new employee permission variables to the passed items respectively
     */
    async setNewEmployeePermissionVariables(employeePermissions: VenueEmployeePermissionsObject) {
        this.isSuperAdmin = employeePermissions.isSuperAdmin;
        this.adminDashboard_Read = employeePermissions.adminDashboard_Read;
        this.adminDashboard_Write = employeePermissions.adminDashboard_Write;
        this.campaigns_Read = employeePermissions.campaigns_Read;
        this.campaigns_Write = employeePermissions.campaigns_Write;
        this.ticketing_Read = employeePermissions.ticketing_Read;
        this.ticketing_Write = employeePermissions.ticketing_Write;
        this.ticketing_Checkin = employeePermissions.ticketing_Checkin;
        this.messaging_Read = employeePermissions.messaging_Read;
        this.messaging_Write = employeePermissions.messaging_Write;
        this.crm_Read = employeePermissions.crm_Read;
        this.crm_Write = employeePermissions.crm_Write;
        this.guestList_Read = employeePermissions.guestList_Read;
        this.guestList_Write = employeePermissions.guestList_Write;
        this.guestList_Checkin = employeePermissions.guestList_Checkin;
        this.tableService_Read = employeePermissions.tableService_Read;
        this.tableService_Write = employeePermissions.tableService_Write;
        this.tableService_Checkin = employeePermissions.tableService_Checkin;
        this.incentives_Read = employeePermissions.incentives_Read;
        this.incentives_Write = employeePermissions.incentives_Write;
        this.settings_Read = employeePermissions.settings_Read;
        this.settings_Write = employeePermissions.settings_Write;
        this.organization_Read = employeePermissions.organization_Read;
        this.organization_Write = employeePermissions.organization_Write;
        this.employees_Read = employeePermissions.employees_Read;
        this.employees_Write = employeePermissions.employees_Write;
        this.corporationVenues_Read = employeePermissions.corporationVenues_Read;
        this.corporationVenues_Write = employeePermissions.corporationVenues_Write;
        this.accounting_Read = employeePermissions.accounting_Read;
        this.accounting_Write = employeePermissions.accounting_Write;
        this.event_Create = employeePermissions.event_Create;
    }

    /**
     * @returns the new employee request body which is composed of the new employee info and permissions
     */
    async getNewEmployeeRequestBody() {
        const body = {
            isParentCorporationEmployee: await this.accountService.getIsParentCorporationEmployee(),
            corporationUserId: await this.accountService.getCorporationUserId(),
            newEmployeeFirstName: this.newEmployeeFirstName != null ? this.newEmployeeFirstName : null,
            newEmployeeLastName: this.newEmployeeLastName != null ? this.newEmployeeLastName : null,
            newEmployeeEmail: this.newEmployeeEmail != null ? this.newEmployeeEmail : null,
            // newEmployeePassword: this.newEmployeePassword != null ? this.newEmployeePassword : null,
            newEmployeeRole: this.newEmployeeRole != null ? this.newEmployeeRole : null,
            isSuperAdmin: this.isSuperAdmin != null ? this.isSuperAdmin : false,
            adminDashboard_Read: this.adminDashboard_Read != null ? this.adminDashboard_Read : false,
            adminDashboard_Write: this.adminDashboard_Write != null ? this.adminDashboard_Write : false,
            campaigns_Read: this.campaigns_Read != null ? this.campaigns_Read : false,
            campaigns_Write: this.campaigns_Write != null ? this.campaigns_Write : false,
            ticketing_Read: this.ticketing_Read != null ? this.ticketing_Read : false,
            ticketing_Write: this.ticketing_Write != null ? this.ticketing_Write : false,
            ticketing_Checkin: this.ticketing_Checkin != null ? this.ticketing_Checkin : false,
            messaging_Read: this.messaging_Read != null ? this.messaging_Read : false,
            messaging_Write: this.messaging_Write != null ? this.messaging_Write : false,
            crm_Read: this.crm_Read != null ? this.crm_Read : false,
            crm_Write: this.crm_Write != null ? this.crm_Write : false,
            guestList_Read: this.guestList_Read != null ? this.guestList_Read : false,
            guestList_Write: this.guestList_Write != null ? this.guestList_Write : false,
            guestList_Checkin: this.guestList_Checkin != null ? this.guestList_Checkin : false,
            tableService_Read: this.tableService_Read != null ? this.tableService_Read : false,
            tableService_Write: this.tableService_Write != null ? this.tableService_Write : false,
            tableService_Checkin: this.tableService_Checkin != null ? this.tableService_Checkin : false,
            incentives_Read: this.incentives_Read != null ? this.incentives_Read : false,
            incentives_Write: this.incentives_Write != null ? this.incentives_Write : false,
            settings_Read: this.settings_Read != null ? this.settings_Read : false,
            settings_Write: this.settings_Write != null ? this.settings_Write : false,
            organization_Read: this.organization_Read != null ? this.organization_Read : false,
            organization_Write: this.organization_Write != null ? this.organization_Write : false,
            employees_Read: this.employees_Read != null ? this.employees_Read : false,
            employees_Write: this.employees_Write != null ? this.employees_Write : false,
            corporationVenues_Read: this.corporationVenues_Read != null ? this.corporationVenues_Read : false,
            corporationVenues_Write: this.corporationVenues_Write != null ? this.corporationVenues_Write : false,
            accounting_Read: this.accounting_Read != null ? this.accounting_Read : false,
            accounting_Write: this.accounting_Write != null ? this.accounting_Write : false,
            event_Create: this.event_Create != null ? this.event_Create : false
        };

        return body;
    }

    async getEditEmployeeRequestBody() {
        const body = {
            employeeUserId: this.employeeUserId,
            isParentCorporationEmployee: await this.accountService.getIsParentCorporationEmployee(),
            corporationUserId: await this.accountService.getCorporationUserId(),
            newEmployeeFirstName: this.newEmployeeFirstName != null ? this.newEmployeeFirstName : null,
            newEmployeeLastName: this.newEmployeeLastName != null ? this.newEmployeeLastName : null,
            newEmployeeEmail: this.newEmployeeEmail != null ? this.newEmployeeEmail : null,
            newEmployeePassword: null,
            newEmployeeRole: this.newEmployeeRole != null ? this.newEmployeeRole : null,
            isSuperAdmin: this.isSuperAdmin != null ? this.isSuperAdmin : false,
            adminDashboard_Read: this.adminDashboard_Read != null ? this.adminDashboard_Read : false,
            adminDashboard_Write: this.adminDashboard_Write != null ? this.adminDashboard_Write : false,
            campaigns_Read: this.campaigns_Read != null ? this.campaigns_Read : false,
            campaigns_Write: this.campaigns_Write != null ? this.campaigns_Write : false,
            ticketing_Read: this.ticketing_Read != null ? this.ticketing_Read : false,
            ticketing_Write: this.ticketing_Write != null ? this.ticketing_Write : false,
            ticketing_Checkin: this.ticketing_Checkin != null ? this.ticketing_Checkin : false,
            messaging_Read: this.messaging_Read != null ? this.messaging_Read : false,
            messaging_Write: this.messaging_Write != null ? this.messaging_Write : false,
            crm_Read: this.crm_Read != null ? this.crm_Read : false,
            crm_Write: this.crm_Write != null ? this.crm_Write : false,
            guestList_Read: this.guestList_Read != null ? this.guestList_Read : false,
            guestList_Write: this.guestList_Write != null ? this.guestList_Write : false,
            guestList_Checkin: this.guestList_Checkin != null ? this.guestList_Checkin : false,
            tableService_Read: this.tableService_Read != null ? this.tableService_Read : false,
            tableService_Write: this.tableService_Write != null ? this.tableService_Write : false,
            tableService_Checkin: this.tableService_Checkin != null ? this.tableService_Checkin : false,
            incentives_Read: this.incentives_Read != null ? this.incentives_Read : false,
            incentives_Write: this.incentives_Write != null ? this.incentives_Write : false,
            settings_Read: this.settings_Read != null ? this.settings_Read : false,
            settings_Write: this.settings_Write != null ? this.settings_Write : false,
            organization_Read: this.organization_Read != null ? this.organization_Read : false,
            organization_Write: this.organization_Write != null ? this.organization_Write : false,
            employees_Read: this.employees_Read != null ? this.employees_Read : false,
            employees_Write: this.employees_Write != null ? this.employees_Write : false,
            corporationVenues_Read: this.corporationVenues_Read != null ? this.corporationVenues_Read : false,
            corporationVenues_Write: this.corporationVenues_Write != null ? this.corporationVenues_Write : false,
            accounting_Read: this.accounting_Read != null ? this.accounting_Read : false,
            accounting_Write: this.accounting_Write != null ? this.accounting_Write : false,
            event_Create: this.event_Create != null ? this.event_Create : false
        };

        return body;
    }


    async setEditableEmployee(employee: VenueEmployeeListObject) {
        this.newEmployeeFirstName = employee.employeeFirstName;
        this.newEmployeeLastName = employee.employeeLastName;
        this.newEmployeeEmail = employee.employeeEmail;
        this.employeeUserId = employee.employeeUserId;
        this.newEmployeeRole = employee.employeeRole;

        this.isSuperAdmin = employee.permissions.isSuperAdmin;
        this.adminDashboard_Read = employee.permissions.adminDashboard_Read;
        this.adminDashboard_Write = employee.permissions.adminDashboard_Write;
        this.campaigns_Read = employee.permissions.campaigns_Read;
        this.campaigns_Write = employee.permissions.campaigns_Write;
        this.ticketing_Read = employee.permissions.ticketing_Read;
        this.ticketing_Write = employee.permissions.ticketing_Write;
        this.ticketing_Checkin = employee.permissions.ticketing_Checkin;
        this.messaging_Read = employee.permissions.messaging_Read;
        this.messaging_Write = employee.permissions.messaging_Write;
        this.crm_Read = employee.permissions.crm_Read;
        this.crm_Write = employee.permissions.crm_Write;
        this.guestList_Read = employee.permissions.guestList_Read;
        this.guestList_Write = employee.permissions.guestList_Write;
        this.guestList_Checkin = employee.permissions.guestList_Checkin;
        this.tableService_Read = employee.permissions.tableService_Read;
        this.tableService_Write = employee.permissions.tableService_Write;
        this.tableService_Checkin = employee.permissions.tableService_Checkin;
        this.incentives_Read = employee.permissions.incentives_Read;
        this.incentives_Write = employee.permissions.incentives_Write;
        this.settings_Read = employee.permissions.settings_Read;
        this.settings_Write = employee.permissions.settings_Write;
        this.organization_Read = employee.permissions.organization_Read;
        this.organization_Write = employee.permissions.organization_Write;
        this.employees_Read = employee.permissions.employees_Read;
        this.employees_Write = employee.permissions.employees_Write;
        this.corporationVenues_Read = employee.permissions.corporationVenues_Read;
        this.corporationVenues_Write = employee.permissions.corporationVenues_Write;
        this.accounting_Read = employee.permissions.accounting_Read;
        this.accounting_Write = employee.permissions.accounting_Write;
    }

    /**
     * @summary this configures and presents the add child venue modal
     * @task1 present the add child venue modal
     * @task2 set the addChildVenueModalIsOpen variable to true and update it's observable respectively
     * @task3 subscribe to the addChildVenueModal onDidDismiss and then reset all variables upon dismiss
     * @task4 set the addChildVenueModalStep to 1 by calling {@link setAddChildVenueModalStep}
     */
     async presentAddChildVenueModal(){
        //task1
        this.addChildVenueModal = await this.modalCtrl.create({
        breakpoints: [0.1, 0.5, 0.75, 1],
        initialBreakpoint: 1,
        component: AddChildVenueComponent,
        cssClass: 'addChildVenueModal'
        });

        await this.addChildVenueModal.present();

        //task2
        this.addChildVenueModalIsOpen = true;
        this.addChildVenueModalIsOpenObservable.next(true);

        //task3
        this.addChildVenueModal.onDidDismiss().then((res) => {
            //TODO need to add in reset all variables method
            //this.resetAllVariables();
        });

        //task4
        this.setAddChildVenueModalStep(1);
    }

    async dismissAddChildVenueModal(){
        await this.addChildVenueModal.dismiss();

        this.addChildVenueModalIsOpen = false;
        this.addChildVenueModalIsOpenObservable.next(false);
    }

    // eslint-disable-next-line
    /**
     * @param  {number} modalStep
     * @task1 set the {@var addChildVenueModalStep} to the passed modalStep
     * @task2 call the {@method next()} on {@var addChildVenueModalStepObservable} so that...
     * all subscriptions in other components fetch the new {@var addChildVenueModalStep}
     */
     setAddChildVenueModalStep(modalStep: number) {
        //task 1
        this.addChildVenueModalStep = modalStep;
        //task 2
        this.addChildVenueModalStepObservable.next(modalStep);
    }


    /**
     * @returns addEmployeeModalStep {number}
     */
    getAddChildVenueModalStep() {
        return this.addChildVenueModalStep;
    }

    //eslint-disable-next-line
    /**
     * @param  {NewChildCorporationInfoBody} corporationInfo
     * @summary set all of the new child corporation information variables to the passed items respectively
     */
     async setChildCorporationInfoVariables(corporationInfo: NewChildCorporationInfoBody) {
        this.corporationName = corporationInfo.corporationName;
        this.corporationPhone = corporationInfo.corporationPhone;
        this.corporationEmail = corporationInfo.corporationEmail;
        this.corporationStreetAddress = corporationInfo.streetAddress;
        this.corporationCity = corporationInfo.city;
        this.corporationState = corporationInfo.state;
        this.corporationPostalCode = corporationInfo.postalCode;
    }

    //eslint-disable-next-line
    /**
     * @param  {NewChildCorporationPrimaryContactBody} primaryContactInfo
     * @summary set all of the new child corporation primary contact variables to the passed items respectively
     */
     async setChildCorporationPrimaryContactVariables(primaryContactInfo: NewChildCorporationPrimaryContactBody) {
        this.primaryContactFirstName = primaryContactInfo.firstName;
        this.primaryContactLastName = primaryContactInfo.lastName;
        this.primaryContactPhoneNumber = primaryContactInfo.phoneNumber;
        this.primaryContactPosition = primaryContactInfo.position;
        this.primaryContactEmail = primaryContactInfo.email;
        this.primaryContactPassword = primaryContactInfo.password;
        this.newVenueSubscriptionTier = primaryContactInfo.subscriptionTier;
        this.newVenueTotalTrialDays = primaryContactInfo.totalTrialDays;
    }

    /**
     * @returns the new child corporation request body which is composed of the new child corporation info and primary contact info
     */
     async getNewChildCorporationRequestBody() {
        const body = {
            childCorporation: true,
            city: this.corporationCity,
            corporationEmail: this.corporationEmail,
            corporationName: this.corporationName,
            corporationPhone: this.corporationPhone,
            email: this.corporationEmail,
            firstName: this.primaryContactFirstName,
            lastName: this.primaryContactLastName,
            parentCorporation: false,
            password: this.primaryContactPassword,
            phoneNumber: this.primaryContactPhoneNumber,
            position: this.primaryContactPosition,
            postalCode: this.corporationPostalCode,
            state: this.corporationState,
            streetAddress: this.corporationStreetAddress,
            parentCorporationUserId: await this.accountService.getCorporationUserId(),
            subscriptionTier: this.newVenueSubscriptionTier,
            totalTrialDays: this.newVenueTotalTrialDays
        };

        return body;
    }

}
