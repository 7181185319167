// External
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

//Ionic imports
import { ModalController } from '@ionic/angular';

//Internal components
import { CampaignInsightsComponent } from '../components/campaigns/campaign-insights/campaign-insights.component';
import { CampaignAnalyticsComponent } from '../components/campaigns/campaign-analytics/campaign-analytics.component';

//Internal models
import { CampaignObject } from '../models/campaign.model';

@Injectable()
export class CampaignAnalyticsProvider {
    //campaign insights modal
    campaignInsightsModal: any;
    campaignInsightsModalIsOpenObservable = new Subject();
    campaignInsightsModalIsOpen: boolean;

    //campaign analytics modal
    campaignAnalyticsModal: any;
    campaignAnalyticsModalIsOpenObservable = new Subject();
    campaignAnalyticsModalIsOpen: boolean;

    selectedCampaignInsightsCampaignObservable = new Subject();
    selectedCampaignInsightsCampaign: CampaignObject;

    constructor(
        public modalCtrl: ModalController,
    ) {

    }

    /**
     * @summary this configures and presents the add campaign modal
     */
     async presentCampaignInsightsModal(){
        this.campaignInsightsModal = await this.modalCtrl.create({
        breakpoints: [0.1, 0.5, 0.75, 1],
        initialBreakpoint: 1,
        component: CampaignInsightsComponent
        });

        await this.campaignInsightsModal.present();

        this.campaignInsightsModalIsOpen = true;
        this.campaignInsightsModalIsOpenObservable.next(true);

        this.campaignInsightsModal.onDidDismiss().then((res) => {

        });
    }

    async dismissCampaignInsightsModal(){
        await this.campaignInsightsModal.dismiss();

        this.campaignInsightsModalIsOpen = false;
        this.campaignInsightsModalIsOpenObservable.next(false);
    }


    async setSelectedCampaignInsightsCampaign(campaign: CampaignObject) {
        this.selectedCampaignInsightsCampaign = campaign;
        this.selectedCampaignInsightsCampaignObservable.next(campaign);
    }

    async getSelectedCampaignInsightsId() {
        return this.selectedCampaignInsightsCampaign;
    }


    /**
     * @summary this configures and presents the campaign analytics modal
     */
     async presentCampaignAnalyticsModal(){
        this.campaignAnalyticsModal = await this.modalCtrl.create({
        breakpoints: [0.1, 0.5, 0.75, 1],
        initialBreakpoint: 1,
        component: CampaignAnalyticsComponent
        });

        await this.campaignAnalyticsModal.present();

        this.campaignAnalyticsModalIsOpen = true;
        this.campaignAnalyticsModalIsOpenObservable.next(true);

        this.campaignAnalyticsModal.onDidDismiss().then((res) => {

        });
    }

    async dismissCampaignAnalyticsModal(){
        await this.campaignAnalyticsModal.dismiss();

        this.campaignAnalyticsModalIsOpen = false;
        this.campaignAnalyticsModalIsOpenObservable.next(false);
    }
}
