//Angular imports
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

//Ionic imports
import { LoadingController, ToastController } from '@ionic/angular';

//Internal providers
import { GlobalAppProvider } from 'src/app/app.provider';
import { AddGuestListGuestProvider } from 'src/app/providers/add-guest-list-guest.provider';
import { GuestListProvider } from 'src/app/providers/guest-list.provider';

//Internal models
import { StrydEventObject } from 'src/app/models/event.model';
import { GuestListObject, VenueGuestListAccessLevelObject } from 'src/app/models/guest-list.model';

//Internal services
import { GuestListService } from 'src/app/services/guest-list/guest-list.service';
import { AccountService } from 'src/app/services/account/account-service.service';

export interface Genders {
  commonName: string;
  internalName: string;
}

@Component({
  selector: 'app-individual-guest',
  templateUrl: './individual-guest.component.html',
  styleUrls: ['./individual-guest.component.scss'],
})
export class IndividualGuestComponent implements OnInit {
  //internal display variables
  corporationUserId: string;
  showListDropdown: boolean;
  loading: any;
  showAccessLevelDropDown: boolean;

  //selected event variables
  selectedEvent: StrydEventObject = null;

  //guest list variables
  eventGuestLists: GuestListObject[];

  //add guest variables
  selectedListId: string[] = [];
  selectedListNames: string[] = [];
  joinedSelectedListNames = '';

  individualGuestForm: UntypedFormGroup;

  genders: Genders[] = [
    { commonName: 'Male', internalName: 'Male' },
    { commonName: 'Female', internalName: 'Female' },
    { commonName: 'Other', internalName: 'Other' }
  ];

  selectedGender = '';
  showSelectGender: boolean;

  //access level variables
  existingAccessLevels: VenueGuestListAccessLevelObject[];
  selectedAccessLevelName?: string | null;
  selectedAccessLevelIds?: string[] | null;

  constructor(
    private accountService: AccountService,
    private addGuestListGuestProvider: AddGuestListGuestProvider,
    private appProvider: GlobalAppProvider,
    private formBuilder: UntypedFormBuilder,
    private guestListProvider: GuestListProvider,
    private guestListService: GuestListService,
    private loadingController: LoadingController,
    private toastController: ToastController
  ) { }

  get phoneNumber() { return this.individualGuestForm.get('phoneNumber'); }
  get email() { return this.individualGuestForm.get('email'); }
  get firstName() { return this.individualGuestForm.get('firstName'); }
  get lastName() { return this.individualGuestForm.get('lastName'); }
  get gender() { return this.individualGuestForm.get('gender'); }
  get additionalGuests() { return this.individualGuestForm.get('additionalGuests'); }
  get totalAdditionalGuests() { return this.individualGuestForm.get('totalAdditionalGuests'); }
  get selectedAccessLevels() { return this.individualGuestForm.get('selectedAccessLevels'); }
  get notes() { return this.individualGuestForm.get('notes'); }

  async ngOnInit() {
    //@task1 initialize the individual guest form
    this.individualGuestForm = this.formBuilder.group({
      phoneNumber: [null, [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
      // eslint-disable-next-line max-len
      email: [null, [Validators.email, Validators.minLength(5), Validators.maxLength(100), Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      firstName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern('^[a-zA-Z ]*$')]],
      lastName: [null, [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern('^[a-zA-Z ]*$')]],
      gender: [null, [Validators.required, Validators.minLength(4), Validators.maxLength(6)]],
      additionalGuests: [false, [Validators.required, Validators.pattern('^(true|false)$')]],
      totalAdditionalGuests: [0, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(0)]],
      selectedAccessLevels: [null],
      notes: [null],
    });

    //@task2 get the currently selected event
    this.selectedEvent = await this.appProvider.getSelectedEvent();
    this.corporationUserId = await this.accountService.getCorporationUserId();
    this.getExistingAccessLevels();

    this.selectGender(this.genders[2]);

    //@task3 get all guest lists for the selected event
    this.guestListService.getEventVenueOnlyGuestLists(this.selectedEvent.eventDetailId).then((lists) => {
      this.eventGuestLists = [];

      for (const r in lists) {
        if (Object.prototype.hasOwnProperty.call(lists, r)) {
          if (!lists[r].isPromoterList) {
            this.eventGuestLists.push(new GuestListObject(lists[r]));
          }
        }
      }
    }).catch(async (error) => {
      await this.presentToast(
        'An error occurred while fetching available guest lists',
        2000,
        'top',
        'error-toast',
        'bug'
      );
    });
  }

  async getExistingAccessLevels() {
    await this.guestListService.getVenueGuestListAccessLevel(this.corporationUserId)
      .then((res: any) => {
        this.existingAccessLevels = [];

        for (const r in res) {
          if (Object.prototype.hasOwnProperty.call(res, r)) {
            this.existingAccessLevels.push(new VenueGuestListAccessLevelObject(res[r]));
          }
        }
      })
      .catch((error) => {

      });
  }


  onListClicked(list: GuestListObject) {
    list.selected = !list.selected;

    const previouslySelectedIndex = this.selectedListId.indexOf(list.id);

    if (previouslySelectedIndex !== -1) {
      this.selectedListId.splice(previouslySelectedIndex, 1);
      this.selectedListNames.splice(previouslySelectedIndex, 1);
    } else {
      this.selectedListId.push(list.id);
      this.selectedListNames.push(list.name);
    }

    this.joinedSelectedListNames = this.selectedListNames.join(', ');

    this.showListDropdown = false;
  }

  selectGender(gender: Genders) {
    this.selectedGender = gender.internalName;
    this.gender.setValue(gender.internalName);
    this.showSelectGender = false;
  }

  async selectAccessLevel(accessLevel: VenueGuestListAccessLevelObject) {
    this.showAccessLevelDropDown = false;
    accessLevel.selected = !accessLevel.selected;

    // eslint-disable-next-line max-len
    //recalculate the selected access levels form control value so that it only contains the name of each selected access levels separated by a comma
    const selectedAccessLevels = this.existingAccessLevels.filter((level) => level.selected === true);
    const selectedAccessLevelNames = selectedAccessLevels.map((level) => level.name);
    this.selectedAccessLevels.setValue(selectedAccessLevelNames.join(', '));

    if (this.existingAccessLevels.some((level) => level.selected === true)) {
      this.selectedAccessLevelIds = this.existingAccessLevels.filter((level) => level.selected === true).map((level) => level.id);

      // eslint-disable-next-line max-len
      this.selectedAccessLevelName = this.existingAccessLevels.filter((level) => level.selected === true).map((level) => level.name).join(', ');
    } else {
      this.selectedAccessLevelIds = null;
      this.selectedAccessLevelName = null;
    }
  }

  async onIndividualGuestSubmit() {
    this.showListDropdown = false;

    if (this.selectedListId.length === 0) {
      return null;
    }

    if (this.totalAdditionalGuests.value === null || this.totalAdditionalGuests.value === undefined) {
      this.totalAdditionalGuests.setValue(0);
    }

    await this.showLoading();

    await this.assembleRequestBody()
      .then((requestBody) => {

        this.guestListService.addIndividualGuest(requestBody)
          .then(async (res) => {

            this.loading.dismiss();

            // eslint-disable-next-line max-len
            await this.presentToast(`${requestBody.firstName} has been added successfully`, 3000, 'top', 'success-toast', 'checkmark-circle');

            this.resetIndividualGuestForm();
            await this.guestListProvider.setShowAddGuestListGuest(false);

          }).catch(async (error) => {
            this.loading.dismiss();
            await this.presentToast(`There was an error adding ${requestBody.firstName}`, 2000, 'top', 'error-toast', 'bug');
          });

      })
      .catch(async (error) => {
        this.loading.dismiss();
        await this.presentToast('An error occurred while adding the guest', 2000, 'top', 'error-toast', 'bug');
      });
  }

  async assembleRequestBody() {
    const requestBody = {
      guestListIds: this.selectedListId,
      guestProfileTags: [],
      corporationUserId: await this.accountService.getCorporationUserId(),
      phoneNumber: this.phoneNumber.value,
      email: this.email.value,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      gender: this.gender.value,
      hasAdditionalGuests: this.totalAdditionalGuests.value > 0 ? true : false,
      totalAdditionalGuests: this.totalAdditionalGuests.value,
      notes: this.notes.value,
      selectedAccessLevelIds: this.selectedAccessLevelIds
    };

    return requestBody;
  }

  closeDropdowns() {
    this.showListDropdown = false;
    this.showSelectGender = false;
  }

  async closeAddGuestListGuest() {
    await this.guestListProvider.setShowAddGuestListGuest(false);
  }

  resetIndividualGuestForm() {
    this.individualGuestForm.reset({
      phoneNumber: null,
      email: null,
      firstName: null,
      lastName: null,
      gender: null,
      additionalGuests: false,
      totalAdditionalGuests: 0,
      notes: null
    });

    this.selectedGender = '';
    this.selectedListId = [];
    this.selectedListNames = [];
    this.joinedSelectedListNames = '';
  }

  async showLoading() {
    this.loading = await this.loadingController.create({
      message: 'Processing new guest...',
      cssClass: 'custom-loading',
    });

    this.loading.present();
  }


  async presentToast(
    message: string,
    duration: number,
    position: 'top' | 'middle' | 'bottom',
    cssClass: 'error-toast' | 'warning-toast' | 'success-toast' | 'information-toast' | '',
    icon: 'bug' | 'warning' | 'checkmark-circle' | 'information-circle' | ''
  ){
    const toast = await this.toastController.create({
      message,
      duration,
      position,
      cssClass,
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel'
        }
      ],
      icon
    });

    await toast.present();

    return null;
  }
}
