/* eslint-disable max-len */
//Angular variables
import { Injectable } from '@angular/core';
import { from, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

//Ionic imports


//Capacitor variables
import { CapacitorHttp, HttpOptions, HttpResponse } from '@capacitor/core';

//Other external imports
import * as XLSX from 'xlsx';

//Internal services
import { AccountService } from '../account/account-service.service';

//Internal variables
import { environment } from '../../../environments/environment';

//Internal models
import { BannedGuestObject } from 'src/app/models/guest-list.model';

//Internal providers
import { GuestCheckInProvider } from '../../providers/guest-checkin.provider';

declare global {
  interface Navigator {
      msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

@Injectable({
  providedIn: 'root'
})
export class GuestListService {

  constructor(
    private accountService: AccountService,
    private guestCheckInProvider: GuestCheckInProvider
  ) { }

  async getNewAccessToken() {
    const userId = localStorage.getItem('phynxUserId');
    if (userId !== null && userId !== undefined && userId !== '' && userId !== 'null') {
      await this.accountService.refreshLoginMobile(userId).then(async (user) => {
        await this.accountService.setUserTokens(user.tokens).then(async () => true).catch((error) => {
          //TODO throw error here
          throw new Error('Unable to set user authentication tokens');
        });
      }).catch((error) => {
        //TODO throw error here
        throw new Error('Unable to refresh login session');
      });
    }
  }

  async searchEventGuests(requestBody, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/SearchEventGuests`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.searchEventGuests(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventGuestLists(eventDetailId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetEventGuestLists/${eventDetailId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventGuestLists(eventDetailId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getGuestListGuests(listId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetListGuests?lid=${listId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getGuestListGuests(listId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventVenueOnlyGuestLists(eventDetailId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetEventVenueOnlyGuestLists/${eventDetailId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventVenueOnlyGuestLists(eventDetailId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getSelectedGuestListPromoterAllowances(listId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetSelectedGuestListPromoterAllowances?listId=${listId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getSelectedGuestListPromoterAllowances(listId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getGuestListCheckInEmployees(corporationUserId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetVenueCheckInEmployees/${corporationUserId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getGuestListCheckInEmployees(corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getListAllowances(listId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetListAllowances?listId=${listId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getListAllowances(listId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getExistingGuestListCheckInEmployees(listId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetExistingGuestListCheckInEmployees?glid=${listId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getExistingGuestListCheckInEmployees(listId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getPreferredPromoters(corporationUserId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetVenuePreferredPromoters/${corporationUserId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPreferredPromoters(corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getNewPreferredPromoters(body: object, retry = true){
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/GetNewVenuePreferredPromoters`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getNewPreferredPromoters(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addNewGuestList(requestBody, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/AddNewGuestList`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addNewGuestList(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateGuestList(requestBody, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/UpdateGuestList`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateGuestList(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addIndividualGuest(requestBody, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/AddIndividualGuest`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addIndividualGuest(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addPromoterIndividualGuest(requestBody, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/AddPromoterIndividualGuest`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addPromoterIndividualGuest(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async promoterExcelUploadGuest(requestBody, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/PromoterExcelUploadGuests`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.promoterExcelUploadGuest(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async bulkAddGuests(requestBody, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/BulkAddGuests`,
      headers: {
        authorization: this.accountService.authorizationHeader(),
        'content-type': 'application/json',
      },
      data: requestBody,
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.bulkAddGuests(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      const error = response.data;
      // (error as any).status = response.status; // Add status code to the error object
      // (error as any).message = response.data; // Add message to the error object
      throw error;
    }
  }

  async excelUploadGuests(requestBody, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/ExcelUploadGuests`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.excelUploadGuests(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventGuestListStats(strydEventId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetEventGuestListStats/${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventGuestListStats(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getGuestAdditionalGuests(parentGuestId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetGuestAdditionalGuests/${parentGuestId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getGuestAdditionalGuests(parentGuestId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateGuestStatus(requestBody, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/UpdateGuestStatus`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateGuestStatus(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateMultipleGuestStatus(requestBody, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/UpdateMultipleGuestStatus`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateMultipleGuestStatus(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateAdditionalGuestStatus(requestBody, isTicketedGuest: boolean, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/UpdateAdditionalGuestStatus?isTicketedGuest=${isTicketedGuest}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateAdditionalGuestStatus(requestBody, isTicketedGuest, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addNewAdditionalGuest(parentGuestId: string, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/AddAdditionalGuest/${parentGuestId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addNewAdditionalGuest(parentGuestId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async removeAdditionalGuest(additionalGuestId: string, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/RemoveAdditionalGuest/${additionalGuestId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.removeAdditionalGuest(additionalGuestId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async banGuest(requestBody, isTicketedGuest: boolean, retry = true) {
    const options = {
      url: `${environment.baseUrl}/api/GuestLists/BanGuest?isTicketedGuest=${isTicketedGuest}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.banGuest(requestBody, isTicketedGuest, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async banAdditionalGuest(requestBody, isTicketedGuest: boolean, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/BanAdditionalGuest?isTicketedGuest=${isTicketedGuest}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.banAdditionalGuest(requestBody, isTicketedGuest, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async removeGuestBan(bannedGuest: BannedGuestObject, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/RemoveBanFromGuest`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: bannedGuest
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.removeGuestBan(bannedGuest, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getBannedGuests(corporationUserId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetBannedGuests/${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getBannedGuests(corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async resendGuestInvitation(parentGuestId: string, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/ResendGuestInvitation/${parentGuestId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.resendGuestInvitation(parentGuestId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async sendGuestInvitation(parentGuestId: string, guestEmail: string, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/SendGuestInvitation?gid=${parentGuestId}&email=${guestEmail}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.sendGuestInvitation(parentGuestId, guestEmail, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addNewGuestTag(requestBody, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/AddNewGuestTag`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addNewGuestTag(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getGuestTags(guestProfileId: string, corporationUserId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetGuestTags/${guestProfileId}/${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getGuestTags(guestProfileId, corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async removeGuestTag(guestTagId: string, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/RemoveGuestTag/${guestTagId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.removeGuestTag(guestTagId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addNewGuestNote(requestBody, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/AddGuestNote`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addNewGuestNote(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateGuestNote(requestBody, retry = true) {
    const options: HttpOptions = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/GuestLists/UpdateGuestNote`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateGuestNote(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async removeGuestNote(requestBody, retry = true) {
    const options: HttpOptions = {
      method: 'DELETE',
      url: `${environment.baseUrl}/api/GuestLists/RemoveGuestNote`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.delete(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.removeGuestNote(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getGuestNotes(getReservationNote: boolean, reservationId: string, guestProfileVenueId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetGuestNotes/${getReservationNote}/${reservationId}/${guestProfileVenueId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getGuestNotes(getReservationNote, reservationId, guestProfileVenueId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async searchGuestNotes(getReservationNote: boolean, reservationId: string, guestProfileVenueId: string, searchString: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetGuestNotes/${getReservationNote}/${reservationId}/${guestProfileVenueId}/${searchString}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.searchGuestNotes(getReservationNote, reservationId, guestProfileVenueId, searchString, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getBannedGuestDetails(bannedId: string, corporationUserId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetBannedDetails/${bannedId}/${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getBannedGuestDetails(bannedId, corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getBannedGuestImages(bannedId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetBannedGuestImages?bid=${bannedId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getBannedGuestImages(bannedId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async addBannedGuestImage(requestBody, retry = true) {
    const options: HttpOptions = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/GuestLists/AddBannedGuestImage`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addBannedGuestImage(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getPreCheckInGenders(eventDetailId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetPreCheckInGenders/${eventDetailId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPreCheckInGenders(eventDetailId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getCheckedInGenders(eventDetailId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetCheckedInGenders/${eventDetailId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getCheckedInGenders(eventDetailId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getGuestsAddedOverTime(eventDetailId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetGuestsAddedOverTime/${eventDetailId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getGuestsAddedOverTime(eventDetailId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getPromoterEventGuestLists(strydEventDetailId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetPromoterEventGuestLists?eid=${strydEventDetailId}`,
      headers:{
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPromoterEventGuestLists(strydEventDetailId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getAllPromoterEventGuestLists(strydEventDetailId: string, retry = true) {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetAllPromoterEventGuestLists?eid=${strydEventDetailId}`,
      headers:{
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getAllPromoterEventGuestLists(strydEventDetailId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getPromoterGuestListGuests(guestListId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/PromoterGuestListGuests?lid=${guestListId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPromoterGuestListGuests(guestListId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async removeGuestFromList(parentGuestId: string, retry = true) {
    const options = {
      method: 'DELETE',
      url: `${environment.baseUrl}/api/GuestLists/RemoveGuestFromList?gid=${parentGuestId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.delete(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.removeGuestFromList(parentGuestId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getSearchedGuests(searchString: string, page: number, pageSize: number, corporationUserId: string, eid: string, retry = true){
    //task 2
    if (searchString === null || searchString === ''){
      this.guestCheckInProvider.setShowSearchedGuestDropdown(false);
      return from([]);
    } else {
      //task 3
      const options: HttpOptions = {
        method: 'GET',
        // eslint-disable-next-line max-len
        url: `${environment.baseUrl}/api/GuestLists/SearchGuestsForCurrentDay?cui=${corporationUserId}&search=${searchString}&page=${page}&size=${pageSize}&eid=${eid}`,
        headers: {authorization:this.accountService.authorizationHeader()}
      };

      //task 4
      const response: HttpResponse = await CapacitorHttp.get(options);

      if (response.status === 200) {
        return response.data;
      } else if (response.status === 204) {
        return null;
      } else if (response.status === 401 && retry) { // Unauthorized
        try {
          await this.getNewAccessToken();

          // Retry the request with the new access token
          return this.getSearchedGuests(searchString, page, pageSize, corporationUserId, eid, false);
        } catch (error) {
          throw new Error('Unable to refresh access token: ' + error.message);
        }
      } else {
        throw new Error(response.data.message);
      }
    }
  }

  async researchIndividualGuestListGuestAsync(guestId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/ResearchIndividualGuestListGuestAsync?gid=${guestId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.researchIndividualGuestListGuestAsync(guestId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getSearchedGuestDetails(guestId: string, corporationUserId: string, isTicketedGuest: boolean, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetSearchedGuestDetails?gid=${guestId}&cui=${corporationUserId}&ticketed=${isTicketedGuest}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getSearchedGuestDetails(guestId, corporationUserId, isTicketedGuest, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updatePrimaryGuestGender(guestId: string, gender: string, isTicketed: boolean, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/UpdatePrimaryGuestGender?gid=${guestId}&gender=${gender}&isTicketed=${isTicketed}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updatePrimaryGuestGender(guestId, gender, isTicketed, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateAdditionalGuestGender(guestId: string, gender: string, isTicketed: boolean, retry = true) {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/UpdateAdditionalGuestGender?gid=${guestId}&gender=${gender}&isTicketed=${isTicketed}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateAdditionalGuestGender(guestId, gender, isTicketed, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getDownloadableListGuests(listId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetDownloadableListGuests?listId=${listId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getDownloadableListGuests(listId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventDownloadableListGuests(strydEventId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetEventDownloadableListGuests?strydEventId=${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventDownloadableListGuests(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getVenueRecurringGuestLists(corporationUserId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetVenueRecurringGuestLists?cuid=${corporationUserId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenueRecurringGuestLists(corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async removeVenueRecurringGuestList(originalListId: string, retry = true){
    const options: HttpOptions = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/GuestLists/RemoveVenueRecurringGuestList?originalListId=${originalListId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.removeVenueRecurringGuestList(originalListId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async removeGuestList(guestListId: string, retry = true){
    const options: HttpOptions = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/GuestLists/RemoveGuestList?guestListId=${guestListId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.removeGuestList(guestListId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addGuestPhoneNumber(guestId: string, phoneNumber: string, retry = true){
    const options: HttpOptions = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/GuestLists/AddGuestPhoneNumber?gid=${guestId}&phoneNumber=${phoneNumber}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addGuestPhoneNumber(guestId, phoneNumber, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addGuestEmail(guestId: string, email: string, retry = true){
    const options: HttpOptions = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/GuestLists/AddGuestEmail?gid=${guestId}&email=${email}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addGuestEmail(guestId, email, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async updateReservationName(body: object, retry = true){
    const options: HttpOptions = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/GuestLists/UpdateReservationName`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateReservationName(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateReservationContact(body: object, retry = true){
    const options: HttpOptions = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/GuestLists/UpdateReservationContact`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateReservationContact(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateReservationGender(body: object, retry = true){
    const options: HttpOptions = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/GuestLists/UpdateReservationGender`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateReservationGender(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getListBreakdownData(eventId: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetListBreakdownData?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getListBreakdownData(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addVenueGuestListAccessLevel(body: object, retry = true){
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/VenueGuestListAccessLevel`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addVenueGuestListAccessLevel(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getVenueGuestListAccessLevel(cuid: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/VenueGuestListAccessLevel?cuid=${cuid}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenueGuestListAccessLevel(cuid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async updateVenueGuestListAccessLevel(body: object, retry = true){
    const options: HttpOptions = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/GuestLists/VenueGuestListAccessLevel`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateVenueGuestListAccessLevel(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async removeVenueGuestListAccessLevel(alid: string, retry = true){
    const options: HttpOptions = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/GuestLists/RemoveVenueGuestListAccessLevel?alid=${alid}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.removeVenueGuestListAccessLevel(alid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async updateGuestListGuestAccessLevel(body: object, retry = true){
    const options: HttpOptions = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/GuestLists/GuestListGuestAccessLevel`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateGuestListGuestAccessLevel(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getGuestTotalCheckedIn(gid: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetGuestTotalCheckedIn?gid=${gid}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getGuestTotalCheckedIn(gid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getAccessLevelBreakDown(eid: string, cuid: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetAccessLevelBreakDown?eid=${eid}&cuid=${cuid}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getAccessLevelBreakDown(eid, cuid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getGuestListGuestsByAccessLevel(eid: string, alid: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetGuestListGuestsByAccessLevel?eid=${eid}&alid=${alid}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getGuestListGuestsByAccessLevel(eid, alid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async editPromoterGuestInfo(body: object, retry = true){
    const options: HttpOptions = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/GuestLists/UpdatePromoterGuest`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.editPromoterGuestInfo(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      const error = new Error(response.data.message);
      (error as any).status = response.status; // Add status code to the error object
      (error as any).message = response.data.message; // Add message to the error object
      throw error;
    }
  }

  async getPromoterBreakdownReport(edid: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetPromoterBreakdownReport?edid=${edid}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPromoterBreakdownReport(edid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getPromoterUserName(puid: string, retry = true){
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetPromoterUserName?puid=${puid}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPromoterUserName(puid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async exportToExcel(filename: string,
    rows: object[] | object,
    sheetName: string = 'Sheet1',
    columnWidths: number[] = [],
    rowHeights: number[] = [],
    header: string[] = [],
    footer: string[] = []) {

      if (!rows) {
        return;
      }

      // If rows is a single object, wrap it in an array
      const data = Array.isArray(rows) ? rows : [rows];

      // Prepare workbook
      const wb = XLSX.utils.book_new();

      // Convert data to worksheet
      const ws = XLSX.utils.json_to_sheet(data);

      // Set column widths
      ws['!cols'] = columnWidths.map(w => ({wch: w}));

      // Set row heights (only works for the xlsx format, and not for older xls)
      ws['!rows'] = rowHeights.map(h => ({hpt: h}));

      // Add auto-filter to all columns, use the range from the sheet directly
      ws['!autofilter'] = { ref: ws['!ref'] };

      // Add header and footer
      ws['!margins'] = { header: 0.5, footer: 0.5 };
      ws['!header'] = header;
      ws['!footer'] = footer;

      // Append worksheet to workbook
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      // Write workbook and force download
      XLSX.writeFile(wb, `${filename}.xlsx`);
  }

  async exportToCsv(filename: string, rows: object[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent =
      keys.join(separator) +
        '\n' +
        rows.map(row => keys.map(k => {
            let cell = row[k] === null || row[k] === undefined ? '' : row[k];
            cell = cell instanceof Date
              ? cell.toLocaleString()
              : cell.toString().replace(/"/g, '""');
            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }
            return cell;
          }).join(separator)).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
