//Angular imports
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

//Ionic imports
import { Platform, ToastController } from '@ionic/angular';

//Capacitor imports
import { App } from '@capacitor/app';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';

//Internal services
import { AccountService } from './services/account/account-service.service';

//Internal providers
import { AccountProvider } from './providers/account.provider';
import { GlobalAppProvider } from './app.provider';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private appProvider: GlobalAppProvider,
    private renderer: Renderer2,
    private router: Router,
    private toastController: ToastController
  ) {

    // eslint-disable-next-line max-len
    // if (window.location.pathname === '/authorizedSession' || window.location.pathname === '/organization' || window.location.pathname === '/promote') {
    //   if (!accountService.hasValidIdToken()){
    //     this.navigateToHome();
    //   }
    // }

    this.setStatusBarColor();
  }

  async ngOnInit() {
    this.renderer.addClass(document.body, 'dark');

    App.addListener('appStateChange', ({ isActive }) => {
      const pathIsAuthorizedSession = (window.location.pathname === '/authorizedSession');

      if (pathIsAuthorizedSession) {
        this.appProvider.setAppForegroundStatus(isActive);
      }
    });

    const userId = localStorage.getItem('phynxUserId');

    // eslint-disable-next-line max-len
    const pathCanReset = (window.location.pathname === '/authorizedSession' || window.location.pathname === '/login' || window.location.pathname === '/organization' || window.location.pathname === '/' || window.location.pathname === '/promote');

    if (userId !== null && userId !== undefined && userId !== '' && userId !== 'null' && pathCanReset) {
      this.router.navigate(['/refresh-session']);
    } else {
      if (pathCanReset) {
        this.router.navigate(['/login']);
      }
    }
  }

  async setStatusBarColor() {
    if (this.isNative()) {
      await StatusBar.setBackgroundColor({color: '#6149cd'});
    }
  }

  async presentToast(
    message: string,
    duration: number,
    position: 'top' | 'middle' | 'bottom',
    cssClass: 'error-toast' | 'warning-toast' | 'success-toast' | 'information-toast' | '',
    icon: 'bug' | 'warning' | 'checkmark-circle' | 'information-circle' | '',

  ){
    const toast = await this.toastController.create({
      message,
      duration,
      position,
      cssClass,
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
          handler: () => {

          }
        }
      ],
      icon
    });

    await toast.present();

    return null;
  }

  private isNative(): boolean {
    return Capacitor.isNativePlatform();
  }
}
