/* eslint-disable jsdoc/no-types */
// External imports
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

//Internal services
import { AccountService } from '../services/account/account-service.service';

//Internal models
import { TicketedGuestObject, StrydEventTicketTierObject } from '../models/ticketing.model';
import { SelectedGuestDetailsObject } from '../models/guest-list.model';
import { VenuePaymentObject } from '../models/accounting.model';

//Internal enums
import { EditTierType } from '../enums/ticketing/edit-tier-type';

@Injectable()
export class AccountingProvider {
    //cashflow variables
    totalEventRevenue: number;
    totalEventExpenses: number;
    totalEventTaxes: number;
    totalEventProfit: number;

    //payout variables
    selectedPayout: VenuePaymentObject;

    constructor(
        public modalCtrl: ModalController,
        private accountService: AccountService
    ) { }

    /**
     * @param  {number} eventRevenue
     * @param  {number} eventExpenses
     * @param  {number} eventTax
     * @param  {number} eventProfit
     * This sets the individual cashflow values for the selected event
     */
    async setSelectedEventCashflowValues(eventRevenue: number, eventExpenses: number, eventTax: number, eventProfit: number) {
        this.totalEventRevenue = eventRevenue;
        this.totalEventExpenses = eventExpenses;
        this.totalEventTaxes = eventTax;
        this.totalEventProfit = eventProfit;
    }

    async getSelectedEventRevenue() {
        return this.totalEventRevenue;
    }

    async getSelectedEventExpenses() {
        return this.totalEventExpenses;
    }

    async getSelectedEventTax() {
        return this.totalEventTaxes;
    }

    async getSelectedEventProfit() {
        return this.totalEventProfit;
    }


    /**
     * @param  {number} eventRevenue
     * This sets the selected payout value
     */
    async setSelectedPayout(payout: VenuePaymentObject) {
        this.selectedPayout = payout;
    }

    async getSelectedPayout() {
        return this.selectedPayout;
    }
}
