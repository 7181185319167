//Angular Imports
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

//Ionic Imports
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

//Capacitor Imports

//Other External Imports
import { NgxEchartsModule } from 'ngx-echarts';
// import { TranslateModule } from '@ngx-translate/core';
import { QuillConfigModule } from 'ngx-quill/config';
import { ClipboardModule } from 'ngx-clipboard';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxFileDropModule } from 'ngx-file-drop';
import * as Sentry from '@sentry/capacitor';
import * as SentryAngular  from '@sentry/angular';


//Internal Components
import { AppComponent } from './app.component';

//Internal Modules
import { AppRoutingModule } from './app-routing.module';

//Internal Services
import { AccountService } from '../app/services/account/account-service.service';
import { AccountingService } from './services/accounting/accounting.service';
import { EventService } from '../app/services/events/event-service.service';
import { CampaignsService } from './services/campaigns/campaigns.service';
import { GuestListService } from './services/guest-list/guest-list.service';
import { TicketingService } from './services/ticketing/ticketing.service';
import { DashboardService } from './services/dashboard/dashboard.service';
import { NotificationsService } from './services/notifications/notifications.service';
import { OpenAiEventService } from './services/openai/event.service';
import { TablesService } from './services/tables/table-service.service';
import { TableReservationService } from './services/tables/table-reservation.service';
import { CrmService } from './services/crm/crm.service';
import { ChangeLogService } from './services/change-log/change-log.service';
import { DialogService } from './services/dialog-service/dialog-service.service';

//Internal Providers
import { GlobalAppProvider } from './app.provider';
import { EventProvider } from './providers/event.provider';
import { AddCampaignProvider } from './providers/add-campaign.provider';
import { CampaignAnalyticsProvider } from './providers/campaign-analytics.provider';
import { AccountProvider } from './providers/account.provider';
import { GuestListProvider } from './providers/guest-list.provider';
import { AddGuestListProvider } from './providers/add-guest-list.provider';
import { AddGuestListGuestProvider } from './providers/add-guest-list-guest.provider';
import { GuestListGuestProvider } from './providers/guest-list-guest.provider';
import { PromoteProvider } from './providers/promote.provider';
import { GuestCheckInProvider } from './providers/guest-checkin.provider';
import { TicketingProvider } from './providers/ticketing.provider';
import { AccountingProvider } from './providers/accounting.provider';
import { TablesProvider } from './providers/tables.provider';
import { CrmProvider } from './providers/crm.provider';
import { OrganizationProvider } from './providers/organization.provider';
import { DashboardProvider } from './providers/dashboard.provider';

//Other Internal Imports
import { environment } from '../environments/environment';

// Init by passing the sibling SDK's init as the second parameter.
Sentry.init({
    dsn: environment.sentryDsn,
    tracesSampleRate: environment.sentryTracesSampleRate,
    release: `phynx@${environment.appVersion}`,
    dist: environment.appDistVersion,
    environment: environment.sentryName,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    tracePropagationTargets: [
      'localhost',
      'http://23.119.176.134:2269',
      'http://23.119.176.134:2267',
      'http://23.119.176.134:2266',
      'https://web-phynx-dev-fe.azurewebsites.net',
      'https://web-tyx-dev-fe.azurewebsites.net',
      'https://web-tyx-dev-proxy-be.azurewebsites.net',
      'https://web-phynx-stage-fe.azurewebsites.net',
      'https://web-phynx-prod-fe.azurewebsites.net',
      'https://web-tyx-prod-fe.azurewebsites.net',
      'https://web-tyx-prod-proxy-be.azurewebsites.net',
      'https://phynx.live',
      'https://tyx.live'
    ],
    replaysSessionSampleRate: environment.sentryReplaysSessionSampleRate,
    replaysOnErrorSampleRate: environment.sentryReplaysOnErrorSampleRate,
  }, SentryAngular.init);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        HttpClientModule,
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        NgxEchartsModule.forRoot({
            /**
             * This will import all modules from echarts.
             * If you only need custom modules,
             * please refer to [Custom Build] section.
             */
            echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
        }),
        // AgmCoreModule.forRoot({
        //     apiKey: 'AIzaSyBhge3HEP6StkpVcUjYCSM5Zb8hTLwWhoE',
        //     libraries: ['places'],
        //     language: 'en'
        // }),
        IonicModule.forRoot({
            swipeBackEnabled: false
        }),
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        // TranslateModule.forRoot(),
        DragDropModule,
        QuillConfigModule.forRoot({
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                    //[], //blockquote: 'blockquote'
                    //[], // custom button values: { header: 1 }, { header: 2 }
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    //[], // superscript/subscript: { script: 'sub' }, { script: 'super' }
                    //[], // outdent/indent: { indent: '-1' }, { indent: '+1' }
                    //[], // text direction: { direction: 'rtl' }
                    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
                    //[], //headers: { header: [1, 2, 3, 4, 5, 6, false] }
                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    //[], //font family: { font: [] }
                    [{ align: [] }],
                    ['clean'] // remove formatting button
                ]
            },
            placeholder: 'Insert text here...',
            theme: 'snow'
        }),
        ClipboardModule,
        QRCodeModule,
        NgxFileDropModule
    ],
    providers: [
        //providers
        GlobalAppProvider,
        EventProvider,
        AddCampaignProvider,
        CampaignAnalyticsProvider,
        AccountProvider,
        GuestListProvider,
        AddGuestListProvider,
        AddGuestListGuestProvider,
        GuestListGuestProvider,
        PromoteProvider,
        GuestCheckInProvider,
        TicketingProvider,
        AccountingProvider,
        TablesProvider,
        CrmProvider,
        OrganizationProvider,
        DashboardProvider,

        //services
        AccountService,
        AccountingService,
        EventService,
        CampaignsService,
        GuestListService,
        TicketingService,
        DashboardService,
        NotificationsService,
        OpenAiEventService,
        TablesService,
        TableReservationService,
        CrmService,
        ChangeLogService,
        DialogService,

        //pipes
        CurrencyPipe,
        // GoogleMapsAPIWrapper,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: ErrorHandler,
            useValue: SentryAngular.createErrorHandler(),
        },
        {
            provide: SentryAngular.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [SentryAngular.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
