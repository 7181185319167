<form [formGroup]="individualGuestForm" (ngSubmit)="onIndividualGuestSubmit()" class="relative h-[calc(100%-110px)] w-full flex flex-col">
  <div class="flex flex-col justify-start items-start w-full h-full px-4 sm:px-6">
    <div class="w-full">
      <label for="combobox" class="block text-sm font-medium text-white">Guest List(s)</label>
      <div class="relative mt-1">
        <div class="absolute inset-y-0 left-0 flex items-center px-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-gray-400">
            <path fill-rule="evenodd" d="M6 4.75A.75.75 0 016.75 4h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 4.75zM6 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 10zm0 5.25a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75a.75.75 0 01-.75-.75zM1.99 4.75a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1v-.01zM1.99 15.25a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1v-.01zM1.99 10a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1V10z" clip-rule="evenodd" />
          </svg>          
        </div>
        <div class="w-full cursor-pointer" (click)="showListDropdown = !showListDropdown">
          <input id="combobox" type="text" [value]="joinedSelectedListNames" class="w-full pointer-events-none rounded-md border border-gray-300 bg-white py-2 pl-8 pr-12 shadow-sm focus:border-primary-default focus:outline-none focus:ring-1 focus:ring-primary-default sm:text-sm text-dark-default" role="combobox" aria-controls="options" aria-expanded="false" placeholder="Select Guest List(s)">
          <button type="button" class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <!-- Heroicon name: mini/chevron-up-down -->
            <svg class="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
    
        <ul *ngIf="showListDropdown" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" id="options" role="listbox">
  
          <li *ngFor="let list of eventGuestLists; let i = index;" (click)="onListClicked(list)" class="relative cursor-pointer hover:bg-primary-default select-none py-2 pl-8 pr-4 text-gray-900 hover:text-white group" id="option-0" role="option" tabindex="-1">
  
            <span class="block truncate" [ngClass]="{'font-semibold': list.selected}">{{list.name}} ({{list.venueQuantityRemaining}} spots remaining)</span>
    
            <span *ngIf="list.selected" class="absolute inset-y-0 right-0 flex items-center pr-4">
              <!-- Heroicon name: mini/check -->
              <svg class="h-5 w-5 text-primary-500 group-hover:text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
            </span>
  
          </li>
    
        </ul>
      </div>
    </div>
  
  
    <div class="flex flex-row space-x-2 w-full mt-2" (click)="closeDropdowns()">
      <div class="w-full">
        <label for="phone-number" class="block text-sm font-medium text-white">Phone Number</label>
        <div class="relative mt-1 rounded-md shadow-sm">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <!-- Heroicon name: mini/phone -->
            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z" clip-rule="evenodd" />
            </svg>            
          </div>
          <input type="tel" name="phone-number" id="phone-number" formControlName="phoneNumber" autocomplete="new-password" class="block w-full rounded-md border-gray-300 pl-10 focus:border-primary-default focus:ring-primary-default sm:text-sm text-dark-default" placeholder="(555) 555-5555">
        </div>
      </div>
  
      <div class="w-full">
        <label for="email" class="block text-sm font-medium text-white">Email</label>
        <div class="relative mt-1 rounded-md shadow-sm">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <!-- Heroicon name: mini/envelope -->
            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
              <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
            </svg>
          </div>
          <input type="email" name="email" id="email" formControlName="email" autocomplete="new-password" class="block w-full rounded-md border-gray-300 pl-10 focus:border-primary-default focus:ring-primary-default sm:text-sm text-dark-default" placeholder="you@example.com">
        </div>
      </div>
    </div>
  
  
    <div class="flex flex-row space-x-2 w-full mt-2" (click)="closeDropdowns()">
      <div class="w-full">
        <div class="w-full flex justify-between items-center pr-2">
          <label for="first-name" class="block text-sm font-medium text-white">First Name</label>
          <p class="text-danger-400 font-normal text-sm">required</p>
        </div>
        <div class="relative mt-1 rounded-md shadow-sm">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <!-- Heroicon name: mini/user -->
            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
            </svg>            
          </div>
          <input type="text" name="first-name" id="first-name" formControlName="firstName" autocomplete="new-password" class="block w-full rounded-md border-gray-300 pl-10 focus:border-primary-default focus:ring-primary-default sm:text-sm text-dark-default" placeholder="John">
        </div>
      </div>
  
      <div class="w-full">
        <div class="w-full flex justify-between items-center pr-2">
          <label for="last-name" class="block text-sm font-medium text-white">Last Name</label>
          <p class="text-danger-400 font-normal text-sm">required</p>
        </div>
        <div class="relative mt-1 rounded-md shadow-sm">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <!-- Heroicon name: mini/user -->
            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
            </svg>
          </div>
          <input type="text" name="last-name" id="last-name" formControlName="lastName" autocomplete="new-password" class="block w-full rounded-md border-gray-300 pl-10 focus:border-primary-default focus:ring-primary-default sm:text-sm text-dark-default" placeholder="Doe">
        </div>
      </div>
    </div>
  
  
    <div class="flex flex-row space-x-2 w-full mt-2" (click)="showListDropdown = false">
      <div class="w-full">
        <div class="w-full flex justify-between items-center pr-2">
          <label for="combobox" class="block text-sm font-medium text-white">Gender</label>
          <!-- <p class="text-danger-400 font-normal text-sm">required</p> -->
        </div>
        <div class="relative mt-1">
          <div class="absolute inset-y-0 left-0 flex items-center px-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M1 6a3 3 0 013-3h12a3 3 0 013 3v8a3 3 0 01-3 3H4a3 3 0 01-3-3V6zm4 1.5a2 2 0 114 0 2 2 0 01-4 0zm2 3a4 4 0 00-3.665 2.395.75.75 0 00.416 1A8.98 8.98 0 007 14.5a8.98 8.98 0 003.249-.604.75.75 0 00.416-1.001A4.001 4.001 0 007 10.5zm5-3.75a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75zm0 6.5a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75zm.75-4a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5h-2.5z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="w-full cursor-pointer" (click)="showSelectGender = !showSelectGender">
            <input id="combobox" type="text" [value]="selectedGender" [disabled]="true" class="w-full pointer-events-none rounded-md border border-gray-300 bg-white py-2 pl-8 pr-12 shadow-sm focus:border-primary-default focus:outline-none focus:ring-1 focus:ring-primary-default sm:text-sm text-dark-default" role="combobox" aria-controls="options" aria-expanded="false" placeholder="Select Gender">
            <button type="button" class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <!-- Heroicon name: mini/chevron-up-down -->
              <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
  
          <ul *ngIf="showSelectGender" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" id="options" role="listbox">
  
            <li *ngFor="let gender of genders; let i = index;" (click)="selectGender(gender)" class="relative cursor-pointer hover:bg-primary-default select-none py-2 pl-3 pr-9 text-gray-900 hover:text-white" id="option-0" role="option" tabindex="-1">
  
              <span class="block truncate" [ngClass]="{'font-semibold': gender.internalName === selectedGender}">{{gender.commonName}}</span>
  
              <span *ngIf="gender.internalName === selectedGender" class="absolute inset-y-0 right-0 flex items-center pr-4 text-primary-default">
                <!-- Heroicon name: mini/check -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                </svg>
              </span>
            </li>
  
          </ul>
        </div>
      </div>
  
      <div class="w-full">
        <label for="total-additional-guests" class="block text-sm font-medium text-white">Total Additional Guests</label>
        <div class="relative mt-1 rounded-md shadow-sm">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <!-- Heroicon name: mini/user-plus -->
            <svg class="w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M11 5a3 3 0 11-6 0 3 3 0 016 0zM2.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 018 18a9.953 9.953 0 01-5.385-1.572zM16.25 5.75a.75.75 0 00-1.5 0v2h-2a.75.75 0 000 1.5h2v2a.75.75 0 001.5 0v-2h2a.75.75 0 000-1.5h-2v-2z" />
            </svg>              
          </div>
          <input type="number" name="total-additional-guests" id="total-additional-guests" formControlName="totalAdditionalGuests" autocomplete="new-password" class="block w-full rounded-md border-gray-300 pl-10 focus:border-primary-default focus:ring-primary-default sm:text-sm text-dark-default" placeholder="123...">
        </div>
      </div>

    </div>

    <!-- access levels -->
    <div class="flex flex-row space-x-2 w-full mt-2" (click)="showListDropdown = false">
      <div class="w-full">
        <label for="combobox" class="block text-sm font-medium leading-6 text-white">Access Levels</label>
        <div class="relative mt-2">
          <div class="w-full relative" (click)="showAccessLevelDropDown = !showAccessLevelDropDown">
            <input id="combobox" type="text" formControlName="selectedAccessLevels" placeholder="No Access Level" autocomplete="new-password" class="w-full pointer-events-none rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" role="combobox" aria-controls="options" aria-expanded="false">
            <button type="button" class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
      
          <ul *ngIf="showAccessLevelDropDown" class="absolute z-10 mt-1 max-h-36 w-full overflow-y-scroll overflow-x-hidden rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" id="options" role="listbox">
            <!--
              Combobox option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.
      
              Active: "text-white bg-indigo-600", Not Active: "text-gray-900"
            -->
            <li *ngFor="let accessLevel of existingAccessLevels; let i = index;" class="relative cursor-default select-none py-2 pl-3 pr-9" [ngClass]="{'text-white bg-primary-600': accessLevel.selected, 'text-gray-900': !accessLevel.selected}" id="option-0" role="option" tabindex="-1" (click)="selectAccessLevel(accessLevel)">
              <!-- Selected: "font-semibold" -->
              <span class="block truncate">{{accessLevel.name}}</span>
      
              <!--
                Checkmark, only display for selected option.
      
                Active: "text-white", Not Active: "text-indigo-600"
              -->
              <span *ngIf="accessLevel.selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-white">
                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                </svg>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  
  
    <div class="flex flex-row space-x-2 w-full mt-2" (click)="closeDropdowns()">
      <div class="w-full">
        <label for="additional-notes" class="block text-sm font-medium text-white">Notes</label>
        <div class="mt-1">
          <textarea rows="4" name="additional-notes" id="additional-notes" formControlName="notes" autocomplete="new-password" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-default focus:ring-primary-default sm:text-sm text-dark-default"></textarea>
        </div>
      </div>
    </div>
  </div>

  <!-- Action buttons -->
  <div class="flex-shrink-0 border-t border-gray-900 px-4 py-5 sm:px-6 sticky bottom-0 left-0 w-full bg-white dark:bg-dark-700">
    <div class="flex justify-start items-center space-x-4">
      <button type="button" class="rounded-md bg-white py-3 px-3 w-full text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" (click)="closeAddGuestListGuest()">Close</button>
      <button type="submit" [disabled]="!individualGuestForm.valid" class="rounded-md bg-primary-200 ring-primary-300 hover:bg-primary-300 text-primary-800 py-3 px-3 w-full text-sm font-semibold disabled:bg-gray-600 hover:disabled:bg-gray-700 disabled:text-white shadow-sm ring-1 ring-inset">Add Guest</button>
    </div>
  </div>

</form>
