import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

//Internal providers
import { AccountProvider } from 'src/app/providers/account.provider';

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss'],
})
export class AddEmployeeComponent implements OnInit, OnDestroy {
  //internal display variables
  selectedModalStep: number;

  //subscription variables
  private addEmployeeModalSubscription: Subscription;

  constructor(
    private accountProvider: AccountProvider
  ) { }

  /**
   * @task1 get the current add employee modal step and subscribe to the add employee modal step observable
   */
  ngOnInit() {
    //task 1
    this.selectedModalStep = this.accountProvider.getAddEmployeeModalStep();

    this.addEmployeeModalSubscription = this.accountProvider.addEmployeeModalStepObservable
      .subscribe((step: number) => {
        this.selectedModalStep = step;
      });
  }

  ngOnDestroy() {
    if (this.addEmployeeModalSubscription) {
      this.addEmployeeModalSubscription.unsubscribe();
    }
  }
}
