//Angular imports
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

//Ionic imports
import { ModalController } from '@ionic/angular';

//Internal models
import { GuestListGuestObject } from '../models/guest-list.model';

//Internal components
import { GuestDetailsComponent } from '../components/guest-list/guest-details/guest-details.component';


@Injectable()
export class GuestListGuestProvider {
  //search guests dropdown variables
  showSearchedGuestsDropdownObservable = new Subject();
  showSearchedGuestsDropdown: boolean;

  //view guest modal
  viewGuestModal: any;
  viewGuestModalIsOpenObservable = new Subject();
  viewGuestModalIsOpen: boolean;
  currentlyViewingGuest: GuestListGuestObject;

  //guest updated variables
  additionalGuestsUpdatedObservable = new Subject();

  constructor(
    public modalCtrl: ModalController
  ) { }

  // eslint-disable-next-line
  /**
   * @param  {boolean} showDropdown
   * This sets the searched guests dropdown variable and triggers the guests list dropdown Subject so that all observables fire off
   */
  async setShowSearchedGuestDropdown(showDropdown: boolean) {
    this.showSearchedGuestsDropdownObservable.next(showDropdown);
    this.showSearchedGuestsDropdown = showDropdown;
  }


  /**
   * @summary this configures and presents the view guest modal
   */
   async presentViewGuestModal(guest: GuestListGuestObject){
    this.currentlyViewingGuest = guest;

    this.viewGuestModal = await this.modalCtrl.create({
        breakpoints: [0.25, 0.5, 0.75, 1],
        initialBreakpoint: 1,
        cssClass: 'viewGuestModal',
        component: GuestDetailsComponent
    });

    await this.viewGuestModal.present();

    this.viewGuestModalIsOpen = true;
    this.viewGuestModalIsOpenObservable.next(true);

    this.viewGuestModal.onDidDismiss().then((res) => {
        this.resetAllVariables();
    });
  }

  async dismissViewGuestModal() {
    await this.resetAllVariables().then(async () => {
      await this.viewGuestModal.dismiss().then(() => {
        this.viewGuestModalIsOpen = false;
        this.viewGuestModalIsOpenObservable.next(false);
      });
    });
  }

  async getSelectedGuest(): Promise<GuestListGuestObject> {
    return this.currentlyViewingGuest;
  }

  // eslint-disable-next-line
  /**
   * @param  {boolean} showDropdown
   * This sets additional guests updated Subject so that all observables fire off
   */
   async setAdditionalGuestsUpdated(additionalGuestsUpdated: boolean) {
    this.additionalGuestsUpdatedObservable.next(additionalGuestsUpdated);
  }

  async resetAllVariables() {
    this.currentlyViewingGuest = undefined;
  }
}
