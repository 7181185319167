export enum CampaignType {
    link                = 1,    // Displayed in Promoter App; for general sharing outside of defined Campaigns
    facebook            = 2,    // Share on Facebook from Promoter App
    twitter             = 3,    // Share on Twitter from Promoter App
    instagram           = 4,    // Share on Instagram from Promoter App
    promoterQR          = 5,    // Displayed on Promoter App
    promoterSMS         = 6,    // To send an SMS with the Event URL
    location            = 7,    // Literal Handbills may not include a ReferralCode
    posterCode          = 8,    // Find the Event online, then use this code
    posterQR            = 9,    // QR Code displayed on Posters
    posterSMS           = 10,   // (POST MVP) Sent in response to SMS request
    totalTicketSales    = 11,   // Total Ticket Sales (not an available CampaignType for Promoters)
    widget              = 12,   // Widget on Venue's website
    upcomingEventForm   = 13,   // Form on Venue's website to collect contacts for upcoming event dates
    venueWebsiteLink    = 14,   // Link on Venue's website calendar to each Event in Tyx
    venueQR             = 15,   // QR Code for Venue to each Event in Tyx
    promoterLink        = 16,   // Link for Promoter's to each Event in Tyx
}
