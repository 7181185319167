<div class="relative h-[calc(100%-110px)] w-full flex flex-col">
  <div class="flex flex-col justify-start items-start w-full h-full px-4 sm:px-6 pb-24">
    <div class="w-full">
      <label for="combobox" class="block text-sm font-medium text-white">Guest List(s)</label>
      <div class="relative mt-1">
        <div class="absolute inset-y-0 left-0 flex items-center px-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-gray-400">
            <path fill-rule="evenodd" d="M6 4.75A.75.75 0 016.75 4h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 4.75zM6 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 10zm0 5.25a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75a.75.75 0 01-.75-.75zM1.99 4.75a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1v-.01zM1.99 15.25a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1v-.01zM1.99 10a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1V10z" clip-rule="evenodd" />
          </svg>          
        </div>
        <div class="w-full cursor-pointer" (click)="showListDropdown = !showListDropdown">
          <input id="combobox" type="text" [value]="joinedSelectedListNames" class="w-full pointer-events-none rounded-md border border-gray-300 bg-white py-2 pl-8 pr-12 shadow-sm focus:border-primary-default focus:outline-none focus:ring-1 focus:ring-primary-default sm:text-sm text-dark-default" role="combobox" aria-controls="options" aria-expanded="false" placeholder="Select Guest List(s)">
          <button type="button" class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <!-- Heroicon name: mini/chevron-up-down -->
            <svg class="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
  
        <ul *ngIf="showListDropdown" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" id="options" role="listbox">
  
          <li *ngFor="let list of eventGuestLists; let i = index;" (click)="onListClicked(list)" class="relative cursor-pointer hover:bg-primary-default select-none py-2 pl-8 pr-4 text-gray-900 hover:text-white group" id="option-0" role="option" tabindex="-1">
  
            <span class="block truncate" [ngClass]="{'font-semibold': list.selected}">{{list.name}} ({{list.venueQuantityRemaining}} spots remaining)</span>
    
            <span *ngIf="list.selected" class="absolute inset-y-0 right-0 flex items-center pr-4">
              <!-- Heroicon name: mini/check -->
              <svg class="h-5 w-5 text-primary-500 group-hover:text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
              </svg>
            </span>
  
          </li>
    
        </ul>
      </div>
    </div>
  
    <div *ngIf="guests === undefined || guests === null || guests === []" class="flex flex-col justify-center items-center space-y-4 w-full mt-4">
      <!-- <label for="dropzone-file" class="flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
        <div class="flex flex-col justify-center items-center pt-5 pb-6">
          <svg aria-hidden="true" class="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
          <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> XLSX or XLS Only</p>
        </div>
        <input id="dropzone-file" type="file" accept=".xlsx" class="hidden" (change)="onFileChange($event)" />
      </label> -->
      <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="onFileDropped($event)">
          <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            Drag and drop your file or
            <button type="button" class="text-primary-400 hover:text-primary-500 mx-1" (click)="openFileSelector()">click here</button>
            to browse for it.
          </ng-template>
      </ngx-file-drop>
      <a class="px-6 py-2 bg-primary-500 text-white rounded-lg shadow-sm shadow-dark-800 cursor-pointer hover:bg-primary-600" href="https://stryd-stock-images.s3.us-west-1.amazonaws.com/bulkUploadTemplate.xlsx" download>
        <label class="cursor-pointer">
          <span class="cursor-pointer">Download Template</span>
        </label>
      </a>
    </div>
  
    <div *ngIf="guests !== undefined && guests !== null && guests !== []" class="w-full h-full flex flex-col space-y-4 justify-start items-center pt-10 text-center" (click)="showListDropdown = false">
      <div class="w-20 min-w-[80px] h-20 min-h-[80px] rounded-full bg-secondary-200 flex justify-center items-center text-left">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-secondary-800">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
        </svg>      
      </div>
      <p class="text-xl mt-4 px-20" *ngIf="!submissionContainsErrors">
          {{totalGuests}} guests are ready to be uploaded. Press the Add Guests button below to continue.
      </p>

      <div class="w-full h-full mt-4 flex flex-col justify-start items-start overflow-y-scroll overflow-x-hidden scroll-hidden text-left">
        <button type="button" class="w-full text-sm font-semibold py-1.5 text-white bg-gray-600 hover:bg-gray-700 rounded-lg ring-primary-300 ring-1 ring-inset shadow-sm" *ngIf="!showAccessLevels" (click)="addAccessLevels()">
          Add Access Levels
        </button>
  
        <div *ngIf="showAccessLevels" class="w-full h-full flex flex-col justify-start items-start relative">
          <div class="w-full flex justify-start items-center sticky top-0 bg-dark-500 pb-2">
            <div class="w-full">
              <label for="combobox" class="block text-sm font-medium leading-6 text-white">Access Levels</label>
              <div class="relative mt-2">
                <div class="w-full flex justify-start items-center space-x-3">
                  <div class="w-full relative" (click)="showAccessLevelDropDown = !showAccessLevelDropDown">
                    <input id="combobox" type="text" formControlName="selectedAccessLevels" autocomplete="new-password" placeholder="No Access Level" class="w-full pointer-events-none rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" role="combobox" aria-controls="options" aria-expanded="false">
                    <button type="button" class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                      <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                      </svg>
                    </button>
                  </div>
                  <p (click)="assignAccessLevelsToAll()" class="text-sm text-white hover:text-gray-200 cursor-pointer font-normal whitespace-nowrap">Apply All</p>
                </div>
            
                <ul *ngIf="showAccessLevelDropDown" class="absolute z-10 mt-1 max-h-36 w-full overflow-y-scroll overflow-x-hidden rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" id="options" role="listbox">
                  <!--
                    Combobox option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.
            
                    Active: "text-white bg-indigo-600", Not Active: "text-gray-900"
                  -->
                  <li *ngFor="let accessLevel of existingAccessLevels; let i = index;" class="relative cursor-default select-none py-2 pl-3 pr-9" [ngClass]="{'text-white bg-primary-600': accessLevel.selected, 'text-gray-900': !accessLevel.selected}" id="option-0" role="option" tabindex="-1" (click)="selectAccessLevel(accessLevel)">
                    <!-- Selected: "font-semibold" -->
                    <span class="block truncate">{{accessLevel.name}}</span>
            
                    <!--
                      Checkmark, only display for selected option.
            
                      Active: "text-white", Not Active: "text-indigo-600"
                    -->
                    <span *ngIf="accessLevel.selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-white">
                      <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                      </svg>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            
          </div>
  
          <div class="w-full px-4 flex justify-between items-center mt-4 mb-2">
            <!-- <p class="text-white font-normal text-base">Name</p> -->
            <p></p>
            <p class="text-white font-normal text-base border-b-2 border-white">Access Levels</p>
          </div>
          
          <div class="w-full flex flex-col justify-start items-start divide-y divide-gray-500">
            <div *ngFor="let guest of guests; let i = index;" (click)="assignAccessControl(guest)" class="w-full px-4 py-2 flex justify-between items-center hover:rounded-lg hover:bg-dark-800 cursor-pointer">
              <p class="text-white font-normal text-base">{{guest.firstName}} {{guest.lastName}}</p>
              <p class="text-white font-normal text-base">{{guest.selectedAccessLevelName || 'No Access'}}</p>
            </div>
          </div>
  
          <div class="w-full h-full min-h-[190px]" (click)="showAccessLevelDropDown = false"></div>
        </div>
      </div>
    </div>
  </div>

  <!-- Action buttons -->
  <div class="flex-shrink-0 border-t border-gray-900 px-4 py-5 sm:px-6 sticky bottom-0 left-0 w-full bg-white dark:bg-dark-700">
    <div class="flex justify-start items-center space-x-4">
      <button type="button" class="rounded-md bg-white py-3 px-3 w-full text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" (click)="closeAddGuestListGuest()">Close</button>
      <button type="button" *ngIf="!submissionContainsErrors && hasGuests" [disabled]="selectedListId === null || selectedListId === undefined" (click)="submitExcelUpload()" class="rounded-md bg-primary-200 ring-primary-300 hover:bg-primary-300 text-primary-800 py-3 px-3 w-full text-sm font-semibold disabled:bg-gray-600 hover:disabled:bg-gray-700 disabled:text-white shadow-sm ring-1 ring-inset">Add Guests</button>
      <button type="button" *ngIf="submissionContainsErrors || !hasGuests" (click)="clearErrors()" class="rounded-md bg-primary-200 ring-primary-300 hover:bg-primary-300 text-primary-800 py-3 px-3 w-full text-sm font-semibold disabled:bg-gray-600 hover:disabled:bg-gray-700 disabled:text-white shadow-sm ring-1 ring-inset">Reset</button>
    </div>
  </div>

</div>
