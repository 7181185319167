// External imports
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

//Internal services
import { AccountService } from '../services/account/account-service.service';

//Internal models
// eslint-disable-next-line max-len
import { EventFloorPlanObject, EventFloorPlanTableObject, TableReservationObject, VenueFloorPlanObject, VenueFloorPlanSectionObject, VenueFloorPlanTableObject, EventTableReservationDetailsObject } from '../models/table-service.model';

@Injectable()
export class TablesProvider {
    //default floor plan modal variables
    showDefaultFloorPlanModalObservable = new Subject();
    showDefaultFloorPlanModal: boolean;

    //add venue floor plan variables
    newVenueFloorPlanAddedObservable = new Subject();
    showAddVenueFloorPlanObservable = new Subject();
    showAddVenueFloorPlan: boolean;
    isEditVenueFloorPlan: boolean;
    editingVenueFloorPlan: VenueFloorPlanObject;

    //selected venue floor plan variables
    selectedVenueFloorPlan: VenueFloorPlanObject;
    selectedVenueFloorPlanObservable = new Subject();

    //selected event floor plan variables
    selectedEventFloorPlan: EventFloorPlanObject;
    selectedEventFloorPlanObservable = new Subject();

    //add venue floor plan section variables
    newVenueFloorPlanSectionAddedObservable = new Subject();
    showAddVenueFloorPlanSectionObservable = new Subject();
    showAddVenueFloorPlanSection: boolean;
    isEditVenueFloorPlanSection: boolean;
    editingVenueFloorPlanSection: VenueFloorPlanSectionObject;

    //add event floor plan section variables
    newEventFloorPlanSectionAddedObservable = new Subject();
    showAddEventFloorPlanSectionObservable = new Subject();
    showAddEventFloorPlanSection: boolean;

    //add venue floor plan table variables
    newVenueFloorPlanTableAddedObservable = new Subject();
    showAddVenueFloorPlanTableObservable = new Subject();
    showAddVenueFloorPlanTable: boolean;

    //add event floor plan table variables
    newEventFloorPlanTableAddedObservable = new Subject();
    showAddEventFloorPlanTableObservable = new Subject();
    showAddEventFloorPlanTable: boolean;
    isEditingEventFloorPlanTable: boolean;
    editingEventFloorPlanTable: EventFloorPlanTableObject;

    //view venue floor plan sections variables
    showViewVenueFloorPlanSectionTablesObservable = new Subject();
    showViewVenueFloorPlanSectionTables: boolean;

    //view venue floor plan sections variables
    showViewVenueFloorPlanSectionsObservable = new Subject();
    showViewVenueFloorPlanSections: boolean;

    //view event floor plan section tables variables
    showViewEventFloorPlanSectionTablesObservable = new Subject();
    showViewEventFloorPlanSectionTables: boolean;

    //view event floor plan sections variables
    showViewEventFloorPlanSectionsObservable = new Subject();
    showViewEventFloorPlanSections: boolean;

    //show view table reservation variables
    viewingTableReservationSelectedTable: EventFloorPlanTableObject;
    viewingTableReservationSelectedTableObservable = new Subject();
    showViewTableReservationObservable = new Subject();
    showViewTableReservation: boolean;
    viewingTableReservationIsConfirmed: boolean;

    //table reservation variables
    selectedTableReservation: TableReservationObject;
    selectedTableReservationObservable = new Subject();

    //show add table reservation variables
    showAddTableReservationObservable = new Subject();
    showAddTableReservation: boolean;
    addTableReservationSelectedTable: EventFloorPlanTableObject;

    //show add stand-by reservation variables
    showAddStandbyReservationObservable = new Subject();
    showAddStandbyReservation: boolean;

    //show manage standby reservation variables
    showManageStandbyReservationObservable = new Subject();
    showManageStandbyReservation: boolean;
    selectedStandbyReservation: TableReservationObject;

    //view enable event floor plans variables
    showEnableEventFloorPlansObservable = new Subject();
    showEnableEventFloorPlans: boolean;

    //table server assigned variables
    newTableServerAssignedObservable = new Subject();

    //table minimum updated variables
    tableMinimumUpdatedObservable = new Subject();

    //table actions modal variables
    tableActionsModalOpenObservable = new Subject();

    //view venue floor plan promoter payments variables
    showVenueFloorPlanPromoterPaymentsObservable = new Subject();
    showVenueFloorPlanPromoterPayments: boolean;

    //view event floor plan promoter payments variables
    showEventFloorPlanPromoterPaymentsObservable = new Subject();
    showEventFloorPlanPromoterPayments: boolean;

    //view upcoming event reservations variables
    showUpcomingEventReservationsObservable = new Subject();
    showUpcomingEventReservations: boolean;

    //all reservations variables
    allReservations: EventTableReservationDetailsObject[];
    allReservationsObservable = new Subject();
    selectReservationUpdatedObservable = new Subject();

    constructor(
        public modalCtrl: ModalController,
        private accountService: AccountService
    ) { }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showModal
     * This sets the default floor plan modal variable and triggers the respective Subject so that all observables fire off
     */
    async setShowDefaultFloorPlanModal(showModal: boolean) {
        this.showDefaultFloorPlanModalObservable.next(showModal);
        this.showDefaultFloorPlanModal = showModal;
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This sets the add venue floor plan slide-out variable and triggers the respective Subject so that all observables fire off
     */
    async setShowAddVenueFloorPlan(showSlideOut: boolean, isEdit: boolean = false, venueFloorPlan?: VenueFloorPlanObject) {
        if (isEdit) {
            this.isEditVenueFloorPlan = isEdit;
            this.editingVenueFloorPlan = venueFloorPlan;
        }

        this.showAddVenueFloorPlan = showSlideOut;
        this.showAddVenueFloorPlanObservable.next(showSlideOut);
    }
    async setNewVenueFloorPlanAdded(newFloorPlanAdded: boolean) {
        this.newVenueFloorPlanAddedObservable.next(newFloorPlanAdded);
    }
    async setIsEditVenueFloorPlan(isEditing: boolean) {
        this.isEditVenueFloorPlan = isEditing;
    }
    async getIsEditVenueFloorPlan(): Promise<boolean> {
        return this.isEditVenueFloorPlan;
    }
    async getEditingVenueFloorPlan(): Promise<VenueFloorPlanObject> {
        return this.editingVenueFloorPlan;
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} venueFloorPlan
     * This sets the venue floor plan variable and triggers the respective Subject so that all observables fire off
     */
    async setSelectedVenueFloorPlan(venueFloorPlan: VenueFloorPlanObject) {
        this.selectedVenueFloorPlanObservable.next(venueFloorPlan);
        this.selectedVenueFloorPlan = venueFloorPlan;
    }
    async getSelectedVenueFloorPlan(): Promise<VenueFloorPlanObject> {
        return this.selectedVenueFloorPlan;
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} eventFloorPlan
     * This sets the event floor plan variable and triggers the respective Subject so that all observables fire off
     */
    async setSelectedEventFloorPlan(eventFloorPlan: EventFloorPlanObject) {
        this.selectedEventFloorPlanObservable.next(eventFloorPlan);
        this.selectedEventFloorPlan = eventFloorPlan;
    }
    async getSelectedEventFloorPlan(): Promise<EventFloorPlanObject> {
        return this.selectedEventFloorPlan;
    }



    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This sets the add venue floor plan section slide-out variable and triggers the respective Subject so that all observables fire off
     */
    // eslint-disable-next-line max-len
    async setShowAddVenueFloorPlanSection(showSlideOut: boolean, isEdit: boolean = false, venueFloorPlanSection?: VenueFloorPlanSectionObject) {
        if (isEdit) {
            this.isEditVenueFloorPlanSection = isEdit;
            this.editingVenueFloorPlanSection = venueFloorPlanSection;
        } else {
            this.isEditVenueFloorPlanSection = false;
            this.editingVenueFloorPlanSection = null;
        }

        this.showAddVenueFloorPlanSectionObservable.next(showSlideOut);
        this.showAddVenueFloorPlanSection = showSlideOut;
    }
    async setNewVenueFloorPlanSectionAdded(newSectionAdded: boolean) {
        this.newVenueFloorPlanSectionAddedObservable.next(newSectionAdded);
    }
    async getIsEditVenueFloorPlanSection(): Promise<boolean> {
        return this.isEditVenueFloorPlanSection;
    }
    async getEditingVenueFloorPlanSection(): Promise<VenueFloorPlanSectionObject> {
        return this.editingVenueFloorPlanSection;
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This sets the add venue floor plan section slide-out variable and triggers the respective Subject so that all observables fire off
     */
    async setShowAddEventFloorPlanSection(showSlideOut: boolean) {
        this.showAddEventFloorPlanSectionObservable.next(showSlideOut);
        this.showAddEventFloorPlanSection = showSlideOut;
    }
    async setNewEventFloorPlanSectionAdded(newSectionAdded: boolean) {
        this.newEventFloorPlanSectionAddedObservable.next(newSectionAdded);
    }


    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This sets the add venue floor plan table slide-out variable and triggers the respective Subject so that all observables fire off
     */
    async setShowAddVenueFloorPlanTable(showSlideOut: boolean, isEditing: boolean, editingTable: VenueFloorPlanTableObject) {
        this.showAddVenueFloorPlanTableObservable.next({showSlideOut, isEditing, editingTable});
        this.showAddVenueFloorPlanTable = showSlideOut;
    }
    async setNewVenueFloorPlanTableAdded(newTableAdded: boolean) {
        this.newVenueFloorPlanTableAddedObservable.next(newTableAdded);
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This sets the add event floor plan table slide-out variable and triggers the respective Subject so that all observables fire off
     */
    async setShowAddEventFloorPlanTable(showSlideOut: boolean, isEditing: boolean, editingTable: EventFloorPlanTableObject) {
        this.showAddEventFloorPlanTableObservable.next({showSlideOut, isEditing, editingTable});
        this.showAddEventFloorPlanTable = showSlideOut;
        this.isEditingEventFloorPlanTable = isEditing;
        this.editingEventFloorPlanTable = editingTable;
    }
    async getShowAddEventFloorPlanTable() {
        return {
            showAddEventFloorPlanTable: this.showAddEventFloorPlanTable,
            isEditingEventFloorPlanTable: this.isEditingEventFloorPlanTable,
            editingEventFloorPlanTable: this.editingEventFloorPlanTable
        };
    }
    async setNewEventFloorPlanTableAdded(newTableAdded: boolean) {
        this.newEventFloorPlanTableAddedObservable.next(newTableAdded);
    }


    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This sets the add venue floor plan section table slide-out variable and triggers the respective Subject
     * so that all observables fire off
     */
    async setShowViewVenueFloorPlanSectionTables(showSlideOut: boolean) {
        this.showViewVenueFloorPlanSectionTablesObservable.next(showSlideOut);
        this.showViewVenueFloorPlanSectionTables = showSlideOut;
    }
    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This sets the add venue floor plan sections slide-out variable and triggers the respective Subject so that all observables fire off
     */
    async setShowViewVenueFloorPlanSections(showSlideOut: boolean) {
        this.showViewVenueFloorPlanSectionsObservable.next(showSlideOut);
        this.showViewVenueFloorPlanSections = showSlideOut;
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This sets the view event floor plan section tables slide-out variable and triggers the respective Subject
     * so that all observables fire off
     */
    async setShowViewEventFloorPlanSectionTables(showSlideOut: boolean) {
        this.showViewEventFloorPlanSectionTablesObservable.next(showSlideOut);
        this.showViewEventFloorPlanSectionTables = showSlideOut;
    }
    async getShowViewEventFloorPlanSectionTables(): Promise<boolean> {
        return this.showViewEventFloorPlanSectionTables;
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This sets the view event floor plan section tables slide-out variable and triggers the respective Subject
     * so that all observables fire off
     */
    async setShowViewEventFloorPlanSections(showSlideOut: boolean) {
        this.showViewEventFloorPlanSectionsObservable.next(showSlideOut);
        this.showViewEventFloorPlanSections = showSlideOut;
    }
    async getShowViewEventFloorPlanSections(): Promise<boolean> {
        return this.showViewEventFloorPlanSections;
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This sets the view table reservation slide-out variable and triggers the respective Subject
     * so that all observables fire off
     */
    async setShowViewTableReservation(showSlideOut: boolean, reservationIsConfirmed: boolean, eventTable: EventFloorPlanTableObject) {
        this.showViewTableReservation = showSlideOut;
        this.viewingTableReservationIsConfirmed = reservationIsConfirmed;
        this.viewingTableReservationSelectedTable = eventTable;
        this.showViewTableReservationObservable.next(showSlideOut);
    }
    async getViewTableReservationSelectedTable(): Promise<EventFloorPlanTableObject> {
        return this.viewingTableReservationSelectedTable;
    }
    async getViewTableReservationIsConfirmed(): Promise<boolean> {
        return this.viewingTableReservationIsConfirmed;
    }
    async setViewingTableReservationTable(eventTable: EventFloorPlanTableObject) {
        this.viewingTableReservationSelectedTable = eventTable;
        this.viewingTableReservationSelectedTableObservable.next(eventTable);
    }

    // eslint-disable-next-line
    /**
     * @param  {TableReservationObject} tableReservation
     * This sets the selected table reservation variable and triggers the respective Subject
     * so that all observables fire off
     */
    async setSelectedTableReservation(tableReservation: TableReservationObject) {
        this.selectedTableReservation = tableReservation;
        this.selectedTableReservationObservable.next(tableReservation);
    }
    async getSelectedTableReservation(): Promise<TableReservationObject> {
        return this.selectedTableReservation;
    }

    async setSelectedTableReservationCheckedInMaleGuests(checkedInMaleGuests: number) {
        this.selectedTableReservation.checkedInMaleGuests = checkedInMaleGuests;
    }
    async getSelectedTableReservationCheckedInMaleGuests(): Promise<number> {
        return this.selectedTableReservation.checkedInMaleGuests;
    }
    async setSelectedTableReservationCheckedInFemaleGuests(checkedInFemaleGuests: number) {
        this.selectedTableReservation.checkedInFemaleGuests = checkedInFemaleGuests;
    }
    async getSelectedTableReservationCheckedInFemaleGuests(): Promise<number> {
        return this.selectedTableReservation.checkedInFemaleGuests;
    }
    async setSelectedTableReservationCheckedInOtherGuests(checkedInOtherGuests: number) {
        this.selectedTableReservation.checkedInOtherGuests = checkedInOtherGuests;
    }
    async getSelectedTableReservationCheckedInOtherGuests(): Promise<number> {
        return this.selectedTableReservation.checkedInOtherGuests;
    }


    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This sets the add table reservation slide-out variable and triggers the respective Subject
     * so that all observables fire off
     */
    async setShowAddTableReservation(showSlideOut: boolean, eventTable: EventFloorPlanTableObject) {
        this.showAddTableReservation = showSlideOut;
        this.addTableReservationSelectedTable = eventTable;
        this.showAddTableReservationObservable.next(showSlideOut);
    }
    async getShowAddTableReservation(): Promise<boolean> {
        return this.showAddTableReservation;
    }
    async getAddTableReservationSelectedTable(): Promise<EventFloorPlanTableObject> {
        return this.addTableReservationSelectedTable;
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This sets the manage standby reservation slide-out variable and triggers the respective Subject
     * so that all observables fire off
     */
    async setShowManageStandbyReservation(showSlideOut: boolean, standbyReservation: TableReservationObject) {
        this.showManageStandbyReservation = showSlideOut;
        this.selectedStandbyReservation = standbyReservation;
        this.showManageStandbyReservationObservable.next(showSlideOut);
    }

    async getShowManageStandbyReservation(): Promise<boolean> {
        return this.showManageStandbyReservation;
    }

    async getSelectedStandbyReservation(): Promise<TableReservationObject> {
        return this.selectedStandbyReservation;
    }


    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This sets the enable event floor plans slide-out variable and triggers the respective Subject
     * so that all observables fire off
     */
    async setShowEnableEventFloorPlans(showSlideOut: boolean) {
        this.showEnableEventFloorPlans = showSlideOut;
        this.showEnableEventFloorPlansObservable.next(showSlideOut);
    }
    async getShowEnableEventFloorPlans(): Promise<boolean> {
        return this.showEnableEventFloorPlans;
    }

    async anyVenueSlideOutsShowing(): Promise<boolean> {
        switch(true) {
            case this.showAddVenueFloorPlan:
                return true;
            case this.showViewVenueFloorPlanSections:
                return true;
            case this.showViewVenueFloorPlanSectionTables:
                return true;
            case this.showAddVenueFloorPlanSection:
                return true;
            case this.showAddVenueFloorPlanTable:
                return true;
            default:
                return false;
        }
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This sets the add stand-by reservation slide-out variable and triggers the respective Subject
     * so that all observables fire off
     */
    async setShowAddStandByReservation(showSlideOut: boolean) {
        this.showAddStandbyReservation = showSlideOut;
        this.showAddStandbyReservationObservable.next(showSlideOut);
    }
    async getShowAddStandByReservation(): Promise<boolean> {
        return this.showAddStandbyReservation;
    }


    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This triggers the table server assigned Subject
     * so that all observables fire off
     */
    async setNewTableServerAssignedObservable(serverAssigned: boolean) {
        this.newTableServerAssignedObservable.next(serverAssigned);
    }


    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This triggers the table server assigned Subject
     * so that all observables fire off
     */
    async setTableMinimumUpdatedObservable(minimumUpdated: boolean) {
        this.tableMinimumUpdatedObservable.next(minimumUpdated);
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This triggers the table actions modal Subject
     * so that all observables fire off
     */
    async setTableActionsModalOpenObservable(modalOpened: boolean) {
        this.tableActionsModalOpenObservable.next(modalOpened);
    }


    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This sets the venue floor plan promoter payments slide-out variable and triggers the respective Subject
     * so that all observables fire off
     */
    async setShowVenueFloorPlanPromoterPayments(showSlideOut: boolean) {
        this.showVenueFloorPlanPromoterPayments = showSlideOut;
        this.showVenueFloorPlanPromoterPaymentsObservable.next(showSlideOut);
    }
    async getShowVenueFloorPlanPromoterPayments(): Promise<boolean> {
        return this.showVenueFloorPlanPromoterPayments;
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This sets the event floor plan promoter payments slide-out variable and triggers the respective Subject
     * so that all observables fire off
     */
    async setShowEventFloorPlanPromoterPayments(showSlideOut: boolean) {
        this.showEventFloorPlanPromoterPayments = showSlideOut;
        this.showEventFloorPlanPromoterPaymentsObservable.next(showSlideOut);
    }
    async getShowEventFloorPlanPromoterPayments(): Promise<boolean> {
        return this.showEventFloorPlanPromoterPayments;
    }

    // eslint-disable-next-line
    /**
     * @param  {TableReservationObject[]} reservations
     * This sets the all reservations variable and triggers the respective Subject
     * so that all observables fire off
     */
    async setAllReservations(reservations: EventTableReservationDetailsObject[]) {
        this.allReservations = reservations;
        console.log('reservations', reservations);
        console.log('this.allReservations', this.allReservations);
        this.allReservationsObservable.next(reservations);
    }
    async updateSpecifiedReservation(reservation: TableReservationObject) {
        const index = this.allReservations.findIndex(res => res.reservation.id === reservation.id);
        this.allReservations[index].reservation = reservation;
        this.selectReservationUpdatedObservable.next(this.allReservations[index].reservation);
    }
    async getAllReservations(): Promise<EventTableReservationDetailsObject[]> {
        return this.allReservations;
    }
    async getFromAllReservationsByReservationId(reservationId: string): Promise<EventTableReservationDetailsObject> {
        return this.allReservations.find(res => res.reservation.id === reservationId);
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showSlideOut
     * This sets the upcoming event reservations slide-out variable and triggers the respective Subject
     * so that all observables fire off
     */
    async setShowUpcomingEventReservations(showSlideOut: boolean) {
        this.showUpcomingEventReservations = showSlideOut;
        this.showUpcomingEventReservationsObservable.next(showSlideOut);
    }
    async getShowUpcomingEventReservations(): Promise<boolean> {
        return this.showUpcomingEventReservations;
    }
}
