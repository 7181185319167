/* eslint-disable max-len */
//Angular variables
import { Injectable } from '@angular/core';

//Capacitor variables
import { CapacitorHttp, HttpResponse } from '@capacitor/core';

//Other external imports
import * as XLSX from 'xlsx';

//Internal services
import { AccountService } from '../account/account-service.service';

//Internal variables
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TableReservationService {

  constructor(
    private accountService: AccountService
  ) { }

  async getNewAccessToken() {
    const userId = localStorage.getItem('phynxUserId');
    if (userId !== null && userId !== undefined && userId !== '' && userId !== 'null') {
      await this.accountService.refreshLoginMobile(userId).then(async (user) => {
        await this.accountService.setUserTokens(user.tokens).then(async () => true).catch((error) => {
          //TODO throw error here
          throw new Error('Unable to set user authentication tokens');
        });
      }).catch((error) => {
        //TODO throw error here
        throw new Error('Unable to refresh login session');
      });
    }
  }

  async getReservation(tid: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GetTableReservation?tid=${tid}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getReservation(tid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getReservationTable(tid: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/TableDetails?tid=${tid}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getReservationTable(tid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getSearchedReservationTable(rid: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/SearchedReservationTableDetails?rid=${rid}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getSearchedReservationTable(rid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getReservationSection(tid: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GetTableReservationSection?tid=${tid}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getReservationSection(tid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getStandByReservationSection(sid: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GetStandByReservationSection?sid=${sid}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getReservationSection(sid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getReservationAddedBy(rid: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/AddedBy?rid=${rid}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getReservationAddedBy(rid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getReservationServerName(rid: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GetReservationServerName?rid=${rid}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getReservationServerName(rid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getClosedReservationDetails(rid: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/ClosedReservationDetails?rid=${rid}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getClosedReservationDetails(rid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateReservationCheckedIn(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/ReservationCheckedInGuests`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateReservationCheckedIn(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getReservationAdditionalTables(srid: string, vtid: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/ReservationAdditionalTables?srid=${srid}&vtid=${vtid}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getReservationAdditionalTables(srid, vtid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async closeReservation(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/Close`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.closeReservation(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addReservationSocialAccount(body: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/TableReservations/AddReservationSocialMediaAccountURL`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addReservationSocialAccount(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getReservationSocialAccounts(reservationId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GetReservationSocialAccounts?reservationId=${reservationId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getReservationSocialAccounts(reservationId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getAssignedServer(corporationUserId: string, tableId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GetAssignedServer?corporationUserId=${corporationUserId}&tableId=${tableId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getAssignedServer(corporationUserId, tableId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getSuggestedServers(corporationUserId: string, eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GetReservationSuggestedServers?corporationUserId=${corporationUserId}&eventId=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getSuggestedServers(corporationUserId, eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getSearchedServers(corporationUserId: string, searchText: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GetReservationSearchedServers?corporationUserId=${corporationUserId}&searchText=${searchText}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getSearchedServers(corporationUserId, searchText, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addTableServer(reservationId: string, serverUserId: string, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/AddTableServer?rid=${reservationId}&serverUserId=${serverUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addTableServer(reservationId, serverUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async changeReservationTable(reservationId: string, existingTableId: string, newTableId: string, changeReason: string, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/ChangeReservationTable?reservationId=${reservationId}&existingTableId=${existingTableId}&newTableId=${newTableId}&changeReason=${changeReason}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.changeReservationTable(reservationId, existingTableId, newTableId, changeReason, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getReservationFloorPlan(floorPlanId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GetReservationFloorPlan?floorPlanId=${floorPlanId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getReservationFloorPlan(floorPlanId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getAvailableTables(floorPlanId: string, reservationId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GetAvailableEventTables?floorPlanId=${floorPlanId}&reservationId=${reservationId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getAvailableTables(floorPlanId, reservationId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async cancelTableReservation(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/CancelReservation`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.cancelTableReservation(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async cancelPendingTableReservation(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/CancelPendingReservation`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.cancelPendingTableReservation(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getGuestProfileVenueId(guestProfileId: string, corporationUserId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GetGuetProfileVenueId?guestProfileId=${guestProfileId}&corporationUserId=${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getGuestProfileVenueId(guestProfileId, corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getGuestProfileVenueCompany(guestProfileId: string, corporationUserId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GetGuestProfileVenueCompany?guestProfileId=${guestProfileId}&corporationUserId=${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getGuestProfileVenueCompany(guestProfileId, corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getGuestVipStatus(reservationId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GetGuestVIPStatus?reservationId=${reservationId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getGuestVipStatus(reservationId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addGuestProfileVenueCompany(guestProfileId: string, corporationUserId: string, companyName: string, tableReservationId: string = null, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/AddGuestProfileVenueCompany?guestProfileId=${guestProfileId}&corporationUserId=${corporationUserId}&companyName=${companyName}&tableReservationId=${tableReservationId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addGuestProfileVenueCompany(guestProfileId, corporationUserId, companyName, tableReservationId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async updateVipStatus(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/GuestVIPStatus`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateVipStatus(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  // async checkout(body: object, retry = true){
  //   const options = {
  //     method: 'POST',
  //     url: `${environment.baseUrl}/api/Reservations/Checkout`,
  //     headers: {
  //       authorization:this.accountService.authorizationHeader(),
  //       // eslint-disable-next-line @typescript-eslint/naming-convention
  //       'Content-Type': 'application/json'
  //     },
  //     data: body
  //   };

  //   const response: HttpResponse = await CapacitorHttp.post(options);

  //   if (response.status === 200) {
  //     return response.data;
  //   } else if (response.status === 204) {
  //     return null;
  //   } else if (response.status === 401 && retry) { // Unauthorized
  //     try {
  //       await this.getNewAccessToken();

  //       // Retry the request with the new access token
  //       return this.checkout(body, false);
  //     } catch (error) {
  //       throw new Error('Unable to refresh access token: ' + error.message);
  //     }
  //   } else {
  //     throw new Error(response.data.message);
  //   }
  // }

  async addTableReservation(body: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/TableReservations/VenueAddTableReservation`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addTableReservation(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      const error = new Error(response.data.message);
      (error as any).status = response.status; // Add status code to the error object
      (error as any).message = response.data.message; // Add message to the error object
      throw error;
    }
  }

  async addStandByTableReservation(body: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/TableReservations/VenueAddStandByTableReservation`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addStandByTableReservation(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async cancelStandByTableReservation(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/CancelStandByTableReservation`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.cancelStandByTableReservation(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getSearchedGuestsByEmail(email: string, corporationUserId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/SearchedGuestsByEmail?email=${email}&cuid=${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getSearchedGuestsByEmail(email, corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getSearchedGuestsByPhoneNumber(phoneNumber: string, corporationUserId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/SearchedGuestsByPhoneNumber?phoneNumber=${phoneNumber}&cuid=${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getSearchedGuestsByPhoneNumber(phoneNumber, corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getSearchedGuestsByName(name: string, corporationUserId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/SearchedGuestsByName?name=${name}&cuid=${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getSearchedGuestsByName(name, corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async addReservationTable(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/AddReservationTable`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addReservationTable(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async removeReservationTable(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/RemoveReservationTable`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.removeReservationTable(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateReservationName(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/ReservationName`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateReservationName(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateReservationContact(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/ReservationContact`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateReservationContact(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateReservationCompany(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/ReservationCompany`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateReservationCompany(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateReservationMinimum(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/ReservationMinimum`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateReservationMinimum(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateReservationComped(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/ReservationComped`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateReservationComped(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateReservationCheckedInGuests(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/UpdateReservationCheckedInGuests`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateReservationCheckedInGuests(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateReservationGuestGenders(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/UpdateReservationGuestGenders`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateReservationGuestGenders(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async reopenTableReservation(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/ReOpenTableReservation`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.reopenTableReservation(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async resendCompletePurchaseEmail(reservationId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/ResendCompletePurchaseEmail?reservationId=${reservationId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.resendCompletePurchaseEmail(reservationId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async resendConfirmedPurchaseEmail(reservationId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/ResendConfirmedPurchaseEmail?reservationId=${reservationId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.resendConfirmedPurchaseEmail(reservationId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getGuestGender(reservationId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GuestGender?reservationId=${reservationId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getGuestGender(reservationId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateGuestGender(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/UpdateGuestGender`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateGuestGender(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async reopenCanceledReservation(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/ReOpenCanceledReservation`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.reopenCanceledReservation(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async reopenCanceledPendingReservation(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/ReOpenCanceledPendingReservation`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.reopenCanceledPendingReservation(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateReservationFinalSpend(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/UpdateReservationFinalSpend`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateReservationFinalSpend(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getGuestBirthDate(phoneNumber: string, corporationUserId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GetGuestBirthDate?phoneNumber=${phoneNumber}&cuid=${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getGuestBirthDate(phoneNumber, corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async markReservationAsPaid(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/MarkReservationAsPaid`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.markReservationAsPaid(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async cancelReservationDueUpfront(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/CancelReservationDueUpfront`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.cancelReservationDueUpfront(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getMarkedAsPaidByUserName(markedAsPaidByUserId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GetMarkedAsPaidByUserName?uid=${markedAsPaidByUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getMarkedAsPaidByUserName(markedAsPaidByUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateReservationPaidUpfront(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/UpdateReservationPaidUpfront`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateReservationPaidUpfront(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateReservationAttribution(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/UpdateReservationAttribution`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateReservationAttribution(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getUpcomingEventReservationByDate(date: string, cuid: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GetUpcomingEventReservationByDate?date=${date}&cuid=${cuid}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getUpcomingEventReservationByDate(date, cuid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getAllUpcomingEventReservationsByVenue(cuid: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/TableReservations/GetAllUpcomingEventReservationsByVenue?cuid=${cuid}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getAllUpcomingEventReservationsByVenue(cuid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async removeUpcomingEventReservation(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/TableReservations/RemoveUpcomingEventReservation`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.removeUpcomingEventReservation(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async exportToExcel(filename: string,
    rows: object[] | object,
    sheetName: string = 'Sheet1',
    columnWidths: number[] = [],
    rowHeights: number[] = [],
    header: string[] = [],
    footer: string[] = []) {

      if (!rows) {
        return;
      }

      // If rows is a single object, wrap it in an array
      const data = Array.isArray(rows) ? rows : [rows];

      // Prepare workbook
      const wb = XLSX.utils.book_new();

      // Convert data to worksheet
      const ws = XLSX.utils.json_to_sheet(data);

      // Set column widths
      ws['!cols'] = columnWidths.map(w => ({wch: w}));

      // Set row heights (only works for the xlsx format, and not for older xls)
      ws['!rows'] = rowHeights.map(h => ({hpt: h}));

      // Add auto-filter to all columns, use the range from the sheet directly
      ws['!autofilter'] = { ref: ws['!ref'] };

      // Add header and footer
      ws['!margins'] = { header: 0.5, footer: 0.5 };
      ws['!header'] = header;
      ws['!footer'] = footer;

      // Append worksheet to workbook
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      // Write workbook and force download
      XLSX.writeFile(wb, `${filename}.xlsx`);
  }
}
