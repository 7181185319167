//Angular imports
import { Component, OnInit } from '@angular/core';

//Internal providers
import { GuestListGuestProvider } from 'src/app/providers/guest-list-guest.provider';

@Component({
  selector: 'app-guest-details',
  templateUrl: './guest-details.component.html',
  styleUrls: ['./guest-details.component.scss'],
})
export class GuestDetailsComponent implements OnInit {

  constructor(
    private guestListGuestProvider: GuestListGuestProvider
  ) { }

  ngOnInit() {

  }

  async closeModal() {
    await this.guestListGuestProvider.dismissViewGuestModal();
  }
}
