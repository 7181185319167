//Angular imports
import { Component, OnInit } from '@angular/core';

//Internal providers
import { CampaignAnalyticsProvider } from 'src/app/providers/campaign-analytics.provider';

//Internal enums
import { CampaignType } from 'src/app/enums/campaigns/campaign-type';

//Internal models
import { CampaignObject } from 'src/app/models/campaign.model';

@Component({
  selector: 'app-campaign-insights',
  templateUrl: './campaign-insights.component.html',
  styleUrls: ['./campaign-insights.component.scss'],
})
export class CampaignInsightsComponent implements OnInit {
  //internal component display variables
  selectedInsightsCampaign: CampaignObject;
  selectedInsightsCampaignType: string;

  constructor(
    private campaignAnalyticsProvider: CampaignAnalyticsProvider
  ) { }

  async ngOnInit() {
    this.selectedInsightsCampaign = await this.campaignAnalyticsProvider.getSelectedCampaignInsightsId();

    switch (true) {
      case this.selectedInsightsCampaign.campaignTypeId === CampaignType.facebook: {
        this.selectedInsightsCampaignType = 'social';

        break;
      }
      case this.selectedInsightsCampaign.campaignTypeId === CampaignType.instagram: {
        this.selectedInsightsCampaignType = 'social';

        break;
      }
      case this.selectedInsightsCampaign.campaignTypeId === CampaignType.location: {
        this.selectedInsightsCampaignType = 'location';

        break;
      }
      case this.selectedInsightsCampaign.campaignTypeId === CampaignType.posterCode: {
        this.selectedInsightsCampaignType = 'poster';

        break;
      }
      case this.selectedInsightsCampaign.campaignTypeId === CampaignType.promoterSMS: {
        this.selectedInsightsCampaignType = 'sms';

        break;
      }
      case this.selectedInsightsCampaign.campaignTypeId === CampaignType.link: {
        this.selectedInsightsCampaignType = 'link';

        break;
      }
      case this.selectedInsightsCampaign.campaignTypeId === CampaignType.promoterQR: {
        this.selectedInsightsCampaignType = 'qrcode';

        break;
      }
      default: {
        break;
      }
    }

  }

}
