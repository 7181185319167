<div class="w-full h-full relative bg-dark-default pt-6 pb-8 overflow-scroll scroll-hidden px-4">
  <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2">
    <li *ngFor="let guest of bannedGuests; let i = index;" class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
      <div class="flex w-full items-center justify-between space-x-6 p-6">
        <div class="flex-1 truncate">
          <div class="flex items-center space-x-3">
            <h3 class="truncate text-sm font-medium text-gray-900">{{guest.fullName}}</h3>
          </div>
          <p class="mt-1 truncate text-sm text-gray-500" *ngIf="guest.phoneNumber">{{guest.phoneNumber | phone}}</p>
        </div>
        <div class="h-10 w-10 rounded-full bg-primary-200 text-primary-800 flex justify-center items-center space-x-0">
          <span class="text-lg font-medium leading-none">{{guest.firstInitial}}{{guest.lastInitial}}</span>
        </div>
        <!-- <img class="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" *ngIf="guest.imageFileId !== null" src="{{baseUrl}}/api/File/{{guest.imageFileId}}" alt=""> -->
        <span class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500" *ngIf="!guest.imageFileId === null">
          <span class="text-xl font-medium leading-none text-white">{{guest.firstInitial}}</span>
        </span>
      </div>
      <div>
        <div class="-mt-px flex divide-x divide-gray-200">
          <button class="flex w-0 flex-1" name="details_{{i}}" id="open-banned-details-modal-{{i}}" (click)="getBanDetails(guest)">
            <div class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium cursor-pointer text-gray-800 hover:bg-slate-100">
              <!-- Heroicon name: mini/identification -->
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-gray-500">
                <path fill-rule="evenodd" d="M1 6a3 3 0 013-3h12a3 3 0 013 3v8a3 3 0 01-3 3H4a3 3 0 01-3-3V6zm4 1.5a2 2 0 114 0 2 2 0 01-4 0zm2 3a4 4 0 00-3.665 2.395.75.75 0 00.416 1A8.98 8.98 0 007 14.5a8.98 8.98 0 003.249-.604.75.75 0 00.416-1.001A4.001 4.001 0 007 10.5zm5-3.75a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75zm0 6.5a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75zm.75-4a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5h-2.5z" clip-rule="evenodd" />
              </svg>              
              <span class="ml-3">View Details</span>
            </div>
          </button>

          <ion-modal #bannedDetailsModal class="banned-details-modal bg-dark" trigger="open-banned-details-modal-{{i}}">
            <ng-template>
              <ion-content>
                <ion-toolbar>
                  <ion-title>Banned Details</ion-title>
                  <ion-buttons slot="end">
                    <ion-button (click)="bannedDetailsModal.dismiss()">Close</ion-button>
                  </ion-buttons>
                </ion-toolbar>
                
                <div class="w-full h-[calc(100%-56px)] pt-4">
                  <div class="grid grid-cols-12">
                    <div class="col col-span-2 flex justify-center items-center">
                      <!-- <img *ngIf="bannedGuestDetails.imageFileId" src="{{baseUrl}}/api/File/{{bannedGuestDetails.imageFileId}}" class="inline-flex h-12 w-12 sm:h-16 sm:w-16 rounded-full"> -->
                      <div class="h-10 w-10 rounded-full bg-primary-200 text-primary-800 flex justify-center items-center space-x-0">
                        <span class="text-lg font-medium leading-none">{{guest.firstInitial}}{{guest.lastInitial}}</span>
                      </div>
                      <span *ngIf="!bannedGuestDetails.imageFileId" class="inline-flex h-12 w-12 sm:h-16 sm:w-16 items-center justify-center rounded-full bg-gray-500">
                        <span class="text-xl font-medium leading-none text-white">{{initials}}</span>
                      </span>
                    </div>
                    <div class="col col-span-6 flex flex-col justify-center items-start space-y-1 pr-1">
                      <p class="text-white">{{bannedGuestDetails.fullName}}</p>
                      <p class="text-white">{{bannedGuestDetails.email}}</p>
                      <p class="text-red-500 uppercase" *ngIf="bannedGuestDetails.isBolo">Be on the lookout!</p>
                    </div>
                    <div class="col col-span-4 flex flex-col justify-center items-start space-y-1 pl-1">
                      <p class="text-white" *ngIf="bannedGuestDetails.phoneNumber">{{bannedGuestDetails.phoneNumber | phone}}</p>
                      <p class="text-white">{{bannedGuestDetails.gender}}</p>
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-x-4 sm:gap-x-8 py-4 px-2 sm:px-4">
                    <div class="col-span-1 h-20 rounded-lg shadow-lg bg-white pl-4">
                      <div class="w-full h-full flex flex-col justify-center items-start space-y-2">
                        <p class="text-base font-normal text-gray-900">Total Visits</p>
                        <p class="text-xl sm:text-2xl font-semibold text-primary-default">{{bannedGuestDetails.totalVenueVisits}}</p>
                      </div>
                    </div>
                    
                    <div class="col-span-1 h-20 rounded-lg shadow-lg bg-white pl-4">
                      <div class="w-full h-full flex flex-col justify-center items-start space-y-2">
                        <p class="text-base font-normal text-gray-900">Other Bans</p>
                        <p class="text-xl sm:text-2xl font-semibold text-primary-default">{{bannedGuestDetails.totalOtherBans}}</p>
                      </div>
                    </div>

                    <div class="col-span-1 h-20 rounded-lg shadow-lg bg-white pl-4">
                      <div class="w-full h-full flex flex-col justify-center items-start space-y-2">
                        <p class="text-base font-normal text-gray-900">Banned By</p>
                        <p class="text-lg sm:text-xl font-semibold text-primary-default truncate whitespace-nowrap overflow-ellipsis">{{bannedGuestDetails.bannedBy}}</p>
                      </div>
                    </div>
                  </div>


                  <div class="px-4">
                    <h1 class="text-xl font-semibold text-white mt-4">Reason</h1>

                    <p class="text-white">{{bannedGuestDetails.bannedReason}}</p>
                  </div>


                  <!-- guest images -->
                  <div class="flex flex-col space-y-4 px-4 py-4">
                    <div class="col-span-12">
                      <h1 class="text-xl font-semibold text-white">Images</h1>
                    </div>

                    <div class="flex justify-start items-start flex-wrap space-x-2">
                      <div *ngFor="let image of bannedGuestImages; let i = index;">
                        <div class="relative group">
                          <img src="{{baseUrl}}/api/File/{{image}}" class="w-full h-32 object-cover rounded-lg shadow-lg" alt="Banned Guest Image">
                        </div>
                      </div>

                      <div *ngIf="bannedGuestImages.length === 0" class="text-white text-lg col-span-1 sm:col-span-2 flex justify-center items-center mt-4">
                        <span>There are no images to display yet</span>
                      </div>

                      <!-- add another image -->
                      <div class="relative group">
                        <button class="w-full h-32 flex justify-center items-center rounded-lg bg-primary-200 shadow-lg px-2" (click)="presentAddImageAlert(guest)">
                          <div class="flex flex-col items-center space-y-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5 text-primary-800">
                              <path fill-rule="evenodd" d="M13.75 7h-3V3.66l1.95 2.1a.75.75 0 1 0 1.1-1.02l-3.25-3.5a.75.75 0 0 0-1.1 0L6.2 4.74a.75.75 0 0 0 1.1 1.02l1.95-2.1V7h-3A2.25 2.25 0 0 0 4 9.25v7.5A2.25 2.25 0 0 0 6.25 19h7.5A2.25 2.25 0 0 0 16 16.75v-7.5A2.25 2.25 0 0 0 13.75 7Zm-3 0h-1.5v5.25a.75.75 0 0 0 1.5 0V7Z" clip-rule="evenodd" />
                            </svg>
                            <span class="text-primary-800 text-sm">Add Image</span>
                          </div>
                        </button>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </ion-content>
            </ng-template>
          </ion-modal>


          <button [disabled]="!hasTicketingWritePermission && !hasGuestListWritePermission && !hasTableServiceWritePermission" class="-ml-px flex w-0 flex-1 group" (click)="presentRemoveBanAlert(guest)">
            <div class="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium bg-red-100 cursor-pointer text-red-800 hover:bg-red-200 group-disabled:bg-dark-100 group-disabled:hover:bg-dark-200 group-disabled:text-dark-800 group-disabled:cursor-default">
              <!-- Heroicon name: mini/user-minus -->
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-red-500 group-disabled:text-dark-800">
                <path d="M11 5a3 3 0 11-6 0 3 3 0 016 0zM2.046 15.253c-.058.468.172.92.57 1.175A9.953 9.953 0 008 18c1.982 0 3.83-.578 5.384-1.573.398-.254.628-.707.57-1.175a6.001 6.001 0 00-11.908 0zM12.75 7.75a.75.75 0 000 1.5h5.5a.75.75 0 000-1.5h-5.5z" />
              </svg>              
              <span class="ml-3">Remove Ban</span>
            </div>
          </button>
        </div>
      </div>
    </li>


    <li *ngIf="!hasBannedGuests" class="text-white text-lg col-span-1 sm:col-span-2 flex justify-center items-center mt-4">
      <span>There are no banned guests to display yet</span>
    </li>
  </ul>
</div>
