/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit} from '@angular/core';

//Other external imports
import * as echarts from 'echarts';
import { EChartsOption } from 'echarts';

//Internal services
import { CampaignsService } from 'src/app/services/campaigns/campaigns.service';

//Internal providers
import { GlobalAppProvider } from 'src/app/app.provider';

//Internal models
import { StrydEventObject } from 'src/app/models/event.model';

@Component({
  selector: 'app-campaign-analytics',
  templateUrl: './campaign-analytics.component.html',
  styleUrls: ['./campaign-analytics.component.scss'],
})
export class CampaignAnalyticsComponent implements OnInit, AfterViewInit {
  @ViewChild('salesByCampaignTypeChart', {static: false}) salesByCampaignTypeChart: ElementRef;
  @ViewChild('completionsByCampaignTypeChart', {static: false}) completionsByCampaignTypeChart: ElementRef;
  @ViewChild('profitByCampaignType', {static: false}) profitByCampaignType: ElementRef;

  //chart variables
  chartOptions: EChartsOption;

  //internal display variables
  selectedStrydEvent: StrydEventObject;

  constructor(
    private appProvider: GlobalAppProvider,
    private campaignService: CampaignsService
  ) { }

  async ngOnInit() {
    //task 1: get the selected STRYD event
    this.selectedStrydEvent = await this.appProvider.getSelectedEvent();

    this.appProvider.selectedEventObservable.subscribe(async (res: StrydEventObject) => {
      this.selectedStrydEvent = res;

      this.assembleSalesByCampaignTypeChart();
      this.assembleCompletionsByCampaignTypeChart();
      this.assembleProfitByCampaignTypeChart();
    });
  }

  ngAfterViewInit(): void {
    this.assembleSalesByCampaignTypeChart();
    this.assembleCompletionsByCampaignTypeChart();
    this.assembleProfitByCampaignTypeChart();
  }

  async assembleSalesByCampaignTypeChart() {
    await this.campaignService.getSalesByCampaignType(this.selectedStrydEvent.eventDetailId)
      .then((res) => {

        const myChart = echarts.init(this.salesByCampaignTypeChart.nativeElement, 'dark');

        const chartOptions = {
          backgroundColor: '#2e2e3f',
          legend: {
            top: 'bottom'
          },
          title: {
            text: 'Sales by Source',
            left: 'center',
            top: '5%',
            textStyle: {
              color: '#ccc'
            }
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              restore: { show: true },
              saveAsImage: { show: true }
            }
          },
          tooltip: {
            trigger: 'item',
            valueFormatter: (value) => '$' + value.toFixed(2)
          },
          series: [
            {
              name: 'Total Sales',
              type: 'pie',
              radius: '55%',
              center: ['50%', '50%'],
              roseType: 'area',
              itemStyle: {
                borderRadius: 8
              },
              data: res.sort(function(a, b) {
                return a.value - b.value;
              }),
              animationType: 'scale',
              animationEasing: 'elasticOut',
              animationDelay(idx) {
                return Math.random() * 200;
              }
            }
          ]
        };

        myChart.setOption(chartOptions);
        myChart.on('click', ($event) => {

        });
        myChart.on('dblclick', ($event) => {

        });
      });
  }

  async assembleCompletionsByCampaignTypeChart() {
    this.campaignService.getCompletionsByCampaignType(this.selectedStrydEvent.eventDetailId)
      .then((res) => {

        const myChart = echarts.init(this.completionsByCampaignTypeChart.nativeElement, 'dark');

        const chartOptions = {
          backgroundColor: '#2e2e3f',
          legend: {
            top: 'bottom'
          },
          title: {
            text: 'Completions by Campaign',
            left: 'center',
            top: '5%',
            textStyle: {
              color: '#ccc'
            }
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              restore: { show: true },
              saveAsImage: { show: true }
            }
          },
          tooltip: {
            trigger: 'item'
          },
          series: [
            {
              name: 'Total Completions',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: true,
              },
              emphasis: {
                label: {
                  show: true
                }
              },
              labelLine: {
                show: true
              },
              data: res.sort(function(a, b) {
                return a.value - b.value;
              }),
              animationType: 'scale',
              animationEasing: 'elasticOut',
              animationDelay(idx) {
                return Math.random() * 200;
              }
            }
          ]
        };

        myChart.setOption(chartOptions);
        myChart.on('click', ($event) => {

        });
        myChart.on('dblclick', ($event) => {

        });
      });
  }

  async assembleProfitByCampaignTypeChart() {
    await this.campaignService.getProfitByCampaignType(this.selectedStrydEvent.eventDetailId)
      .then((res) => {

        const myChart = echarts.init(this.profitByCampaignType.nativeElement, 'dark');

        const chartOptions = {
          backgroundColor: '#2e2e3f',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            top: 'bottom',
            data: ['Profit', 'Expenses', 'Income']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'value'
            }
          ],
          yAxis: [
            {
              type: 'category',
              axisTick: {
                show: false
              },
              data: ['Link', 'Facebook', 'Instagram', 'QR Code', 'SMS']
            }
          ],
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              restore: { show: true },
              saveAsImage: { show: true }
            }
          },
          series: [
            {
              name: 'Profit',
              type: 'bar',
              label: {
                show: true,
                position: 'inside'
              },
              emphasis: {
                focus: 'series'
              },
              data: [res[0].totalProfit, res[1].totalProfit, res[2].totalProfit, res[3].totalProfit, res[4].totalProfit]
            },
            {
              name: 'Income',
              type: 'bar',
              stack: 'Total',
              label: {
                show: true
              },
              emphasis: {
                focus: 'series'
              },
              data: [res[0].totalSales, res[1].totalSales, res[2].totalSales, res[3].totalSales, res[4].totalSales]
            },
            {
              name: 'Expenses',
              type: 'bar',
              stack: 'Total',
              label: {
                show: true,
                position: 'inside'
              },
              emphasis: {
                focus: 'series'
              },
              data: [-res[0].totalCost, -res[1].totalCost, -res[2].totalCost, -res[3].totalCost, -res[4].totalCost]
            }
          ]
        };

        myChart.setOption(chartOptions);
        myChart.on('click', ($event) => {

        });
        myChart.on('dblclick', ($event) => {

        });
      });
  }

}
