//Angular variables
import { Injectable } from '@angular/core';
import { from, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

//Capacitor variables
import { CapacitorHttp, HttpResponse } from '@capacitor/core';

//Internal services
import { AccountService } from '../account/account-service.service';

//Internal variables
import { environment } from '../../../environments/environment';

//Internal providers
import { TicketingProvider } from 'src/app/providers/ticketing.provider';

@Injectable({
  providedIn: 'root'
})
export class TicketingService {

  constructor(
    private accountService: AccountService,
    private ticketingProvider: TicketingProvider
  ) { }

  async getNewAccessToken() {
    const userId = localStorage.getItem('phynxUserId');
    if (userId !== null && userId !== undefined && userId !== '' && userId !== 'null') {
      await this.accountService.refreshLoginMobile(userId).then(async (user) => {
        await this.accountService.setUserTokens(user.tokens).then(async () => true).catch((error) => {
          //TODO throw error here
          throw new Error('Unable to set user authentication tokens');
        });
      }).catch((error) => {
        //TODO throw error here
        throw new Error('Unable to refresh login session');
      });
    }
  }

  async searchEventGuests(searchString: string, passedPage: number, pageSize: number, eventDetailId: string, retry = true){
    if (searchString === null || searchString === '') {
      this.ticketingProvider.setShowSearchedGuestDropdown(false);
      return [];
    } else {
      const requestBody = {
        eventDetailId,
        searchString,
        passedPage,
        pageSize
      };

      const options = {
        method: 'POST',
        url: `${environment.baseUrl}/api/Ticketing/SearchEventGuests`,
        headers: {
          authorization:this.accountService.authorizationHeader(),
          'content-type':'application/json'
        },
        data: requestBody
      };

      const response: HttpResponse = await CapacitorHttp.post(options);

      if (response.status === 200) {
        this.ticketingProvider.setShowSearchedGuestDropdown(true);
        return response.data;
      } else if (response.status === 204) {
        return null;
      } else if (response.status === 401 && retry) { // Unauthorized
        try {
          await this.getNewAccessToken();

          // Retry the request with the new access token
          return this.searchEventGuests(searchString, passedPage, pageSize, eventDetailId, false);
        } catch (error) {
          throw new Error('Unable to refresh access token: ' + error.message);
        }
      } else {
        throw new Error(response.data.message);
      }

    }
  }

  async getEventTicketTiers(strydEventId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Ticketing/GetEventTicketTiers?eid=${strydEventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventTicketTiers(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateGuestStatus(requestBody, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/UpdateGuestStatus`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateGuestStatus(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateAdditionalGuestStatus(requestBody, isTicketedGuest: boolean, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/UpdateAdditionalGuestStatus?isTicketedGuest=${isTicketedGuest}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateAdditionalGuestStatus(requestBody, isTicketedGuest, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async banGuest(requestBody, isTicketedGuest: boolean, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/BanGuest?isTicketedGuest=${isTicketedGuest}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.banGuest(requestBody, isTicketedGuest, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async banAdditionalGuest(requestBody, isTicketedGuest: boolean, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/BanAdditionalGuest?isTicketedGuest=${isTicketedGuest}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.banAdditionalGuest(requestBody, isTicketedGuest, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updatePrimaryGuestGender(guestId: string, gender: string, isTicketed: boolean, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/UpdatePrimaryGuestGender?gid=${guestId}&gender=${gender}&isTicketed=${isTicketed}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updatePrimaryGuestGender(guestId, gender, isTicketed, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateAdditionalGuestGender(guestId: string, gender: string, isTicketed: boolean, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/GuestLists/UpdateAdditionalGuestGender?gid=${guestId}&gender=${gender}&isTicketed=${isTicketed}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateAdditionalGuestGender(guestId, gender, isTicketed, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getSelectedGuestDetails(guestId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Ticketing/GetEventGuest?gid=${guestId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getSelectedGuestDetails(guestId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getAdditionalTicketedGuests(primaryGuestId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Ticketing/GetTicketedAdditionalGuests?gid=${primaryGuestId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getAdditionalTicketedGuests(primaryGuestId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getGrossRevenue(strydEventId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Ticketing/GetGrossRevenue?eid=${strydEventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getGrossRevenue(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getGrossTicketsSold(strydEventId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Ticketing/GetGrossTicketsSold?eid=${strydEventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getGrossTicketsSold(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getCheckedInTickets(strydEventId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Ticketing/GetCheckedInTickets?eid=${strydEventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getCheckedInTickets(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getTicketSalesOverTime(strydEventId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Ticketing/GetTicketSalesOverTime?eid=${strydEventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTicketSalesOverTime(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateEventTicketTier(requestBody: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/StrydEvents/UpdateEventTicketTier`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateEventTicketTier(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getTierPromoterPayments(tierId: string, searchString: string, retry = true) {
    const options = {
      method: 'GET',
      // eslint-disable-next-line max-len
      url: `${environment.baseUrl}/api/Ticketing/GetTicketTierPromoterPayments?tierId=${tierId}` + (searchString ? `&searchString=${searchString}` : ''),
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTierPromoterPayments(tierId, searchString, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getTierPromoterDiscounts(tierId: string, searchString: string, retry = true) {
    const options = {
      method: 'GET',
      // eslint-disable-next-line max-len
      url: `${environment.baseUrl}/api/Ticketing/GetTicketTierPromoterDiscounts?tierId=${tierId}` + (searchString ? `&searchString=${searchString}` : ''),
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTierPromoterDiscounts(tierId, searchString, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateEventTicketTierPromoterPayments(requestBody: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/StrydEvents/UpdateTicketTierPromoterPayments`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateEventTicketTierPromoterPayments(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateEventTicketTierPromoterDiscounts(requestBody: object, corporationUserId: string, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/StrydEvents/UpdateTicketTierPromoterDiscounts?cuid=${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateEventTicketTierPromoterDiscounts(requestBody, corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async removeTicketTier(tierId: string, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Ticketing/RemoveTicketTier?tierId=${tierId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.removeTicketTier(tierId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async stopTicketTierSales(tierId: string, retry = true) {
    const requestBody = {
      tierId
    };

    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/StrydEvents/EndTicketTierSales`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.stopTicketTierSales(tierId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addTicketTier(eid: string, requestBody: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Ticketing/AddTicketTier?eid=${eid}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addTicketTier(eid, requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async refundFullReservation(attendeeId: string, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Ticketing/RefundFullAttendeeCharge?attendeeId=${attendeeId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.refundFullReservation(attendeeId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getEventTicketingStats(strydEventId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Ticketing/GetEventTicketingStats?strydEventId=${strydEventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventTicketingStats(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getTicketTierGuests(tierId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Ticketing/GetTierGuests?tid=${tierId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTicketTierGuests(tierId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getTicketTierPromoters(tierId: string, corporationUserId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Ticketing/GetTierPromoters?tid=${tierId}&cuid=${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTicketTierPromoters(tierId, corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateTicketTierPromoters(requestBody: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/StrydEvents/UpdateTicketTierPromoters`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateTicketTierPromoters(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async updateTicketTierIndex(requestBody: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Ticketing/UpdateTicketTierIndex`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        'content-type':'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateTicketTierIndex(requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getDownloadableTierGuests(tierId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Ticketing/GetDownloadableTierGuests?tid=${tierId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getDownloadableTierGuests(tierId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getEventDownloadableTicketedGuests(eventId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Ticketing/GetEventDownloadableTicketedGuests?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventDownloadableTicketedGuests(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getTicketingSalesByPromoter(eventId: string, corporationUserId: string, passedPage: number, pageSize: number, retry = true) {
    const options = {
      method: 'GET',
      // eslint-disable-next-line max-len
      url: `${environment.baseUrl}/api/Ticketing/GetSalesByPromoter?eid=${eventId}&cid=${corporationUserId}&passedPage=${passedPage}&pageSize=${pageSize}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTicketingSalesByPromoter(eventId, corporationUserId, passedPage, pageSize, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  exportToCsv(filename: string, rows: object[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent =
      keys.join(separator) +
        '\n' +
        rows.map(row => keys.map(k => {
            let cell = row[k] === null || row[k] === undefined ? '' : row[k];
            cell = cell instanceof Date
              ? cell.toLocaleString()
              : cell.toString().replace(/"/g, '""');
            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }
            return cell;
          }).join(separator)).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
