/* eslint-disable max-len */
//Angular variables
import { Injectable } from '@angular/core';

//Capacitor variables
import { CapacitorHttp, HttpResponse } from '@capacitor/core';

//Other external imports
import * as XLSX from 'xlsx';

//Internal services
import { AccountService } from '../account/account-service.service';

//Internal variables
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TablesService {

  constructor(
    private accountService: AccountService
  ) { }

  async getNewAccessToken() {
    const userId = localStorage.getItem('phynxUserId');
    if (userId !== null && userId !== undefined && userId !== '' && userId !== 'null') {
      await this.accountService.refreshLoginMobile(userId).then(async (user) => {
        await this.accountService.setUserTokens(user.tokens).then(async () => true).catch((error) => {
          //TODO throw error here
          throw new Error('Unable to set user authentication tokens');
        });
      }).catch((error) => {
        //TODO throw error here
        throw new Error('Unable to refresh login session');
      });
    }
  }

  async getVenueFloorPlans(retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/GetVenueFloorPlans?cid=${await this.accountService.getCorporationUserId()}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenueFloorPlans(false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEnabledEventFloorPlans(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/GetEnabledEventFloorPlans?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEnabledEventFloorPlans(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getPublicEventFloorPlans(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/GetPublicEventFloorPlans?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPublicEventFloorPlans(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async createVenueFloorPlan(body: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Tables/AddVenueFloorPlan`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.createVenueFloorPlan(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getVenueFloorPlanSections(floorPlanId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/GetVenueFloorPlanSections?fid=${floorPlanId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenueFloorPlanSections(floorPlanId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventFloorPlanSections(floorPlanId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/GetEventFloorPlanSections?fid=${floorPlanId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventFloorPlanSections(floorPlanId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventFloorPlanSectionTables(eventId: string, floorPlanId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/GetEventFloorPlanSectionTables?eid=${eventId}&fid=${floorPlanId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventFloorPlanSectionTables(eventId, floorPlanId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getVenueFloorPlanSectionTables(floorPlanId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/GetVenueFloorPlanSectionTables?fid=${floorPlanId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenueFloorPlanSectionTables(floorPlanId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async createVenueFloorPlanSection(body: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Tables/AddVenueFloorPlanSection`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.createVenueFloorPlanSection(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateVenueFloorPlanSection(body: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Tables/UpdateVenueFloorPlanSection`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateVenueFloorPlanSection(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async createEventFloorPlanSection(body: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Tables/AddEventFloorPlanSection`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.createEventFloorPlanSection(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateVenueFloorPlanSectionIndex(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/UpdateVenueFloorPlanSectionIndex`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateVenueFloorPlanSectionIndex(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateEventFloorPlanSectionIndex(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/UpdateEventFloorPlanSectionIndex`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateEventFloorPlanSectionIndex(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateVenueTableSection(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/UpdateVenueFloorPlanTableSection`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateVenueTableSection(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateEventTableSection(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/UpdateEventFloorPlanTableSection`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateEventTableSection(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getVenueFloorPlanTables(floorPlanId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/GetVenueFloorPlanTables?fid=${floorPlanId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenueFloorPlanTables(floorPlanId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventFloorPlanTables(floorPlanId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/GetEventFloorPlanTables?fid=${floorPlanId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventFloorPlanTables(floorPlanId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getAllEventTableReservationDetails(floorPlanId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/GetAllEventTableReservationDetails?fid=${floorPlanId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getAllEventTableReservationDetails(floorPlanId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getPromoterEventFloorPlans(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/GetPromoterEventFloorPlans?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPromoterEventFloorPlans(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getPromoterEventFloorPlanTables(floorPlanId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/GetPromoterEventFloorPlanTables?fid=${floorPlanId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPromoterEventFloorPlanTables(floorPlanId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getPromoterEventTableReservation(tableId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/GetPromoterEventTableReservation?tableId=${tableId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPromoterEventTableReservation(tableId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async createVenueFloorPlanTable(body: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Tables/AddVenueFloorPlanTable`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.createVenueFloorPlanTable(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async createEventFloorPlanTable(body: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Tables/AddEventFloorPlanTable`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.createEventFloorPlanTable(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateVenueFloorPlanTable(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/UpdateVenueFloorPlanTable`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateVenueFloorPlanTable(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateVenueFloorPlanTableIndex(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/UpdateVenueFloorPlanTableIndex`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateVenueFloorPlanTableIndex(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateEventFloorPlanTable(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/UpdateEventFloorPlanTable`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateEventFloorPlanTable(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateEventFloorPlanTableIndex(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/UpdateEventFloorPlanTableIndex`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateEventFloorPlanTableIndex(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async duplicateVenueFloorPlanTable(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/DuplicateVenueFloorPlanTable`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.duplicateVenueFloorPlanTable(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async duplicateEventFloorPlanTable(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/DuplicateEventFloorPlanTable`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.duplicateEventFloorPlanTable(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateEventTablePublicStatus(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/UpdateEventTablePublicStatus`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateEventTablePublicStatus(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateEventFloorPlanTablePublicStatus(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/UpdateEventFloorPlanTablePublicStatus`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateEventFloorPlanTablePublicStatus(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async removeVenueFloorPlanTable(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/RemoveVenueFloorPlanTable`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.removeVenueFloorPlanTable(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async removeEventFloorPlanTable(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/RemoveEventFloorPlanTable`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.removeEventFloorPlanTable(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateVenueFloorPlanTablePosition(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/UpdateVenueFloorPlanTablePosition`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateVenueFloorPlanTablePosition(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateEventFloorPlanTablePosition(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/UpdateEventFloorPlanTablePosition`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateEventFloorPlanTablePosition(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getFloorPlanReservationExport(floorPlanId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/ExportFloorPlanReservations?fpid=${floorPlanId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getFloorPlanReservationExport(floorPlanId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getSearchedTableReservations(floorPlanId: string, searchText: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/SearchTableReservations?floorPlanId=${floorPlanId}&searchText=${searchText}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getSearchedTableReservations(floorPlanId, searchText, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getAvailableTables(sectionId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/GetEventSectionTables?sectionId=${sectionId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getAvailableTables(sectionId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getAllEventFloorPlans(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/GetAllEventFloorPlans?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getAllEventFloorPlans(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async enableEventFloorPlan(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/EnableEventFloorPlan`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.enableEventFloorPlan(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      const error = new Error(response.data.message);
      (error as any).status = response.status; // Add status code to the error object
      (error as any).message = response.data.message; // Add message to the error object
      throw error;
    }
  }

  async disableEventFloorPlan(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/DisableEventFloorPlan`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.disableEventFloorPlan(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      const error = new Error(response.data.Message);
      (error as any).status = response.status; // Add status code to the error object
      throw error;
    }
  }

  async setEventFloorPlanDefault(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/SetEventFloorPlanDefault`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.setEventFloorPlanDefault(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async setEventFloorPlanPublicStatus(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/SetEventFloorPlanPublic`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.setEventFloorPlanDefault(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      const error = new Error(response.data.message);
      (error as any).status = response.status; // Add status code to the error object
      (error as any).message = response.data.message; // Add message to the error object
      throw error;
    }
  }

  async removeVenueFloorPlan(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/RemoveVenueFloorPlan`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.removeVenueFloorPlan(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getFloorPlanRegistrationForm(floorPlanId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/GetFloorPlanRegistrationForm?fpid=${floorPlanId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getFloorPlanRegistrationForm(floorPlanId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateVenueFloorPlan(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/UpdateVenueFloorPlan`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateVenueFloorPlan(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async removeVenueFloorPlanSection(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/RemoveVenueFloorPlanSection`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.removeVenueFloorPlanSection(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async duplicateVenueFloorPlan(body: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Tables/DuplicateVenueFloorPlan`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.duplicateVenueFloorPlan(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getVenueFloorPlanPromoterPayments(floorPlanId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/GetVenueFloorPlanPromoterPayments?fpid=${floorPlanId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenueFloorPlanPromoterPayments(floorPlanId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateVenueFloorPlanPromoterPayments(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/UpdateVenueFloorPlanPromoterPayments`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateVenueFloorPlanPromoterPayments(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getEventFloorPlanPromoterPayments(floorPlanId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Tables/GetEventFloorPlanPromoterPayments?fpid=${floorPlanId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventFloorPlanPromoterPayments(floorPlanId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateEventFloorPlanPromoterPayments(body: object, retry = true){
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Tables/UpdateEventFloorPlanPromoterPayments`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateEventFloorPlanPromoterPayments(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async searchPromotersAndEmployees(searchString: string, cuid: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Venues/SearchPromotersAndEmployees?searchString=${searchString}&cuid=${cuid}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.searchPromotersAndEmployees(searchString, cuid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async exportToExcel(filename: string,
    rows: object[] | object,
    sheetName: string = 'Sheet1',
    columnWidths: number[] = [],
    rowHeights: number[] = [],
    header: string[] = [],
    footer: string[] = []) {

      if (!rows) {
        return;
      }

      // If rows is a single object, wrap it in an array
      const data = Array.isArray(rows) ? rows : [rows];

      // Prepare workbook
      const wb = XLSX.utils.book_new();

      // Convert data to worksheet
      const ws = XLSX.utils.json_to_sheet(data);

      // Set column widths
      ws['!cols'] = columnWidths.map(w => ({wch: w}));

      // Set row heights (only works for the xlsx format, and not for older xls)
      ws['!rows'] = rowHeights.map(h => ({hpt: h}));

      // Add auto-filter to all columns, use the range from the sheet directly
      ws['!autofilter'] = { ref: ws['!ref'] };

      // Add header and footer
      ws['!margins'] = { header: 0.5, footer: 0.5 };
      ws['!header'] = header;
      ws['!footer'] = footer;

      // Append worksheet to workbook
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      // Write workbook and force download
      XLSX.writeFile(wb, `${filename}.xlsx`);
  }
}
