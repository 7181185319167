// External imports
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

//Internal services
import { AccountService } from '../services/account/account-service.service';

//Internal models
import { GuestListGuestObject } from '../models/guest-list.model';

@Injectable()
export class GuestListProvider {
    //view add guest list variables
    showAddGuestListObservable = new Subject();
    showAddGuestList: boolean;

    //view add guest list variables
    showAddGuestListGuestObservable = new Subject();
    showAddGuestListGuest: boolean;

    //view guest list guest variables
    showViewGuestListGuestObservable = new Subject();
    showViewGuestListGuest: boolean;
    viewingGuestListGuest: GuestListGuestObject;

    //view manage access levels variables
    showManageAccessLevelsObservable = new Subject();
    showManageAccessLevels: boolean;

    constructor(
        public modalCtrl: ModalController,
        private accountService: AccountService
    ) { }

    async guestListSlideOutOpen() {
        return (this.showAddGuestList === true || this.showAddGuestListGuest === true || this.showViewGuestListGuest === true);
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showModal
     * This sets the view guest list guest slide-out variable and triggers the respective Subject so that all observables fire off
     */
    async setShowViewGuestListGuest(showSlideOut: boolean, guest?: GuestListGuestObject) {
        if (guest) {
            this.viewingGuestListGuest = guest;
        }

        this.showViewGuestListGuestObservable.next(showSlideOut);
        this.showViewGuestListGuest = showSlideOut;
    }

    async getShowViewGuestListGuest() {
        return this.showViewGuestListGuest;
    }

    async getViewingGuestListGuest() {
        return this.viewingGuestListGuest;
    }


    // eslint-disable-next-line
    /**
     * @param  {boolean} showModal
     * This sets the view add guest list slide-out variable and triggers the respective Subject so that all observables fire off
     */
    async setShowViewAddGuestList(showSlideOut: boolean, guest?: GuestListGuestObject) {
        this.showAddGuestListObservable.next(showSlideOut);
        this.showAddGuestList = showSlideOut;
    }

    async getViewingAddGuestList() {
        return this.showAddGuestList;
    }


    // eslint-disable-next-line
    /**
     * @param  {boolean} showModal
     * This sets the view add guest list slide-out variable and triggers the respective Subject so that all observables fire off
     */
    async setShowAddGuestListGuest(showSlideOut: boolean, guest?: GuestListGuestObject) {
        this.showAddGuestListGuestObservable.next(showSlideOut);
        this.showAddGuestListGuest = showSlideOut;
    }

    async getViewingAddGuestListGuest() {
        return this.showAddGuestListGuest;
    }


    // eslint-disable-next-line
    /**
     * @param  {boolean} showModal
     * This sets the view manage access levels slide-out variable and triggers the respective Subject so that all observables fire off
     */
    async setShowManageAccessLevels(showSlideOut: boolean) {
        this.showManageAccessLevelsObservable.next(showSlideOut);
        this.showManageAccessLevels = showSlideOut;
    }

    async getViewingManageAccessLevels() {
        return this.showManageAccessLevels;
    }
}
