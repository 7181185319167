/* eslint-disable max-len */
//Angular variables
import { Injectable } from '@angular/core';

//Capacitor variables
import { CapacitorHttp, HttpResponse } from '@capacitor/core';

//Internal services
import { AccountService } from '../account/account-service.service';

//Internal variables
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private accountService: AccountService
  ) { }

  async getNewAccessToken() {
    const userId = localStorage.getItem('phynxUserId');
    if (userId !== null && userId !== undefined && userId !== '' && userId !== 'null') {
      await this.accountService.refreshLoginMobile(userId).then(async (user) => {
        await this.accountService.setUserTokens(user.tokens).then(async () => true).catch((error) => {
          //TODO throw error here
          throw new Error('Unable to set user authentication tokens');
        });
      }).catch((error) => {
        //TODO throw error here
        throw new Error('Unable to refresh login session');
      });
    }
  }

  async getTotalSalesByTicketType(strydEventId: string, chartType: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/TotalSalesByTicketType?eid=${strydEventId}&chartType=${chartType}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTotalSalesByTicketType(strydEventId, chartType, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getSalesByEmployeeType(strydEventId: string, chartType: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/PromoterVsEmployeeSales?eid=${strydEventId}&chartType=${chartType}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getSalesByEmployeeType(strydEventId, chartType, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getTotalQuantitySoldByTicketType(strydEventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/TotalQuantitySoldByTicketType?eid=${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTotalQuantitySoldByTicketType(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getTotalEventRevenue(strydEventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/TotalEventRevenue?eid=${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      const data = JSON.parse(response.data);
      return data.result;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTotalEventRevenue(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getAveragePurchaseLeadTime(strydEventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/AveragePurchaseLeadTime?eid=${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      const data = JSON.parse(response.data);
      return data.result;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getAveragePurchaseLeadTime(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventRevenueAndTickets(strydEventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/TotalEventRevenueAndTickets?eid=${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventRevenueAndTickets(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getAllGuestListGuestGenders(strydEventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/AllGuestListGuestGenders?eid=${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getAllGuestListGuestGenders(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getTotalCheckedInByCategory(strydEventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/TotalCheckedInByCategory?eid=${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTotalCheckedInByCategory(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getAverageTicketPrice(strydEventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/AverageTicketPrice?eid=${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      const data = JSON.parse(response.data);
      return data.result;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getAverageTicketPrice(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getAverageTablePrice(strydEventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/AverageTablePrice?eid=${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getAverageTablePrice(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getManualEventData(strydEventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/ManualEventData?eid=${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getManualEventData(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async postAverageTableSpend(body: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Dashboard/AverageTableSpend`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.postAverageTableSpend(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getVenueRepeatCustomerRate(corporationUserId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/VenueRepeatCustomerRate?cuid=${corporationUserId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenueRepeatCustomerRate(corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventRepeatCustomerRate(strydEventId: string,corporationUserId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/EventRepeatCustomerRate?eid=${strydEventId}&cuid=${corporationUserId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventRepeatCustomerRate(strydEventId, corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getTotalEventsByDateRange(corporationUserId: string, requestBody: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Dashboard/TotalEventsByDateRange?cuid=${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      const data = JSON.parse(response.data);
      return data.result;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTotalEventsByDateRange(corporationUserId, requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventRevenueByDateRange(corporationUserId: string, requestBody: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Dashboard/EventRevenueByDateRange?cuid=${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventRevenueByDateRange(corporationUserId, requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getParentTotalChildEventsByDateRange(corporationUserId: string, requestBody: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Dashboard/Parent/TotalChildEventsByDateRange?cuid=${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getParentTotalChildEventsByDateRange(corporationUserId, requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getParentItemizedEventsByDateRange(corporationUserId: string, requestBody: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Dashboard/Parent/ItemizedEventsByDateRange?cuid=${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getParentItemizedEventsByDateRange(corporationUserId, requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getParentTotalRevenueByCategory(corporationUserId: string, requestBody: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Dashboard/Parent/TotalRevenueByCategory?cuid=${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getParentTotalRevenueByCategory(corporationUserId, requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getParentItemizedTotalRevenueByCategory(corporationUserId: string, requestBody: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Dashboard/Parent/ItemizedTotalRevenueByCategory?cuid=${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getParentItemizedTotalRevenueByCategory(corporationUserId, requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getParentAverageTicketSpend(corporationUserId: string, requestBody: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Dashboard/Parent/AverageTicketSpend?cuid=${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getParentAverageTicketSpend(corporationUserId, requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getParentItemizedAverageTicketSpend(corporationUserId: string, requestBody: object, retry = true){
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/Dashboard/Parent/ItemizedAverageTicketSpend?cuid=${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: requestBody
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getParentItemizedAverageTicketSpend(corporationUserId, requestBody, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventTotalRevenueByType(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/EventTotalRevenueByType?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventTotalRevenueByType(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventTableTotalRevenue(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/GetEventTableTotalRevenue?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventTableTotalRevenue(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getEventTableStatuses(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/GetEventTableStatuses?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventTableStatuses(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getEventTableReservationsByEmployeeType(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/GetEventTableReservationsByEmployeeType?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventTableReservationsByEmployeeType(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getEventTableRepeatCustomerRate(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/GetEventTableRepeatCustomerRate?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventTableRepeatCustomerRate(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getEventTableReservationGenders(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/GetEventTableReservationGenders?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventTableReservationGenders(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getEventTableReservationsByArrivalTime(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/GetEventTableReservationsByArrivalTime?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventTableReservationsByArrivalTime(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getEventTableReservationsTimeSeriesData(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/GetEventTableReservationsTimeSeriesData?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventTableReservationsTimeSeriesData(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getEventTableCashflow(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/GetEventTableCashflow?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventTableCashflow(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getEventGuestListGuestCheckInRate(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/GetEventGuestListGuestCheckInRate?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventGuestListGuestCheckInRate(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getEventGuestListGuestByEmployeeType(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/GetEventGuestListGuestByEmployeeType?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventGuestListGuestByEmployeeType(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getEventGuestListRepeatGuestRate(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/GetEventGuestListRepeatGuestRate?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventGuestListRepeatGuestRate(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getGuestListGuestsTimeSeriesData(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/GetGuestListGuestsTimeSeriesData?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getGuestListGuestsTimeSeriesData(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getEventGuestListGuestGenders(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Dashboard/GetEventGuestListGuestGenders?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventGuestListGuestGenders(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }
}
