//Angular imports
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

//Ionic imports
import { ModalController } from '@ionic/angular';

//Internal models
import { GuestListObject, PromoterAllowanceObject } from '../models/guest-list.model';

//Internal components
import { AddGuestListComponent } from '../components/guest-list/add-guest-list/add-guest-list.component';
import { ViewBannedGuestsComponent } from '../components/guest-list/view-banned-guests/view-banned-guests.component';

//Internal providers
import { GuestListProvider } from './guest-list.provider';

//Internal services
import { GuestListService } from '../services/guest-list/guest-list.service';


export interface ListBasicInfo {
  checkinEmployeeIds: string[];
  checkinEmployeeName: string;
  endDateTime: Date;
  listType: string;
  name: string;
  // quantityAllowed: number;
  startDate: Date;
}
export interface ListSettings {
  isCheckinList: boolean;
  isPublicList: boolean;
  isRecurringList: boolean;
  addCustomCheck: boolean;
  customCheckValue: string;
  isPromoterList: boolean;
  showEmail: boolean;
  emailRequired: boolean;
  showPhoneNumber: boolean;
  phoneNumberRequired: boolean;
  createGuestProfiles: boolean;
  showGender: boolean;
  requireGender: boolean;
  plusOneAllowed: boolean;
  maxAdditionalGuests: number;
  publiclyShowGuests: boolean;
  allowGuestRemoval: boolean;
  healthCheckRequired: boolean;
  sendGuestTicket: boolean;
  ticketPickupInstructions: string;
  enableSpecialRequests: boolean;
  enableSpecialEvents: boolean;
  askForAllergies: boolean;
  showRemainingSpots: boolean;
  quantityAllowed: number;
}

@Injectable()
export class AddGuestListProvider {
  //add new guest list modal
  addGuestListModal: any;
  addGuestListModalIsOpenObservable = new Subject();
  addGuestListModalIsOpen: boolean;

  //view banned guests modal
  viewBannedGuestModal: any;
  viewBannedGuestModalIsOpenObservable = new Subject();
  viewBannedGuestModalIsOpen: boolean;

  //view recurring lists modal
  viewRecurringListsModalIsOpenObservable = new Subject();
  viewRecurringListsModalIsOpen: boolean;

  //add guest list step variables
  selectedGuestListStepObservable = new Subject();
  selectedGuestListStep: number;
  totalGuestListStepsObservable = new Subject();
  totalGuestListSteps: number;

  //new list basic info variables
  listId: string;
  listName: string;
  startDate: Date | string;
  endDateTime: Date | string;
  quantityAllowed: number;
  checkInEmployeeUserIds: string[] = [];
  checkInEmployeeName: string;

  //new list settings variables
  isCheckinList = true;
  isPublicList = false;
  isRecurringList = false;
  addCustomCheck = false;
  customCheckValue: string = null;
  isPromoterList = false;
  createGuestProfiles = false;
  showEmail = true;
  emailRequired = true;
  showPhoneNumber = true;
  phoneNumberRequired = true;
  showGender = true;
  requireGender = false;
  plusOneAllowed = false;
  maxAdditionalGuests: number = null;
  publiclyShowGuests = false;
  allowGuestRemoval = false;
  healthCheckRequired = false;
  sendGuestTicket = true;
  ticketPickupInstructions: string = null;
  enableSpecialRequests = false;
  enableSpecialEvents = false;
  askForAllergies = false;
  showRemainingSpots = false;

  //new list promoter allowance variables
  promoterAllowances: PromoterAllowanceObject[];

  //editing list variables
  isEditingList: boolean;

  constructor(
    private guestListProvider: GuestListProvider,
    private guestListService: GuestListService,
    public modalCtrl: ModalController
  ) { }

  async dismissAddGuestListModal() {
    await this.resetAllVariables().then(async () => {
      await this.addGuestListModal.dismiss().then(() => {
        this.addGuestListModalIsOpen = false;
        this.addGuestListModalIsOpenObservable.next(false);
      });
    });
  }

  async presentViewBannedGuestModal(){
    this.viewBannedGuestModal = await this.modalCtrl.create({
        breakpoints: [0.1, 0.5, 0.75, 1],
        initialBreakpoint: 1,
        component: ViewBannedGuestsComponent
    });

    await this.viewBannedGuestModal.present();

    this.viewBannedGuestModalIsOpen = true;
    this.viewBannedGuestModalIsOpenObservable.next(true);

    this.viewBannedGuestModal.onDidDismiss().then((res) => {
        this.resetAllVariables();
    });
  }

  async dismissViewBannedGuestListModal() {
    await this.resetAllVariables().then(async () => {
      await this.viewBannedGuestModal.dismiss().then(() => {
        this.viewBannedGuestModalIsOpen = false;
        this.viewBannedGuestModalIsOpenObservable.next(false);
      });
    });
  }

  async presentViewRecurringListsModal(){
    this.viewRecurringListsModalIsOpen = true;
    this.viewRecurringListsModalIsOpenObservable.next(true);
  }

  async dismissViewRecurringListsModal() {
    this.viewRecurringListsModalIsOpen = false;
    this.viewRecurringListsModalIsOpenObservable.next(false);
  }

  async resetAllVariables() {
    this.isEditingList = false;

    this.listName = null;
    this.startDate = null;
    this.endDateTime = null;
    this.quantityAllowed = null;
    this.checkInEmployeeUserIds = [];
    this.checkInEmployeeName = null;
    this.isCheckinList = true;
    this.isPublicList = false;
    this.isRecurringList = false;
    this.addCustomCheck = false;
    this.customCheckValue = null;
    this.isPromoterList = false;
    this.showEmail = true;
    this.emailRequired = true;
    this.showPhoneNumber = true;
    this.phoneNumberRequired = true;
    this.createGuestProfiles = false;
    this.showGender = true;
    this.requireGender = false;
    this.plusOneAllowed = false;
    this.maxAdditionalGuests = null;
    this.publiclyShowGuests = false;
    this.allowGuestRemoval = false;
    this.healthCheckRequired = false;
    this.sendGuestTicket = true;
    this.ticketPickupInstructions = null;
    this.enableSpecialRequests = false;
    this.enableSpecialEvents = false;
    this.askForAllergies = false;
    this.showRemainingSpots = false;

    this.promoterAllowances = [];

    this.setSelectedGuestListStep(1);
    this.setTotalGuestListSteps(2);
  }

  async setSelectedGuestListStep(guestListStep: number) {
    //@task1 set the {@var selectedGuestListStep} to the passed guestListStep
    this.selectedGuestListStep = guestListStep;
    //@task2 call the {@method next()} on {@var selectedGuestListStepObservable} so that...
    //all subscriptions in other components fetch the new {@var selectedGuestListStep}
    this.selectedGuestListStepObservable.next(guestListStep);
  }

  getCurrentSelectedStep() {
    return this.selectedGuestListStep;
  }

  async setTotalGuestListSteps(totalSteps: number) {
    //@task1 set the {@var totalGuestListSteps} to the passed totalSteps
    this.totalGuestListSteps = totalSteps;
    //@task2 call the {@method next()} on {@var totalGuestListStepsObservable} so that...
    //all subscriptions in other components fetch the new {@var totalSteps}
    this.totalGuestListStepsObservable.next(totalSteps);
  }

  getTotalGuestListSteps() {
    return this.totalGuestListSteps;
  }


  async setBasicInfo(passedInfo: ListBasicInfo, checkInEmployeeIds: string[], checkInEmployeeName: string) {
    this.listName = passedInfo.name;
    this.startDate = passedInfo.startDate;
    this.endDateTime = passedInfo.endDateTime;
    // this.quantityAllowed = passedInfo.quantityAllowed;
    this.checkInEmployeeUserIds = checkInEmployeeIds;
    this.checkInEmployeeName = checkInEmployeeName;

    switch(passedInfo.listType) {
      case 'promoter':
        this.isPromoterList = true;
        break;
      case 'public':
        this.isPublicList = true;
        break;
      default:
        break;
    }

    await this.setSelectedGuestListStep(2);

    return;
  }

  async getBasicInfo() {
    let listType = 'house';

    switch(true) {
      case this.isPromoterList:
        listType = 'promoter';
        break;
      case this.isPublicList:
        listType = 'public';
        break;
      default:
        listType = 'house';
        break;
    }

    const basicInfo = {
      checkinEmployeeIds: this.checkInEmployeeUserIds,
      checkinEmployeeName: this.checkInEmployeeName,
      endDateTime: this.endDateTime !== undefined && this.endDateTime !== null ? await this.formatDateTimeAsISO(this.endDateTime) : null,
      listType,
      name: this.listName,
      startDate: this.startDate !== undefined && this.startDate !== null ? await this.formatDateTimeAsISO(this.startDate) : null,
    };

    return basicInfo;
  }

  async formatDateTimeAsISO(date: string | Date): Promise<string> {
    if (typeof date === 'string') {
      return date;
    }

    return date.toISOString();
  }

  async setListSettings(passedSettings: ListSettings) {
    this.isCheckinList = passedSettings.isCheckinList;
    this.isPublicList = passedSettings.isPublicList;
    this.isRecurringList = passedSettings.isRecurringList;
    this.addCustomCheck = passedSettings.addCustomCheck;
    this.customCheckValue = passedSettings.customCheckValue;
    this.isPromoterList = passedSettings.isPromoterList;
    this.showEmail = passedSettings.showEmail;
    this.emailRequired = passedSettings.emailRequired;
    this.showPhoneNumber = passedSettings.showPhoneNumber;
    this.phoneNumberRequired = passedSettings.phoneNumberRequired;
    this.createGuestProfiles = passedSettings.createGuestProfiles;
    this.showGender = passedSettings.showGender;
    this.requireGender = passedSettings.requireGender;
    this.plusOneAllowed = passedSettings.plusOneAllowed;
    this.maxAdditionalGuests = passedSettings.maxAdditionalGuests;
    this.publiclyShowGuests = passedSettings.publiclyShowGuests;
    this.allowGuestRemoval = passedSettings.allowGuestRemoval;
    this.healthCheckRequired = passedSettings.healthCheckRequired;
    this.sendGuestTicket = passedSettings.sendGuestTicket;
    this.ticketPickupInstructions = passedSettings.ticketPickupInstructions;
    this.enableSpecialRequests = passedSettings.enableSpecialRequests;
    this.enableSpecialEvents = passedSettings.enableSpecialEvents;
    this.askForAllergies = passedSettings.askForAllergies;
    this.showRemainingSpots = passedSettings.showRemainingSpots;
    this.quantityAllowed = passedSettings.quantityAllowed;

    await this.setSelectedGuestListStep(3);

    return;
  }

  async getListSettings() {
    const settings: ListSettings = {
      isCheckinList: this.isCheckinList,
      isPublicList: this.isPublicList,
      isRecurringList: this.isRecurringList,
      addCustomCheck: this.addCustomCheck,
      customCheckValue: this.customCheckValue,
      isPromoterList: this.isPromoterList,
      showEmail: this.showEmail,
      emailRequired: this.emailRequired,
      showPhoneNumber: this.showPhoneNumber,
      phoneNumberRequired: this.phoneNumberRequired,
      createGuestProfiles: this.createGuestProfiles,
      showGender: this.showGender,
      requireGender: this.requireGender,
      plusOneAllowed: this.plusOneAllowed,
      maxAdditionalGuests: this.maxAdditionalGuests,
      publiclyShowGuests: this.publiclyShowGuests,
      allowGuestRemoval: this.allowGuestRemoval,
      healthCheckRequired: this.healthCheckRequired,
      sendGuestTicket: this.sendGuestTicket,
      ticketPickupInstructions: this.ticketPickupInstructions,
      enableSpecialRequests: this.enableSpecialRequests,
      enableSpecialEvents: this.enableSpecialEvents,
      askForAllergies: this.askForAllergies,
      showRemainingSpots: this.showRemainingSpots,
      quantityAllowed: this.quantityAllowed
    };

    return settings;
  }

  async setEditingList(editingList: GuestListObject) {
    if (editingList !== null) {
      this.isEditingList = true;

      this.listId = editingList.id;
      this.listName = editingList.name;
      this.startDate = editingList.startDate;
      this.endDateTime = editingList.endDateTime;
      this.quantityAllowed = editingList.quantityAllowed;
      //TODO need to set the checkin employee ids and names
      //this.checkInEmployeeUserIds = editingList.checkinUserId;
      //this.checkInEmployeeName = editingList.checkInEmployeeName;
      this.isCheckinList = editingList.isCheckinList;
      this.isPublicList = editingList.isPublicList;
      this.isRecurringList = editingList.isRecurring;
      this.addCustomCheck = editingList.customCheckRequired;
      this.customCheckValue = editingList.customCheckDescription;
      this.isPromoterList = editingList.isPromoterList;
      this.showEmail = editingList.showEmail;
      this.emailRequired = editingList.emailRequired;
      this.showPhoneNumber = editingList.showPhoneNumber;
      this.phoneNumberRequired = editingList.phoneNumberRequired;
      this.createGuestProfiles = editingList.guestDetailsRequired;
      this.showGender = editingList.showGender;
      this.requireGender = editingList.genderRequired;
      this.plusOneAllowed = editingList.publicAdditionalGuestAllowed;
      this.maxAdditionalGuests = editingList.publicMaxAdditionalGuests;
      this.publiclyShowGuests = editingList.publicShowListMembers;
      this.allowGuestRemoval = editingList.publicAllowRemoval;
      this.healthCheckRequired = editingList.temperatureCheckRequired;
      this.sendGuestTicket = editingList.sendTicketLink;
      this.ticketPickupInstructions = editingList.alternateTicketVerbiage;
      this.enableSpecialRequests = editingList.allowSpecialRequests;
      this.enableSpecialEvents = editingList.allowSpecialEvents;
      this.askForAllergies = editingList.anyAllergies;
      this.showRemainingSpots = editingList.showRemainingSpots;


      await this.guestListService.getListAllowances(this.listId)
        .then((res) => {
          this.promoterAllowances = [];

          for (const r in res) {
            if(Object.prototype.hasOwnProperty.call(res, r)) {
              this.promoterAllowances.push(new PromoterAllowanceObject(res[r]));
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });

      await this.guestListService.getExistingGuestListCheckInEmployees(this.listId)
        .then((res) => {
          this.checkInEmployeeUserIds = [];

          for (const r in res.checkInUserIds) {
            if (Object.prototype.hasOwnProperty.call(res.checkInUserIds, r)) {
              this.checkInEmployeeUserIds.push(res.checkInUserIds[r]);
            }
          }

          this.checkInEmployeeName = res.checkInEmployeeName;
        })
        .catch((err) => {
          console.error(err);
        });

      await this.setSelectedGuestListStep(1).then(async () => {
        const totalSteps = this.isPromoterList ? 3 : 2;
        await this.setTotalGuestListSteps(totalSteps).then(async () => {
          await this.guestListProvider.setShowViewAddGuestList(true);

          return;
        });
      });
    }
  }

  async resetPromoterAllowances() {
    for(const r in this.promoterAllowances) {
      if (Object.prototype.hasOwnProperty.call(this.promoterAllowances, r)) {
        this.promoterAllowances[r].quantityAllowed = 0;
        this.promoterAllowances[r].maxAdditionalGuests = 0;
        this.promoterAllowances[r].rate = 0;
      }
    }
  }

  async getExistingAllowances() {
    return this.promoterAllowances;
  }

  async getIsEditingList() {
    return this.isEditingList;
  }

  async getIsPromoterList() {
    return this.isPromoterList;
  }

  async getExistingListId() {
    return this.listId;
  }
}
