//Angular variables
import { Injectable } from '@angular/core';

//Capacitor variables
import { CapacitorHttp, HttpResponse } from '@capacitor/core';

//Internal services
import { AccountService } from '../account/account-service.service';

//Internal variables
import { environment } from '../../../environments/environment';

//Internal enums
import { PromoterPaymentType } from 'src/app/enums/accounting/promoter-payment-type';

@Injectable({
  providedIn: 'root'
})
export class AccountingService {

  constructor(
    private accountService: AccountService
  ) { }

  async getNewAccessToken() {
    const userId = localStorage.getItem('phynxUserId');
    if (userId !== null && userId !== undefined && userId !== '' && userId !== 'null') {
      await this.accountService.refreshLoginMobile(userId).then(async (user) => {
        await this.accountService.setUserTokens(user.tokens).then(async () => true).catch((error) => {
          //TODO throw error here
          throw new Error('Unable to set user authentication tokens');
        });
      }).catch((error) => {
        //TODO throw error here
        throw new Error('Unable to refresh login session');
      });
    }
  }

  async getCorporationInvoices(passedPage: number, pageSize: number, retry = true) {
    const options = {
      method: 'GET',
      // eslint-disable-next-line max-len
      url: `${environment.baseUrl}/api/Stripe/GetCorporationInvoices?corporationUserId=${await this.accountService.getCorporationUserId()}&passedPage=${passedPage}&pageSize=${pageSize}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getCorporationInvoices(passedPage, pageSize, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventCashflow(strydEventId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Accounting/GetEventCashflow?strydEventId=${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventCashflow(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventCashflowRevenue(strydEventId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Accounting/GetEventCashflowRevenue?strydEventId=${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventCashflowRevenue(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventCashflowExpenses(strydEventId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Accounting/GetEventCashflowExpenses?strydEventId=${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventCashflowExpenses(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventCashflowTaxes(strydEventId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Accounting/GetEventCashflowTaxes?strydEventId=${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventCashflowTaxes(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventCashflowProfit(strydEventId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Accounting/GetEventCashflowProfit?strydEventId=${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventCashflowProfit(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getEventPromoterPaymentsDue(strydEventId: string, promoterPaymentType: PromoterPaymentType, retry = true) {
    const options = {
      method: 'GET',
      // eslint-disable-next-line max-len
      url: `${environment.baseUrl}/api/Accounting/GetEventPromoterPaymentsDue?strydEventId=${strydEventId}&paymentType=${promoterPaymentType}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getEventPromoterPaymentsDue(strydEventId, promoterPaymentType, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  // eslint-disable-next-line max-len
  async getIndividualEventPromoterPaymentsDueAsync(strydEventId: string, promoterUserId: string, promoterPaymentType: PromoterPaymentType, retry = true) {
    const options = {
      method: 'GET',
      // eslint-disable-next-line max-len
      url: `${environment.baseUrl}/api/Accounting/GetIndividualEventPromoterPaymentsDueAsync?strydEventId=${strydEventId}&promoterUserId=${promoterUserId}&paymentType=${promoterPaymentType}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getIndividualEventPromoterPaymentsDueAsync(strydEventId, promoterUserId, promoterPaymentType, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async markAllEventPromoterPaymentsPaid(strydEventId: string, promoterUserId: string, paymentType: PromoterPaymentType, retry = true) {
    const options = {
      method: 'PUT',
      // eslint-disable-next-line max-len
      url: `${environment.baseUrl}/api/Accounting/MarkAllEventPromoterPaymentsPaid?strydEventId=${strydEventId}&promoterUserId=${promoterUserId}&paymentType=${paymentType}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.request(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.markAllEventPromoterPaymentsPaid(strydEventId, promoterUserId, paymentType, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async markIndividualPromoterPaymentPaid(paymentDueId: string, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/Accounting/MarkIndividualPromoterPaymentPaid?paymentDueId=${paymentDueId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.request(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.markIndividualPromoterPaymentPaid(paymentDueId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getVenuePayouts(corporationUserId: string, passedPage: number, pageSize: number, retry = true) {
    const options = {
      method: 'GET',
      // eslint-disable-next-line max-len
      url: `${environment.baseUrl}/api/Accounting/GetVenuePayouts?corporationUserId=${corporationUserId}&passedPage=${passedPage}&pageSize=${pageSize}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenuePayouts(corporationUserId, passedPage, pageSize, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getVenuePayoutEvents(venuePaymentId: string, retry = true) {
    const options = {
      method: 'GET',
      // eslint-disable-next-line max-len
      url: `${environment.baseUrl}/api/Accounting/GetVenuePayoutEvents?venuePaymentId=${venuePaymentId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenuePayoutEvents(venuePaymentId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getTicketSalesAreaChartData(strydEventId: string, totalPastDays: number, retry = true) {
    const options = {
      method: 'GET',
      // eslint-disable-next-line max-len
      url: `${environment.baseUrl}/api/Accounting/GetTicketSalesAreaChartData?strydEventId=${strydEventId}&totalPastDays=${totalPastDays}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTicketSalesAreaChartData(strydEventId, totalPastDays, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getTicketSalesRefundPercentage(strydEventId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Accounting/GetTicketSalesRefundPercentage?strydEventId=${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      const data = JSON.parse(response.data);
      return data.result;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTicketSalesRefundPercentage(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getTicketSalesPromoterRoiPercentage(strydEventId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Accounting/GetTicketSalesPromoterRoiPercentage?strydEventId=${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      const data = JSON.parse(response.data);
      return data.result;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTicketSalesPromoterRoiPercentage(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getPromoterPaymentsChartData(strydEventId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Accounting/GetPromoterPaymentsChartData?strydEventId=${strydEventId}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPromoterPaymentsChartData(strydEventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getTicketTypeSalesChartData(strydEventId: string, format: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Accounting/getTicketTypeSalesChartData?strydEventId=${strydEventId}&format=${format}`,
      headers: {authorization:this.accountService.authorizationHeader()}
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTicketTypeSalesChartData(strydEventId, format, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getPromoterTicketingReconciliation(eventId: string, corporationUserId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Reporting/PromoterTicketingReconciliation?eid=${eventId}&cui=${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPromoterTicketingReconciliation(eventId, corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getPromoterGuestListReconciliation(eventId: string, corporationUserId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Reporting/PromoterGuestListReconciliation?eid=${eventId}&cui=${corporationUserId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getPromoterGuestListReconciliation(eventId, corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getTicketingSummary(eventId: string, corporationUserId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Reporting/TicketingSummary?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTicketingSummary(eventId, corporationUserId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getTablesBookedWithAttribution(eventId: string, retry = true){
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/Accounting/GetTablesBookedWithAttribution?eid=${eventId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTablesBookedWithAttribution(eventId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }



  async exportToCsv(filename: string, rows: object[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent =
      keys.join(separator) +
        '\n' +
        rows.map(row => keys.map(k => {
            let cell = row[k] === null || row[k] === undefined ? '' : row[k];
            cell = cell instanceof Date
              ? cell.toLocaleString()
              : cell.toString().replace(/"/g, '""');
            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }
            return cell;
          }).join(separator)).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
