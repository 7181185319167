//Angular imports
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

//Ionic imports
import { ModalController } from '@ionic/angular';

//Internal components
import { AddGuestSelectorComponent } from '../components/guest-list/add-guest-selector/add-guest-selector.component';
import { IndividualGuestComponent } from '../components/guest-list/add-guest/individual-guest/individual-guest.component';
import { BulkGuestComponent } from '../components/guest-list/add-guest/bulk-guest/bulk-guest.component';
import { ExcelUploadComponent } from '../components/guest-list/add-guest/excel-upload/excel-upload.component';
import { GuestListGuestObject } from '../models/guest-list.model';


@Injectable()
export class AddGuestListGuestProvider {
  //add guest list guest selector modal
  addGuestListGuestSelectorModal: any;
  addGuestListGuestSelectorModalIsOpenObservable = new Subject();
  addGuestListGuestSelectorModalIsOpen: boolean;

  //add individual guest list guest modal
  addGuestListIndividualGuestModal: any;
  addGuestListIndividualGuestModalIsOpenObservable = new Subject();
  addGuestListIndividualGuestModalIsOpen: boolean;

  //bulk add guest list guest modal
  addBulkGuestModal: any;
  addBulkGuestModalIsOpenObservable = new Subject();
  addBulkGuestModalIsOpen: boolean;

  //excel upload guest list guest modal
  excelUploadGuestModal: any;
  excelUploadGuestModalIsOpenObservable = new Subject();
  excelUploadGuestModalIsOpen: boolean;

  //editing list variables
  isEditingGuest: boolean;

  constructor(
    public modalCtrl: ModalController
  ) { }

  /**
   * @summary this configures and presents the add guest modal
   */
  async presentAddGuestSelectorModal(){
    this.addGuestListGuestSelectorModal = await this.modalCtrl.create({
        breakpoints: [0.33],
        initialBreakpoint: 0.33,
        component: AddGuestSelectorComponent
    });

    await this.addGuestListGuestSelectorModal.present();

    this.addGuestListGuestSelectorModalIsOpen = true;
    this.addGuestListGuestSelectorModalIsOpenObservable.next(true);

    this.addGuestListGuestSelectorModal.onDidDismiss().then((res) => {
        this.resetAllVariables();
    });
  }

  async dismissAddGuestSelectorModal() {
    await this.resetAllVariables().then(async () => {
      await this.addGuestListGuestSelectorModal.dismiss().then(() => {
        this.addGuestListGuestSelectorModalIsOpen = false;
        this.addGuestListGuestSelectorModalIsOpenObservable.next(false);
      });
    });
  }


  async presentAddIndividualGuestModal(){
    this.addGuestListIndividualGuestModal = await this.modalCtrl.create({
        breakpoints: [0.75],
        initialBreakpoint: 0.75,
        component: IndividualGuestComponent
    });

    await this.addGuestListIndividualGuestModal.present();

    this.addGuestListIndividualGuestModalIsOpen = true;
    this.addGuestListIndividualGuestModalIsOpenObservable.next(true);

    this.addGuestListIndividualGuestModal.onDidDismiss().then((res) => {
        this.resetAllVariables();
    });
  }

  async dismissAddIndividualGuestModal() {
    await this.resetAllVariables().then(async () => {
      await this.addGuestListIndividualGuestModal.dismiss().then(() => {
        this.addGuestListIndividualGuestModalIsOpen = false;
        this.addGuestListIndividualGuestModalIsOpenObservable.next(false);
      });
    });
  }


  async presentAddBulkGuestModal(){
    this.addBulkGuestModal = await this.modalCtrl.create({
        breakpoints: [0.75],
        initialBreakpoint: 0.75,
        component: BulkGuestComponent
    });

    await this.addBulkGuestModal.present();

    this.addBulkGuestModalIsOpen = true;
    this.addBulkGuestModalIsOpenObservable.next(true);

    this.addBulkGuestModal.onDidDismiss().then((res) => {
        this.resetAllVariables();
    });
  }

  async dismissAddBulkGuestModal() {
    await this.resetAllVariables().then(async () => {
      await this.addBulkGuestModal.dismiss().then(() => {
        this.addBulkGuestModalIsOpen = false;
        this.addBulkGuestModalIsOpenObservable.next(false);
      });
    });
  }


  async presentExcelUploadGuestModal(){
    this.excelUploadGuestModal = await this.modalCtrl.create({
        breakpoints: [0.75],
        initialBreakpoint: 0.75,
        component: ExcelUploadComponent
    });

    await this.excelUploadGuestModal.present();

    this.excelUploadGuestModalIsOpen = true;
    this.excelUploadGuestModalIsOpenObservable.next(true);

    this.excelUploadGuestModal.onDidDismiss().then((res) => {
        this.resetAllVariables();
    });
  }

  async dismissExcelUploadGuestModal() {
    await this.resetAllVariables().then(async () => {
      await this.excelUploadGuestModal.dismiss().then(() => {
        this.excelUploadGuestModalIsOpen = false;
        this.excelUploadGuestModalIsOpenObservable.next(false);
      });
    });
  }

  async resetAllVariables() {

  }
}
