/* eslint-disable max-len */
//Angular variables
import { Injectable } from '@angular/core';

//Capacitor variables
import { CapacitorHttp, HttpResponse } from '@capacitor/core';

//Other external imports
import * as XLSX from 'xlsx';

//Internal services
import { AccountService } from '../account/account-service.service';

//Internal variables
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CrmService {

  constructor(
    private accountService: AccountService
  ) { }

  async getNewAccessToken() {
    const userId = localStorage.getItem('phynxUserId');
    if (userId !== null && userId !== undefined && userId !== '' && userId !== 'null') {
      await this.accountService.refreshLoginMobile(userId).then(async (user) => {
        await this.accountService.setUserTokens(user.tokens).then(async () => true).catch((error) => {
          //TODO throw error here
          throw new Error('Unable to set user authentication tokens');
        });
      }).catch((error) => {
        //TODO throw error here
        throw new Error('Unable to refresh login session');
      });
    }
  }

  async getCustomerList(body: object, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/CRM/CustomerList`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getCustomerList(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async downloadCustomerList(body: object, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/CRM/DownloadCustomerList`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.downloadCustomerList(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async uploadTableListCustomerList(file: File, retry = true) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async () => {
      // Convert to Base64
      const base64 = reader.result as string;
      const base64Data = base64.split(',')[1]; // Remove the Data URL prefix

      const options = {
        method: 'POST',
        url: `${environment.baseUrl}/api/CRM/UploadTableListCustomerList?cuid=${await this.accountService.getCorporationUserId()}`,
        headers: {
            authorization: this.accountService.authorizationHeader(),
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/json'
        },
        data: JSON.stringify({ file: base64Data })
      };

        // Send the request using CapacitorHttp.post or HttpClient
        const response: HttpResponse = await CapacitorHttp.post(options);


      if (response.status === 200) {
        return response.data;
      } else if (response.status === 204) {
        return null;
      } else if (response.status === 401 && retry) { // Unauthorized
        try {
          await this.getNewAccessToken();

          // Retry the request with the new access token
          return this.uploadTableListCustomerList(file, false);
        } catch (error) {
          throw new Error('Unable to refresh access token: ' + error.message);
        }
      } else {
        throw new Error(response.data.message);
      }
    };

    reader.onerror = (error) => {

    };
  }



  async getCustomerHistoricNotes(profileVenueId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/CRM/CustomerHistoricNotes?profileVenueId=${profileVenueId}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getCustomerHistoricNotes(profileVenueId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateCustomerPhoneNumbers(body: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/CRM/UpdateCustomerPhoneNumbers`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateCustomerPhoneNumbers(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateCustomerEmail(body: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/CRM/UpdateCustomerEmail`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateCustomerEmail(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateCustomerGender(body: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/CRM/UpdateCustomerGender`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateCustomerGender(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateCustomerShippingAddress(body: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/CRM/UpdateCustomerShippingAddress`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateCustomerShippingAddress(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateCustomerBirthDate(body: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/CRM/UpdateCustomerBirthDate`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateCustomerBirthDate(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateCustomerWebsite(body: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/CRM/UpdateCustomerWebsite`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateCustomerWebsite(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateCustomerJobInfo(body: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/CRM/UpdateCustomerJobInfo`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateCustomerJobInfo(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async getCustomerSocialAccounts(profileVenueId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/CRM/GetCustomerSocialAccounts?profileVenueId=${profileVenueId}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getCustomerSocialAccounts(profileVenueId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addCustomerSocialAccount(body: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/CRM/AddCustomerSocialMediaAccountURL`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addCustomerSocialAccount(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async addCustomerNote(body: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/CRM/AddCustomerNote`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addCustomerNote(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }

  async updateCustomerVipStatus(body: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/CRM/UpdateCustomerVipStatus`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateCustomerVipStatus(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async crmBanGuest(body: object, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/CRM/BanGuest`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.crmBanGuest(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getCustomerDetails(profileVenueId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/CRM/CustomerDetails?pvid=${profileVenueId}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getCustomerDetails(profileVenueId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getVenueBannedGuests(cuid: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/CRM/GetVenueBannedGuests?cuid=${cuid}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenueBannedGuests(cuid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getVenueBannedGuestsByVenueProfileId(cuid: string, gpid: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/CRM/GetVenueBannedGuestsByVenueProfileId?cuid=${cuid}&gpid=${gpid}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getVenueBannedGuestsByVenueProfileId(cuid, gpid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async removeBanFromGuest(body: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/CRM/RemoveBanFromGuest`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateCustomerVipStatus(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async updateBannedGuestBolo(body: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/CRM/UpdateBannedGuestBolo`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.updateBannedGuestBolo(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async addBannedGuestAdditionalImage(body: object, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/CRM/AddBannedGuestAdditionalImage`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addBannedGuestAdditionalImage(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getBannedGuestImages(bannedId: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/GuestLists/GetBannedGuestImages?bid=${bannedId}`,
      headers: {
        authorization:this.accountService.authorizationHeader(),
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getBannedGuestImages(bannedId, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getBannedGuestAdditionalImages(bgid: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/CRM/GetBannedGuestAdditionalImages?bgid=${bgid}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getBannedGuestAdditionalImages(bgid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async removeBannedGuestAdditionalImage(body: object, retry = true) {
    const options = {
      method: 'PUT',
      url: `${environment.baseUrl}/api/CRM/RemoveBannedGuestAdditionalImage`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.put(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.removeBannedGuestAdditionalImage(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getCustomerUpcomingReservations(gpid: string, cuid: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/CRM/GetCustomerUpcomingReservations?gpid=${gpid}&cuid=${cuid}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getCustomerUpcomingReservations(gpid, cuid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getTableReservationFromId(trid: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/CRM/GetTableReservationFromId?trid=${trid}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getTableReservationFromId(trid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async getGuestListGuestFromId(gid: string, retry = true) {
    const options = {
      method: 'GET',
      url: `${environment.baseUrl}/api/CRM/GetGuestListGuestFromId?gid=${gid}`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader()
      }
    };

    const response: HttpResponse = await CapacitorHttp.get(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.getGuestListGuestFromId(gid, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      throw new Error(response.data.message);
    }
  }


  async addIndividualCustomer(body: object, retry = true) {
    const options = {
      method: 'POST',
      url: `${environment.baseUrl}/api/CRM/AddIndividualCustomer`,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        authorization: this.accountService.authorizationHeader(),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json'
      },
      data: body
    };

    const response: HttpResponse = await CapacitorHttp.post(options);

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 204) {
      return null;
    } else if (response.status === 401 && retry) { // Unauthorized
      try {
        await this.getNewAccessToken();

        // Retry the request with the new access token
        return this.addIndividualCustomer(body, false);
      } catch (error) {
        throw new Error('Unable to refresh access token: ' + error.message);
      }
    } else {
      const error = new Error(response.data.message);
      (error as any).status = response.status; // Add status code to the error object
      (error as any).message = response.data.message; // Add message to the error object
      throw error;
    }
  }

  async exportToExcel(filename: string,
    rows: object[] | object,
    sheetName: string = 'Sheet1',
    columnWidths: number[] = [],
    rowHeights: number[] = [],
    header: string[] = [],
    footer: string[] = []) {

      if (!rows) {
        return;
      }

      // If rows is a single object, wrap it in an array
      const data = Array.isArray(rows) ? rows : [rows];

      // Prepare workbook
      const wb = XLSX.utils.book_new();

      // Convert data to worksheet
      const ws = XLSX.utils.json_to_sheet(data);

      // Set column widths
      ws['!cols'] = columnWidths.map(w => ({wch: w}));

      // Set row heights (only works for the xlsx format, and not for older xls)
      ws['!rows'] = rowHeights.map(h => ({hpt: h}));

      // Add auto-filter to all columns, use the range from the sheet directly
      ws['!autofilter'] = { ref: ws['!ref'] };

      // Add header and footer
      ws['!margins'] = { header: 0.5, footer: 0.5 };
      ws['!header'] = header;
      ws['!footer'] = footer;

      // Append worksheet to workbook
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      // Write workbook and force download
      XLSX.writeFile(wb, `${filename}.xlsx`);
  }
}
