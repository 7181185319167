//Angular imports
import { Component, OnInit } from '@angular/core';

//Internal providers
import { AddGuestListGuestProvider } from 'src/app/providers/add-guest-list-guest.provider';

@Component({
  selector: 'app-add-guest-selector',
  templateUrl: './add-guest-selector.component.html',
  styleUrls: ['./add-guest-selector.component.scss'],
})
export class AddGuestSelectorComponent implements OnInit {

  constructor(
    private addGuestListGuestProvider: AddGuestListGuestProvider
  ) { }

  ngOnInit() {}

  selectAddType(type) {
    switch(true) {
      case type === 'individual':
        this.addGuestListGuestProvider.presentAddIndividualGuestModal();
        break;
      case type === 'bulk':
        this.addGuestListGuestProvider.presentAddBulkGuestModal();
        break;
      case type === 'excel':
        this.addGuestListGuestProvider.presentExcelUploadGuestModal();
        break;
      default:
        break;
    }
  }
}
